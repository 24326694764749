import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaCcMastercard, FaCcVisa } from "react-icons/fa";
import { AiFillBank } from "react-icons/ai";

import Layout from "../components/Layout/Layout";
import MobileMenu from "../components/Header/MobileMenu";
import Header from "../components/Header/Header";
import Footer from "../components/Layout/Footer";
import Banner from "../components/Banner";
import Loader from "../components/Loader";

import { getTempCodeRecarga } from "../redux/actions/recargasActions";
import RecargasCard from "../components/Recargaspage/RecargasCard";
import MaterialSteps from "../components/Recargaspage/MaterialRecargasStep";
import Message from "../components/Alerts";
import Seo from "../components/seo";
import useDimentions from "../components/WindowDimentions";
/* import {
  addUserToReserva,
  placeReserva,
} from "../redux/actions/reservaActions"; */
/* import SnackBar from "../components/Snackbar"; */

const SeleccionarPagoRecargaScreen = ({ history }) => {
  const { width } = useDimentions();
  const [metodoPago, setMetodoPago] = useState("PAYPHONE");
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const codigoData = useSelector((state) => state.tempCodeRecarga);
  const { loading, recargaPlace, error } = codigoData;

  const handleTransferencia = (e) => {
    e.preventDefault();
    setMetodoPago("TRANSFERENCIA");
    dispatch(getTempCodeRecarga(userInfo.email));
  };
  const handlePayphone = (e) => {
    e.preventDefault();
    dispatch(getTempCodeRecarga(userInfo.email));
  };

  useEffect(() => {
    window.scrollTo(10, 0);
    if (!userInfo) {
      history.push("/login");
    }
    if (loading === false) {
      if (metodoPago === "TRANSFERENCIA") {
        history.push("/recargar/transferencia");
      } else {
        history.push("/recargar/payphone");
      }
    }
    /* if (codigo === undefined && error === undefined) {
      //console.log("rgrgrgrgrrggr");
      history.push("/reservas/invitado");
    } */
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "/static/js/vendor/jquery-library.js";
    script.async = true;
    script.onload = () => {
      const script13 = document.createElement("script");
      script13.type = "text/javascript";
      script13.src = "/static/js/jquery-ui.js";
      script13.async = true;

      const script2 = document.createElement("script");
      script2.type = "text/javascript";
      script2.src = "/static/js/vendor/modernizr-2.8.3-respond-1.4.2.min.js";
      script2.async = true;
      const script3 = document.createElement("script");
      script3.type = "text/javascript";
      script3.src = "/static/js/vendor/bootstrap.min.js";
      script3.async = true;
      const script4 = document.createElement("script");
      script4.type = "text/javascript";
      script4.src = "/static/js/owl.carousel.js";
      script4.async = true;
      const script5 = document.createElement("script");
      script5.type = "text/javascript";
      script5.src = "/static/js/isotope.pkgd.js";
      script5.async = true;
      const script6 = document.createElement("script");
      script6.type = "text/javascript";
      script6.src = "/static/js/prettyPhoto.js";
      script6.async = true;
      const script7 = document.createElement("script");
      script7.type = "text/javascript";
      script7.src = "/static/js/customScrollbar.min.js";
      script7.async = true;
      const script9 = document.createElement("script");
      script9.type = "text/javascript";
      script9.src = "/static/js/swiper.min.js";
      script9.async = true;
      const script10 = document.createElement("script");
      script10.type = "text/javascript";
      script10.src = "/static/js/countTo.js";
      script10.async = true;
      const script11 = document.createElement("script");
      script11.type = "text/javascript";
      script11.src = "/static/js/appear.js";
      script11.async = true;
      const script8 = document.createElement("script");
      script8.type = "text/javascript";
      script8.src = "/static/js/main.js";
      script8.async = true;
      document.body.appendChild(script13);
      document.body.appendChild(script2);
      document.body.appendChild(script3);
      document.body.appendChild(script4);
      document.body.appendChild(script5);
      document.body.appendChild(script6);
      document.body.appendChild(script7);
      document.body.appendChild(script9);
      document.body.appendChild(script10);
      document.body.appendChild(script11);
      document.body.appendChild(script8);
    };

    document.body.appendChild(script);
  }, [history, error, metodoPago, userInfo, dispatch, recargaPlace, loading]);

  return (
    <Layout>
      <Seo
        titlePage="Método de Pago"
        descriptionPage="Pádel Cuenca. Somos un club conformado por personas amantes del deporte, enfocados en fomentar la vida activa y saludable, priorizando el ejercicio como un hábito que beneficie nuestro estado físico y mental, Disponemos de canchas de Pádel en Cuenca Ecuador para que pueda realizar una reserva."
      />
      <div id="tg-wrapper" className="tg-wrapper tg-haslayout">
        <MobileMenu />
        <Header />
        <Banner title="Recargas" />
        <section className="tg-main-section tg-paddingbottom-zero tg-haslayout">
          <div className={width >= 600 ? "container" : ""}>
            <div className="tg-section-name">
              <h2>Recargas</h2>
            </div>

            <div className="col-sm-11 col-xs-11 pull-right">
              {/*  <ReservaSteps step={3} /> */}
              <MaterialSteps step={1} />
            </div>

            <div className="col-sm-11 col-xs-11 ">
              <div class="row" style={{ marginBottom: "30px" }}>
                <div>
                  <>
                    {/* <h6 style={{ marginTop: "15px" }}>
                        Seleccione un Método de Pago
                      </h6> */}
                    <div className="col-md-8 col-sm-12 col-xs-12">
                      <div>
                        <RecargasCard title="Recarga $120" />
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                      {error ? (
                        <Message
                          message="Algo salió mal, por favor inténtelo más tarde"
                          severity="error"
                        />
                      ) : loading ? (
                        <div style={{ textAlign: "center" }}>
                          {" "}
                          <Loader />
                        </div>
                      ) : (
                        <>
                          {" "}
                          <h6 style={{ marginTop: "15px" }}>
                            Seleccione su Método de Pago
                          </h6>
                          <div className="tg-description buttonContainer">
                            <>
                              <button
                                className="tg-btn payButton"
                                style={{
                                  marginTop: "15px",
                                }}
                                onClick={handlePayphone}
                              >
                                <FaCcMastercard /> <FaCcVisa /> Payphone /
                                Tarjeta
                              </button>
                              <button
                                className="tg-btn payButton"
                                style={{
                                  marginTop: "15px",
                                }}
                                onClick={handleTransferencia}
                              >
                                <AiFillBank /> transferencia
                              </button>
                            </>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </Layout>
  );
};

export default SeleccionarPagoRecargaScreen;
