import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { RECARGA_PLACE_RESET } from "../redux/constants/recargasConstants";
const PayRamiroErrorResult = ({ match }) => {
  const dispatch = useDispatch();
  const transaccionId = match.params.id;
  useEffect(() => {
    dispatch({ type: RECARGA_PLACE_RESET });
  }, [dispatch, transaccionId]);
  return (
    <section className="tg-main-section tg-paddingbottom-zero tg-haslayout">
      <div className="container">
        <div className="tg-section-name">
          <h2>resultado</h2>
        </div>

        <div>
          <div className="content-wrapper">
            <h3>Ha ocurrido un error con su pago</h3>
            {/*  <TicketResumen /> */}
          </div>

          <>
            <div class="row" style={{ marginBottom: "30px" }}>
              <div style={{ textAlign: "center" }}>
                <p style={{ fontSize: "20px" }}>
                  Por favor realice el pago nuevamente.
                </p>
                <Link
                  className="tg-btn"
                  to="/pago-ramiro"
                  style={{ margin: "15px 0px" }}
                >
                  Intentar otra vez
                </Link>
              </div>
            </div>
          </>
        </div>
      </div>
    </section>
  );
};

export default PayRamiroErrorResult;
