import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaUserAlt } from "react-icons/fa";

import socialLinks from "../../constants/social-links";
import { Link } from "react-router-dom";
import { getSaldo } from "../../redux/actions/saldoActions";

const TopNavBar = () => {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const saldoUsuario = useSelector((state) => state.saldo);
  const { saldo, error } = saldoUsuario;

  const { userInfo } = userLogin;

  useEffect(() => {
    if (userInfo) {
      dispatch(getSaldo(userInfo.email));
    }
  }, [userInfo, dispatch]);
  return (
    <div
      id="tg-addnavigationm-mobile"
      className="tg-addnavigationm-mobile collapse navbar-collapse"
    >
      <div className="tg-colhalf pull-right">
        <nav className="tg-addnav">
          <ul>
            {userInfo ? (
              <>
                <li>
                  {error && (
                    <p style={{ fontWeight: "900", color: "#8dbe22" }}>
                      Saldo --
                    </p>
                  )}
                  {saldo && saldo.saldo ? (
                    <p style={{ fontWeight: "900", color: "#8dbe22" }}>
                      Saldo $ {saldo.saldo}
                    </p>
                  ) : (
                    <p style={{ fontWeight: "900", color: "#8dbe22" }}>
                      Saldo $ 0
                    </p>
                  )}
                </li>
                <li>
                  <Link to="/recargar">Recargar</Link>
                </li>

                <li>
                  <Link to="/usuario">
                    <FaUserAlt /> {userInfo.username}
                  </Link>
                </li>
              </>
            ) : (
              <>
                {" "}
                <li>
                  <Link to="/login">Iniciar Sesión</Link>
                </li>
                <li>
                  <Link to="/registro">Registrarse</Link>
                </li>
              </>
            )}
          </ul>
        </nav>
      </div>
      <div className="tg-colhalf">
        <ul className="tg-socialicons">
          {socialLinks.map((social, key) => (
            <li key={key}>
              <a href={social.link} target="_blank" rel="noopener noreferrer">
                {social.reactIcon}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default TopNavBar;
