import {
  RECARGA_PLACE_FAIL,
  RECARGA_PLACE_REQUEST,
  RECARGA_PLACE_SUCCESS,
  RECARGA_PLACE_RESET,
  RECARGA_RESULT_FAIL,
  RECARGA_RESULT_REQUEST,
  RECARGA_RESULT_SUCCESS,
  RECARGA_RESULT_RESET,
  RECARGA_TRANSFERENCIA_RESULT_ADD,
  RECARGA_TRANSFERENCIA_RESULT_RESET,
} from "../constants/recargasConstants";

export const recargaTempCodeReducer = (state = {}, action) => {
  switch (action.type) {
    case RECARGA_PLACE_REQUEST:
      return {
        loading: true,
      };
    case RECARGA_PLACE_SUCCESS:
      return {
        loading: false,
        recargaPlace: action.payload,
      };
    case RECARGA_PLACE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case RECARGA_PLACE_RESET:
      return {};
    default:
      return state;
  }
};

export const recargaResultReducer = (state = {}, action) => {
  switch (action.type) {
    case RECARGA_RESULT_REQUEST:
      return {
        loading: true,
      };
    case RECARGA_RESULT_SUCCESS:
      return {
        loading: false,
        recargaResult: action.payload,
      };
    case RECARGA_RESULT_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case RECARGA_RESULT_RESET:
      return {};
    default:
      return state;
  }
};

export const recargaTransferenciaReducer = (state = {}, action) => {
  switch (action.type) {
    case RECARGA_TRANSFERENCIA_RESULT_ADD:
      return {
        loading: false,
        recargaResult: action.payload,
      };

    case RECARGA_TRANSFERENCIA_RESULT_RESET:
      return {};
    default:
      return state;
  }
};
