export const SCHEDULE_LIST_REQUEST = "SCHEDULE_LIST_REQUEST";
export const SCHEDULE_LIST_SUCESS = "SCHEDULE_LIST_SUCESS";
export const SCHEDULE_LIST_FAIL = "SCHEDULE_LIST_FAIL";

export const SCHEDULE_REAGENDAMIENTO_LIST_REQUEST =
  "SCHEDULE_REAGENDAMIENTO_LIST_REQUEST";
export const SCHEDULE_REAGENDAMIENTO_LIST_SUCESS =
  "SCHEDULE_REAGENDAMIENTO_LIST_SUCESS";
export const SCHEDULE_REAGENDAMIENTO_LIST_FAIL =
  "SCHEDULE_REAGENDAMIENTO_LIST_FAIL";

export const SCHEDULE_REAGENDAMIENTO_LIST_RESET =
  "SCHEDULE_REAGENDAMIENTO_LIST_RESET";
