import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import fondo from "../../images/fondopadel8.jpg";
import fondoMovil from "../../images/fondoPadel4.jpg";
import useDimensions from "../WindowDimentions";
import "react-modal-video/scss/modal-video.scss";
const Video = () => {
  const { width } = useDimensions();
  const [isOpen, setOpen] = useState(false);
  return (
    <section className="tg-videobox tg-haslayout">
      <figure>
        {width > 650 ? (
          <img src={fondo} alt="Mejores jugadas de Padel 2020" layout="fill" />
        ) : (
          <img
            src={fondoMovil}
            alt="Mejores jugadas de Padel 2020"
            layout="fill"
          />
        )}

        <figcaption>
          <button className="tg-playbtn" onClick={() => setOpen(true)}></button>
          <ModalVideo
            channel="youtube"
            autoplay
            isOpen={isOpen}
            videoId="rrZJJwVBBbg"
            onClose={() => setOpen(false)}
          />
          <h2>las mejores jugadas del Padel en el año 2020</h2>
        </figcaption>
      </figure>
    </section>
  );
};

export default Video;
