import React, { useEffect, useState } from "react";

const PayRamiro = () => {
  const apiUrl =
    process.env.NODE_ENV === "production"
      ? "https://www.padelcuenca.ec"
      : "http://localhost:3000";
  const [sdkReady2, setSdkReady2] = useState(false);
  const total = 300 * 100;
  useEffect(() => {
    const id = "pagoRamiro" + Math.random() * (5000 - 0) + 0;
    const scriptToInject = `
      
        payphone.Button({
        
        //token obtenido desde la consola de developer
        token: "rCSOHNyxTVVEh7COI7jEz38XjdJ15PXjMWzwD7wZbFbS4tFaJHHfbIA_q-bsbAbdPUW1T647QWkdsxHGuAWJcZUc2rr2xbgkP0MXAt4L1OAeZllkv1SDlzr9NSv8rzRS0oGG7ojSu9N_4-2O2It7EYrbI0yb_mLXCcTQ8Ya3OpFDl8oSv197oAnbQ_PefCt5uamXtWOThafFvh0Ouw3_Ij_iSbZlTEOy4n0R4moJVcl2afqZnHmidoJmA-9lXJB6-k-XdHxYh2M__hxSOAAcfib4lqtnz_MKRzQdkS9I1TloHvtT9g4khFb9KuH1x4lLcdrNeQ",
        
        //PARÁMETROS DE CONFIGURACIÓN
        btnHorizontal: false,
        btnCard: true,
        
        createOrder: function(actions){
        
        //Se ingresan los datos de la transaccion ej. monto, impuestos, etc
        return actions.prepare({
        
        amount:  ${total},
        amountWithoutTax: ${total},
        currency: "USD",
        clientTransactionId: "${id}"
        });
        
        },
        onComplete: function(model, actions){
        
        //Se confirma el pago realizado
        actions.confirm({
        id: model.id,
        clientTxId: model.clientTxId
        }).then(function(value){
        
        //EN ESTA SECCIÓN SE RECIBE LA RESPUESTA Y SE MUESTRA AL USUARIO                                
        
        if (value.transactionStatus == "Approved"){
        location.replace('${apiUrl}/pago-ramiro/resultado/'+value.transactionId)
        }else{
          location.replace('${apiUrl}/pago-ramiro/error/'+value.transactionId)
        }
        }).catch(function(err){
        console.log(err);
        location.replace('${apiUrl}/pago-ramiro/error/${id}');
        });
        
        }
        }).render("#pp-button");
        `;
    const addPayPhoneScript = async () => {
      const script2 = document.createElement("script");
      script2.type = "text/javascript";
      script2.id = "scriptPayphone";
      script2.innerHTML = scriptToInject;
      script2.async = true;
      script2.onload = () => {
        setSdkReady2(true);
      };

      document.body.appendChild(script2);
    };

    var element = document.getElementById("scriptPayphone") ? true : false;

    if (element) {
      setSdkReady2(true);
    }

    if (sdkReady2 === false) {
      addPayPhoneScript();
    }
  }, [apiUrl, sdkReady2, total]);
  return (
    <div style={{ textAlign: "center", marginTop: "25vh" }}>
      <h5>Pago $300</h5>
      <div id="pp-button" style={{ marginTop: "5vh" }}></div>
    </div>
  );
};

export default PayRamiro;
