import React, { useState, useEffect } from "react";
import { FaEye } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Layout from "../components/Layout/Layout";
import MobileMenu from "../components/Header/MobileMenu";
import Header from "../components/Header/Header";
import Footer from "../components/Layout/Footer";
import Banner from "../components/Banner";
import Loader from "../components/Loader";
import Alert from "../components/Alerts";
import Seo from "../components/seo";
import { listMyReservas } from "../redux/actions/reservaActions";
import { resetReagendamiento } from "../redux/actions/reagendamientoActions";
import { RESERVA_DELETE_RESET } from "../redux/constants/reservasConstants";

const columns = [
  { id: "code", label: "Código de Reserva", minWidth: 100 },

  {
    id: "numJuegos",
    label: "Número de Juegos",
    minWidth: 170,
    align: "right",
  },
  {
    id: "estado",
    label: "Estado Reserva",
    minWidth: 170,
    align: "right",
  },
  {
    id: "acciones",
    label: "Detalles",
    minWidth: 170,
    align: "right",
  },
];

const MisReservasPage = ({ history }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();
  const reservasList = useSelector((state) => state.reservasList);
  const { loading, error, reservas } = reservasList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "/static/js/vendor/jquery-library.js";
    script.async = true;
    script.onload = () => {
      const script13 = document.createElement("script");
      script13.type = "text/javascript";
      script13.src = "/static/js/jquery-ui.js";
      script13.async = true;

      const script2 = document.createElement("script");
      script2.type = "text/javascript";
      script2.src = "/static/js/vendor/modernizr-2.8.3-respond-1.4.2.min.js";
      script2.async = true;
      const script3 = document.createElement("script");
      script3.type = "text/javascript";
      script3.src = "/static/js/vendor/bootstrap.min.js";
      script3.async = true;
      const script4 = document.createElement("script");
      script4.type = "text/javascript";
      script4.src = "/static/js/owl.carousel.js";
      script4.async = true;
      const script5 = document.createElement("script");
      script5.type = "text/javascript";
      script5.src = "/static/js/isotope.pkgd.js";
      script5.async = true;
      const script6 = document.createElement("script");
      script6.type = "text/javascript";
      script6.src = "/static/js/prettyPhoto.js";
      script6.async = true;
      const script7 = document.createElement("script");
      script7.type = "text/javascript";
      script7.src = "/static/js/customScrollbar.min.js";
      script7.async = true;
      const script9 = document.createElement("script");
      script9.type = "text/javascript";
      script9.src = "/static/js/swiper.min.js";
      script9.async = true;
      const script10 = document.createElement("script");
      script10.type = "text/javascript";
      script10.src = "/static/js/countTo.js";
      script10.async = true;
      const script11 = document.createElement("script");
      script11.type = "text/javascript";
      script11.src = "/static/js/appear.js";
      script11.async = true;
      const script8 = document.createElement("script");
      script8.type = "text/javascript";
      script8.src = "/static/js/main.js";
      script8.async = true;
      document.body.appendChild(script13);
      document.body.appendChild(script2);
      document.body.appendChild(script3);
      document.body.appendChild(script4);
      document.body.appendChild(script5);
      document.body.appendChild(script6);
      document.body.appendChild(script7);
      document.body.appendChild(script9);
      document.body.appendChild(script10);
      document.body.appendChild(script11);
      document.body.appendChild(script8);
    };

    document.body.appendChild(script);
    dispatch(resetReagendamiento());
    if (!userInfo) {
      history.push("/login");
    } else {
      dispatch(listMyReservas());
      dispatch({ type: RESERVA_DELETE_RESET });
    }
    window.scrollTo(0, 0);
  }, [dispatch, history, userInfo]);

  return (
    <Layout>
      <Seo
        titlePage="Mis Reservas"
        descriptionPage="Pádel Cuenca. Somos un club conformado por personas amantes del deporte, enfocados en fomentar la vida activa y saludable, priorizando el ejercicio como un hábito que beneficie nuestro estado físico y mental, Disponemos de canchas de Pádel en Cuenca Ecuador para que pueda realizar una reserva."
      />

      <div id="tg-wrapper" className="tg-wrapper tg-haslayout">
        <MobileMenu />
        <Header />
        <Banner title="Mis Reservas" />
        <section className="tg-main-section tg-paddingbottom-zero tg-haslayout">
          <div className="container">
            <div className="tg-section-name">
              <h2>mis reservas</h2>
            </div>

            <div>
              <div className="content-wrapper">
                <h3>Mis Reservas</h3>
              </div>
              {loading ? (
                <div style={{ textAlign: "center" }}>
                  {" "}
                  <Loader />
                </div>
              ) : error ? (
                <Alert message={error} severity="error" />
              ) : (
                reservas && (
                  <>
                    <Paper
                      sx={{
                        width: "100%",
                        overflow: "hidden",
                        marginBottom: "25px",
                      }}
                      elevation={6}
                    >
                      <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              {columns.map((column) => (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  style={{ minWidth: column.minWidth }}
                                >
                                  {column.label}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {reservasList &&
                              reservasList.reservas
                                .slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                                .map((row) => {
                                  return (
                                    <TableRow
                                      hover
                                      role="checkbox"
                                      tabIndex={-1}
                                    >
                                      <TableCell align="right">
                                        {row.codigo}
                                      </TableCell>

                                      <TableCell align="right">
                                        {row.horario.length}
                                      </TableCell>
                                      <TableCell align="right">
                                        {row.estado === "PENDIENTE DE PAGO" ? (
                                          <span
                                            style={{
                                              padding: "5px",
                                              backgroundColor: "#eed202",
                                              color: "white",
                                              fontWeight: "600",
                                            }}
                                          >
                                            {row.estado}
                                          </span>
                                        ) : (
                                          <span
                                            style={{
                                              padding: "5px",
                                              backgroundColor: "green",
                                              color: "white",
                                              fontWeight: "600",
                                            }}
                                          >
                                            {row.estado}
                                          </span>
                                        )}
                                      </TableCell>
                                      <TableCell align="right">
                                        <div>
                                          <Link
                                            to={`/usuario/reservas/${row.codigo}`}
                                            className="detallesLink"
                                          >
                                            <FaEye
                                              style={{
                                                fontSize: "30px",
                                                color: "#8dbe22",
                                                paddingTop: "10px",
                                              }}
                                            />{" "}
                                            {/* <span>Ver Detalles</span> */}
                                          </Link>
                                        </div>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        component="div"
                        count={reservasList.reservas.length}
                        rowsPerPage={rowsPerPage}
                        labelRowsPerPage={"Reservas por Página"}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Paper>
                  </>
                )
              )}
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </Layout>
  );
};

export default MisReservasPage;
