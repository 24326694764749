import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Layout from "../components/Layout/Layout";
import MobileMenu from "../components/Header/MobileMenu";
import Header from "../components/Header/Header";
import Footer from "../components/Layout/Footer";
import Banner from "../components/Banner";
import Loader from "../components/Loader";
import TicketResumen from "../components/TicketResumen";
import { getReservaResult } from "../redux/actions/reservaPlaceActions";
import { RESERVA_PLACE_RESET } from "../redux/constants/reservaPlace";
import Seo from "../components/seo";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#43424D",
    color: theme.palette.common.white,
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ResultadoPago = ({ match, history }) => {
  /*  const [status, setStatus] = useState(-1); */
  const [seconds, setSeconds] = useState(5);
  const transaccionId = match.params.id;
  const dispatch = useDispatch();
  const placeReserva = useSelector((state) => state.placeReserva);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const { loading, reservaResult, error } = placeReserva;

  if (reservaResult !== undefined) {
    console.log(reservaResult.statusCode);
    if (reservaResult.statusCode === undefined) {
      if (seconds > 0) {
        setTimeout(() => setSeconds(seconds - 1), 5000);
      }
    }
  }
  useEffect(() => {
    dispatch({ type: RESERVA_PLACE_RESET });
    dispatch(getReservaResult(transaccionId));

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "/static/js/vendor/jquery-library.js";
    script.async = true;
    script.onload = () => {
      const script13 = document.createElement("script");
      script13.type = "text/javascript";
      script13.src = "/static/js/jquery-ui.js";
      script13.async = true;

      const script2 = document.createElement("script");
      script2.type = "text/javascript";
      script2.src = "/static/js/vendor/modernizr-2.8.3-respond-1.4.2.min.js";
      script2.async = true;
      const script3 = document.createElement("script");
      script3.type = "text/javascript";
      script3.src = "/static/js/vendor/bootstrap.min.js";
      script3.async = true;
      const script4 = document.createElement("script");
      script4.type = "text/javascript";
      script4.src = "/static/js/owl.carousel.js";
      script4.async = true;
      const script5 = document.createElement("script");
      script5.type = "text/javascript";
      script5.src = "/static/js/isotope.pkgd.js";
      script5.async = true;
      const script6 = document.createElement("script");
      script6.type = "text/javascript";
      script6.src = "/static/js/prettyPhoto.js";
      script6.async = true;
      const script7 = document.createElement("script");
      script7.type = "text/javascript";
      script7.src = "/static/js/customScrollbar.min.js";
      script7.async = true;
      const script9 = document.createElement("script");
      script9.type = "text/javascript";
      script9.src = "/static/js/swiper.min.js";
      script9.async = true;
      const script10 = document.createElement("script");
      script10.type = "text/javascript";
      script10.src = "/static/js/countTo.js";
      script10.async = true;
      const script11 = document.createElement("script");
      script11.type = "text/javascript";
      script11.src = "/static/js/appear.js";
      script11.async = true;
      const script8 = document.createElement("script");
      script8.type = "text/javascript";
      script8.src = "/static/js/main.js";
      script8.async = true;
      document.body.appendChild(script13);
      document.body.appendChild(script2);
      document.body.appendChild(script3);
      document.body.appendChild(script4);
      document.body.appendChild(script5);
      document.body.appendChild(script6);
      document.body.appendChild(script7);
      document.body.appendChild(script9);
      document.body.appendChild(script10);
      document.body.appendChild(script11);
      document.body.appendChild(script8);
    };

    document.body.appendChild(script);
  }, [dispatch, transaccionId, seconds, history, userInfo]);

  return (
    <Layout>
      <Seo
        titlePage="Resultado Pago"
        descriptionPage="Pádel Cuenca. Somos un club conformado por personas amantes del deporte, enfocados en fomentar la vida activa y saludable, priorizando el ejercicio como un hábito que beneficie nuestro estado físico y mental, Disponemos de canchas de Pádel en Cuenca Ecuador para que pueda realizar una reserva."
      />

      <div id="tg-wrapper" className="tg-wrapper tg-haslayout">
        <MobileMenu />
        <Header />
        <Banner title="Resultado" />
        <section className="tg-main-section tg-paddingbottom-zero tg-haslayout">
          <div className="container">
            <div className="tg-section-name">
              <h2>resultado</h2>
            </div>

            <div>
              <div className="content-wrapper">
                <h3>Resultado reserva</h3>
                {/*  <TicketResumen /> */}
              </div>
              {loading ? (
                <Loader />
              ) : error ? (
                <p>{error}</p>
              ) : (
                reservaResult && (
                  <>
                    <div class="row" style={{ marginBottom: "30px" }}>
                      {reservaResult.codigo ? (
                        <div className="tg-tickets">
                          <div className="col-md-6 col-sm-12 col-xs-12">
                            <div
                              style={{ textAlign: "center", marginTop: "15px" }}
                            >
                              <h6>Horario de la Reserva</h6>
                              <TableContainer component={Paper}>
                                <Table
                                  sx={{ minWidth: 200 }}
                                  aria-label="customized table"
                                >
                                  <TableHead>
                                    <TableRow>
                                      <StyledTableCell align="right">
                                        Cancha
                                      </StyledTableCell>
                                      <StyledTableCell align="right">
                                        Fecha
                                      </StyledTableCell>
                                      <StyledTableCell align="right">
                                        Hora
                                      </StyledTableCell>
                                      <StyledTableCell align="right">
                                        Precio
                                      </StyledTableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {reservaResult.horario &&
                                      reservaResult.horario.map(
                                        (hor, index) => (
                                          <StyledTableRow key={index}>
                                            <StyledTableCell align="right">
                                              {hor.cancha}
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                              {hor.fecha.slice(0, 10)}
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                              {hor.hora.slice(0, 5)}
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                              $ {hor.precio}
                                            </StyledTableCell>
                                          </StyledTableRow>
                                        )
                                      )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </div>
                          </div>
                          <div
                            className="col-md-4 col-sm-12 col-xs-12"
                            style={{ marginTop: "15px" }}
                          >
                            <h6>Pago</h6>
                            <div style={{ textAlign: "center" }}>
                              <TicketResumen
                                codigo={reservaResult.codigo}
                                email={reservaResult.email}
                                estado={reservaResult.statusCode}
                                cantidad={reservaResult.amount}
                              />
                            </div>
                          </div>
                          <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="buttonContainer">
                              {userInfo ? (
                                <Link
                                  className="tg-btn"
                                  to="/usuario/reservas"
                                  style={{ marginTop: "15px" }}
                                >
                                  ver mis reservas
                                </Link>
                              ) : (
                                <Link
                                  className="tg-btn"
                                  to="/consulta"
                                  style={{ marginTop: "15px" }}
                                >
                                  consultar una reserva
                                </Link>
                              )}
                              <Link
                                className="tg-btn"
                                to="/reservas/invitado"
                                style={{ marginTop: "15px", marginLeft: "5px" }}
                              >
                                realizar otra reserva
                              </Link>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div style={{ textAlign: "center" }}>
                          <Loader />
                          <p style={{ fontSize: "20px" }}>
                            Por favor espere mientras validamos su pago.
                          </p>
                        </div>
                      )}
                    </div>
                  </>
                )
              )}
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </Layout>
  );
};

export default ResultadoPago;
