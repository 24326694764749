import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaArrowLeft } from "react-icons/fa";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Layout from "../components/Layout/Layout";
import MobileMenu from "../components/Header/MobileMenu";
import Header from "../components/Header/Header";
import Footer from "../components/Layout/Footer";
import Banner from "../components/Banner";
import MaterialSteps from "../components/ReservasPage/MaterialStepper";
import Message from "../components/Alerts";
import useDimentions from "../components/WindowDimentions";
import { RESERVA_ADD_CODIGO_RESET } from "../redux/constants/reservasConstants";
import Seo from "../components/seo";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#43424D",
    color: theme.palette.common.white,
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableCellTotal = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 18,
    fontWeight: 700,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const PagoReservaScreen = ({ history }) => {
  const apiUrl =
    process.env.NODE_ENV === "production"
      ? "https://www.padelcuenca.ec"
      : "http://localhost:3000";
  const { width } = useDimentions();
  const dispatch = useDispatch();
  const [sdkReady2, setSdkReady2] = useState(false);

  const reserva = useSelector((state) => state.reserva);
  const reservaPlace = useSelector((state) => state.placeReserva);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const { reservaResult, error } = reservaPlace;
  if (!reservaResult && !error) {
    history.push("/reservas/invitado");
  }
  const total = reservaResult
    ? reservaResult.horario.reduce(
        (prevValue, currentValue) => prevValue + currentValue.precio,
        0
      ) * 100
    : 0;
  const handleRegresar = (e) => {
    e.preventDefault();
    history.push("/reservas/horario");
  };

  useEffect(() => {
    dispatch({ type: RESERVA_ADD_CODIGO_RESET });
    if ((reservaResult === undefined) & (error === undefined)) {
      history.push("/reservas/invitado");
    } else {
      const id = reservaResult ? reservaResult.reserva.codigo : 999;

      const scriptToInject = `
  
    payphone.Button({
    
    //token obtenido desde la consola de developer
    token: "rCSOHNyxTVVEh7COI7jEz38XjdJ15PXjMWzwD7wZbFbS4tFaJHHfbIA_q-bsbAbdPUW1T647QWkdsxHGuAWJcZUc2rr2xbgkP0MXAt4L1OAeZllkv1SDlzr9NSv8rzRS0oGG7ojSu9N_4-2O2It7EYrbI0yb_mLXCcTQ8Ya3OpFDl8oSv197oAnbQ_PefCt5uamXtWOThafFvh0Ouw3_Ij_iSbZlTEOy4n0R4moJVcl2afqZnHmidoJmA-9lXJB6-k-XdHxYh2M__hxSOAAcfib4lqtnz_MKRzQdkS9I1TloHvtT9g4khFb9KuH1x4lLcdrNeQ",
    
    //PARÁMETROS DE CONFIGURACIÓN
    btnHorizontal: false,
    btnCard: true,
    
    createOrder: function(actions){
    
    //Se ingresan los datos de la transaccion ej. monto, impuestos, etc
    return actions.prepare({
    
    amount:  ${total},
    amountWithoutTax: ${total},
    currency: "USD",
    clientTransactionId: "${id}"
    });
    
    },
    
    onComplete: function(model, actions){
    
    //Se confirma el pago realizado
    actions.confirm({
    id: model.id,
    clientTxId: model.clientTxId
    }).then(function(value){
    
    //EN ESTA SECCIÓN SE RECIBE LA RESPUESTA Y SE MUESTRA AL USUARIO                                
    
    if (value.transactionStatus == "Approved"){
    location.replace('${apiUrl}/resultado-pago/'+value.transactionId)
    }else{
      location.replace('${apiUrl}/reservas/resultado/error/'+value.transactionId)
    }
    }).catch(function(err){
    console.log(err);
    location.replace('${apiUrl}/reservas/resultado/error/${id}');
    });
    
    }
    }).render("#pp-button");
    `;

      const addPayPhoneScript = async () => {
        const script2 = document.createElement("script");
        script2.type = "text/javascript";
        script2.id = "scriptPayphone";
        script2.innerHTML = scriptToInject;
        script2.async = true;
        script2.onload = () => {
          setSdkReady2(true);
        };

        document.body.appendChild(script2);
      };

      var element = document.getElementById("scriptPayphone") ? true : false;
      if (element) {
        setSdkReady2(true);
      }
      if (sdkReady2 === false) {
        addPayPhoneScript();
      }
    }

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "/static/js/vendor/jquery-library.js";
    script.async = true;
    script.onload = () => {
      const script13 = document.createElement("script");
      script13.type = "text/javascript";
      script13.src = "/static/js/jquery-ui.js";
      script13.async = true;

      const script2 = document.createElement("script");
      script2.type = "text/javascript";
      script2.src = "/static/js/vendor/modernizr-2.8.3-respond-1.4.2.min.js";
      script2.async = true;
      const script3 = document.createElement("script");
      script3.type = "text/javascript";
      script3.src = "/static/js/vendor/bootstrap.min.js";
      script3.async = true;
      const script4 = document.createElement("script");
      script4.type = "text/javascript";
      script4.src = "/static/js/owl.carousel.js";
      script4.async = true;
      const script5 = document.createElement("script");
      script5.type = "text/javascript";
      script5.src = "/static/js/isotope.pkgd.js";
      script5.async = true;
      const script6 = document.createElement("script");
      script6.type = "text/javascript";
      script6.src = "/static/js/prettyPhoto.js";
      script6.async = true;
      const script7 = document.createElement("script");
      script7.type = "text/javascript";
      script7.src = "/static/js/customScrollbar.min.js";
      script7.async = true;
      const script9 = document.createElement("script");
      script9.type = "text/javascript";
      script9.src = "/static/js/swiper.min.js";
      script9.async = true;
      const script10 = document.createElement("script");
      script10.type = "text/javascript";
      script10.src = "/static/js/countTo.js";
      script10.async = true;
      const script11 = document.createElement("script");
      script11.type = "text/javascript";
      script11.src = "/static/js/appear.js";
      script11.async = true;
      const script8 = document.createElement("script");
      script8.type = "text/javascript";
      script8.src = "/static/js/main.js";
      script8.async = true;
      document.body.appendChild(script13);
      document.body.appendChild(script2);
      document.body.appendChild(script3);
      document.body.appendChild(script4);
      document.body.appendChild(script5);
      document.body.appendChild(script6);
      document.body.appendChild(script7);
      document.body.appendChild(script9);
      document.body.appendChild(script10);
      document.body.appendChild(script11);
      document.body.appendChild(script8);
    };

    document.body.appendChild(script);
  }, [
    history,
    reservaResult,
    total,
    sdkReady2,
    setSdkReady2,
    error,
    userInfo,
    dispatch,
    apiUrl,
  ]);

  return (
    <Layout>
      <Seo
        titlePage="Pago"
        descriptionPage="Pádel Cuenca. Somos un club conformado por personas amantes del deporte, enfocados en fomentar la vida activa y saludable, priorizando el ejercicio como un hábito que beneficie nuestro estado físico y mental, Disponemos de canchas de Pádel en Cuenca Ecuador para que pueda realizar una reserva."
      />
      <div id="tg-wrapper" className="tg-wrapper tg-haslayout">
        <MobileMenu />
        <Header />
        <Banner title="Confirmación" />
        <section className="tg-main-section tg-paddingbottom-zero tg-haslayout">
          <div className={width >= 600 ? "container" : ""}>
            <div className="tg-section-name">
              <h2>pago</h2>
            </div>

            <div className="col-sm-11 col-xs-11 pull-right">
              {/*  <ReservaSteps step={3} /> */}
              <MaterialSteps step={3} />
            </div>
            <div className="col-sm-11 col-xs-11 ">
              <div className="">
                {/*  <h6>Resumen de la reserva</h6> */}
                {!error && reservaResult && (
                  <div style={{ textAlign: "center", marginBottom: "35px" }}>
                    <h6
                      style={{
                        fontSize: "24px",
                        fontWeight: "700",
                        color: "#8dbe22",
                      }}
                    >
                      Reserva pendiente de Pago
                    </h6>
                    <p
                      style={{
                        fontSize: "22px",
                        fontWeight: "700",
                      }}
                    >
                      Su código de reserva es:{" "}
                      <span
                        style={{
                          fontSize: "24px",
                          fontWeight: "700",
                          color: "#8dbe22",
                        }}
                      >
                        {reservaResult.reserva.codigo}
                      </span>
                    </p>
                  </div>
                )}
              </div>
              <div class="row" style={{ marginBottom: "30px" }}>
                <div>
                  {error ? (
                    <>
                      <Message severity="error" message={error} />
                      <div className="buttonContainer">
                        <button
                          className="tg-btn"
                          style={{
                            background: "#41404B",
                            color: "white",
                            marginTop: "15px",
                          }}
                          onClick={handleRegresar}
                        >
                          <FaArrowLeft /> Regresar
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="col-md-8 col-sm-12 col-xs-12"
                        style={{ marginTop: "25px" }}
                      >
                        <h6>Resumen de la reserva</h6>
                        <TableContainer component={Paper}>
                          <Table
                            sx={{ minWidth: 200 }}
                            aria-label="customized table"
                          >
                            <TableHead>
                              <TableRow>
                                <StyledTableCell>Descripción</StyledTableCell>
                                <StyledTableCell align="right">
                                  Fecha
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  Hora
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  Precio
                                </StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {reserva.schedule.map((reser, index) => (
                                <StyledTableRow key={index}>
                                  <StyledTableCell component="th" scope="row">
                                    Reserva - {reserva.court.nombre_cancha}
                                  </StyledTableCell>
                                  <StyledTableCell align="right">
                                    {reser.fecha}
                                  </StyledTableCell>
                                  <StyledTableCell align="right">
                                    {reser.hora}
                                  </StyledTableCell>
                                  <StyledTableCell align="right">
                                    ${reser.precio}
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))}
                              <StyledTableRow>
                                <StyledTableCell rowSpan={1} />
                                <StyledTableCellTotal colSpan={2}>
                                  Total
                                </StyledTableCellTotal>
                                <StyledTableCellTotal align="right">
                                  <span>
                                    ${" "}
                                    {reservaResult &&
                                      parseFloat(
                                        reservaResult.horario.reduce(
                                          (prevValue, currentValue) =>
                                            parseFloat(prevValue) +
                                            parseFloat(currentValue.precio),
                                          0
                                        )
                                      )}
                                  </span>
                                </StyledTableCellTotal>
                              </StyledTableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                      <div
                        className="col-md-4 col-sm-12 col-xs-12"
                        style={{ marginTop: "25px" }}
                      >
                        {/*  <h6>Pago</h6> */}
                        <div className="tg-description">
                          <>
                            <div id="pp-button"></div>

                            {/*  */}
                          </>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </Layout>
  );
};

export default PagoReservaScreen;
