import React from "react";
import { Link } from "react-router-dom";
import image from "../../images/padelHistory.jpg";

const aboutus = ({ isPage }) => {
  return (
    <section className="tg-main-section tg-haslayout">
      <div className="container">
        <div className="tg-section-name">
          <h2>Acerca Pádel Cuenca</h2>
        </div>
        <div className="col-sm-11 col-xs-11 pull-right">
          <div className="row">
            <div className="tg-aboutussection">
              <div className="row">
                <div className="col-md-6 col-sm-12 col-xs-12">
                  <figure>
                    <img src={image} alt="Pádel Cuenca" />
                  </figure>
                </div>
                <div className="col-md-6 col-sm-12 col-xs-12">
                  <div className="tg-contentbox">
                    <div className="tg-section-heading">
                      <h2>fomentando el deporte y vida sana</h2>
                    </div>
                    <div className="tg-description">
                      <p>
                        Somos un club conformado por personas amantes del
                        deporte, enfocados en fomentar la vida activa y
                        saludable, priorizando el ejercicio como un hábito que
                        beneficie nuestro estado físico y mental, queremos que
                        más gente se ejercite por medio de diferentes deportes,
                        en este caso el pádel, un deporte divertido, que
                        conlleva muchas características como la agilidad,
                        velocidad, fuerza, coordinación, trabajo en equipo.
                      </p>
                    </div>
                    {isPage && (
                      <div className="tg-btnbox">
                        <Link
                          className="tg-btn"
                          to="/about-us"
                          style={{ marginTop: "15px" }}
                        >
                          <span>leer más</span>
                        </Link>
                        {/*  <img
                          src={logoProdubanco}
                          style={{ marginTop: "15px", marginLeft: "35px" }}
                        /> */}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!isPage && (
            <div className="tg-btnbox">
              <div
                className="col-md-6 col-sm-12 col-xs-12"
                style={{ marginTop: "35px" }}
              >
                <h5>Misión</h5>
                <p>
                  Contar con un espacio de esparcimiento para todas las edades,
                  con el propósito de fomentar la cultura deportiva dentro de un
                  estilo de vida saludable
                </p>
              </div>
              <div
                className="col-md-6 col-sm-12 col-xs-12"
                style={{ marginTop: "35px" }}
              >
                <h5>Visión</h5>
                <p>
                  Fortalecer el crecimiento del pádel a nivel local y nacional,
                  entregando un servicio de calidad que brinde las mejores
                  condiciones y se aproveche de la mejor forma la práctica de
                  este deporte.
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default aboutus;
