import React from "react";
import "../../styles/recargasCard.css";

const RecargasCard = ({ title }) => {
  return (
    <div>
      <div className="cardRe front">
        <div className="blue"></div>
        <div className="yellow"></div>
        <div className="pink"></div>
        <div className="dots"></div>
        <div className="personal-intro">
          <p>{title}</p>
          <p>Pádel Cuenca</p>
        </div>
      </div>
    </div>
  );
};

export default RecargasCard;
