import React, { useState, useEffect } from "react";
import api from "../api/api";
import { useDispatch } from "react-redux";
import { RESERVA_PLACE_RESET } from "../redux/constants/reservaPlace";

import Loader from "../components/Loader";
const PagoResultadoRamiroPage = ({ match }) => {
  const [seconds, setSeconds] = useState(5);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  console.log(success);
  const transaccionId = match.params.id;
  const dispatch = useDispatch();
  //const placeReserva = useSelector((state) => state.placeReserva);
  //const { loading, reservaResult, error } = placeReserva;
  if (success) {
    console.log(success.statusCode);
    if (success.statusCode === undefined) {
      if (seconds > 0) {
        setTimeout(() => setSeconds(seconds - 1), 5000);
      }
    }
  }
  useEffect(() => {
    dispatch({ type: RESERVA_PLACE_RESET });
    setLoading(true);
    setError(null);
    setSuccess(null);
    const edit = async () => {
      try {
        const { data } = await api.get(
          `/transaccion/transferencia/${transaccionId}`
        );
        setSuccess(data);
        setLoading(false);
      } catch (error) {
        //console.log(error);
        setError("Algo salió mal.");
        setLoading(false);
      }
    };

    edit();
  }, [dispatch, transaccionId, seconds]);
  return (
    <div>
      {loading ? (
        <Loader />
      ) : error ? (
        <div style={{ marginTop: "45vh", textAlign: "center" }}>
          <h4>Algo salió mal, no pudimos confirmar la transacción.</h4>
        </div>
      ) : (
        success && (
          <>
            <div class="row" style={{ marginBottom: "30px" }}>
              {success.statusCode ? (
                <div className="tg-tickets">
                  <div
                    className="col-md-12 col-sm-12 col-xs-12"
                    style={{ marginTop: "30vh" }}
                  >
                    <div style={{ textAlign: "center", marginTop: "15px" }}>
                      <h6>Su pago ha sido confirmado</h6>

                      <p>Correo: {success.email}</p>
                      <p>
                        Estado:{" "}
                        {success.statusCode === 3 ? "CONFIRMADO" : "CANCELADO"}
                      </p>
                      <p>Cantidad: $ {success.amount}</p>
                    </div>
                  </div>
                </div>
              ) : (
                <div style={{ textAlign: "center" }}>
                  <Loader />
                  <p style={{ fontSize: "20px" }}>
                    Por favor espere mientras validamos su pago.
                  </p>
                </div>
              )}
            </div>
          </>
        )
      )}
    </div>
  );
};

export default PagoResultadoRamiroPage;
