import React from "react";
import imagen1 from "../images/player/paddelPlayerwb.png";
import imagen3 from "../images/player/paddelPlayer3wb.png";
import imagen4 from "../images/player/paddelplayer4.png";

const slider = [
  {
    image: <img src={imagen1} alt="Padel Cuenca" />,
    titleparte1: "Reserva",
    titleparte2: "Ahora",
    subtitle: "El deporte sensación en Cuenca",
    button1: "Reserva una Cancha",
    button1Link: "/reservas/invitado",
    button2: "Reserva una Cancha",
    button2Link: "/reservas/invitado",
  },
  {
    image: <img src={imagen4} alt="Padel Cuenca" />,
    titleparte1: "Pádel",
    titleparte2: "Cuenca",
    subtitle: "¡El Pádel llegó a Ecuador!",
    button1: "Donde estamos ubicados",
    button1Link: "/contacto",
    button2: "Red Social de Padding",
    button2Link: "/",
  },
  {
    image: <img src={imagen3} alt="Padel Cuenca" />,
    titleparte1: "Beneficios de",
    titleparte2: " Socios",
    subtitle: "Conoce los Beneficios",
    button1: "Conviertete en Socio",
    button1Link: "/beneficios",
    button2: "Registrate y Conviertete en Socio",
    button2Link: "/",
  },
];

export default slider;
