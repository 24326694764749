import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import MobileMenu from "../components/Header/MobileMenu";
import Header from "../components/Header/Header";
import Footer from "../components/Layout/Footer";
import Banner from "../components/Banner";

import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/Loader";
import Message from "../components/Alerts";
import { login } from "../redux/actions/userActions";
import Seo from "../components/seo";
const LoginPage = ({ location, history }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;

  const redirect = location.search ? location.search.split("=")[1] : "/";

  useEffect(() => {
    window.scrollTo(0, 0);
    if (userInfo) {
      history.push(redirect);
    }
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "/static/js/vendor/jquery-library.js";
    script.async = true;
    script.onload = () => {
      const script13 = document.createElement("script");
      script13.type = "text/javascript";
      script13.src = "/static/js/jquery-ui.js";
      script13.async = true;

      const script2 = document.createElement("script");
      script2.type = "text/javascript";
      script2.src = "/static/js/vendor/modernizr-2.8.3-respond-1.4.2.min.js";
      script2.async = true;
      const script3 = document.createElement("script");
      script3.type = "text/javascript";
      script3.src = "/static/js/vendor/bootstrap.min.js";
      script3.async = true;
      const script4 = document.createElement("script");
      script4.type = "text/javascript";
      script4.src = "/static/js/owl.carousel.js";
      script4.async = true;
      const script5 = document.createElement("script");
      script5.type = "text/javascript";
      script5.src = "/static/js/isotope.pkgd.js";
      script5.async = true;
      const script6 = document.createElement("script");
      script6.type = "text/javascript";
      script6.src = "/static/js/prettyPhoto.js";
      script6.async = true;
      const script7 = document.createElement("script");
      script7.type = "text/javascript";
      script7.src = "/static/js/customScrollbar.min.js";
      script7.async = true;
      const script9 = document.createElement("script");
      script9.type = "text/javascript";
      script9.src = "/static/js/swiper.min.js";
      script9.async = true;
      const script10 = document.createElement("script");
      script10.type = "text/javascript";
      script10.src = "/static/js/countTo.js";
      script10.async = true;
      const script11 = document.createElement("script");
      script11.type = "text/javascript";
      script11.src = "/static/js/appear.js";
      script11.async = true;
      const script8 = document.createElement("script");
      script8.type = "text/javascript";
      script8.src = "/static/js/main.js";
      script8.async = true;
      document.body.appendChild(script13);
      document.body.appendChild(script2);
      document.body.appendChild(script3);
      document.body.appendChild(script4);
      document.body.appendChild(script5);
      document.body.appendChild(script6);
      document.body.appendChild(script7);
      document.body.appendChild(script9);
      document.body.appendChild(script10);
      document.body.appendChild(script11);
      document.body.appendChild(script8);
    };

    document.body.appendChild(script);
  }, [history, userInfo, redirect]);

  const submitHandler = (e) => {
    e.preventDefault();
    //dispatch login
    dispatch(login(email, password));
  };
  return (
    <Layout>
      <Seo
        titlePage="Ingresar"
        descriptionPage="Pádel Cuenca. Somos un club conformado por personas amantes del deporte, enfocados en fomentar la vida activa y saludable, priorizando el ejercicio como un hábito que beneficie nuestro estado físico y mental, Disponemos de canchas de Pádel en Cuenca Ecuador para que pueda realizar una reserva."
      />
      <div id="tg-wrapper" className="tg-wrapper tg-haslayout">
        <MobileMenu />
        <Header />
        <Banner title="Iniciar Sesión" />
        <main id="tg-main" className="tg-main tg-haslayout">
          <div className="modal-dialog" role="document">
            <div className="tg-modal-content">
              <div className="tg-formarea">
                <div className="tg-border-heading">
                  <h3>Iniciar Sesión</h3>
                </div>
                {error && <Message message={error} severity="error" />}
                {loading && <Loader />}
                <form className="tg-loginform" onSubmit={submitHandler}>
                  <fieldset>
                    <div className="form-group">
                      <input
                        type="email"
                        name="correo"
                        value={email}
                        className="form-control"
                        placeholder="Correo electrónico"
                        required
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        name="password"
                        value={password}
                        className="form-control"
                        placeholder="contraseña"
                        required
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                    {/* <div className="form-group">
                  <label>
                    <input
                      type="checkbox"
                      value="rememberme"
                      className="checkbox"
                    />
                    <em>Remember Me</em>
                  </label>
                  <a href="#">
                    <em>Forgot Password</em>
                    <i className="fa fa-question-circle"></i>
                  </a>
                </div> */}
                    <div className="form-group">
                      <button
                        className="tg-btn tg-btn-lg tg-description"
                        type="submit"
                      >
                        Iniciar Sesión
                      </button>
                    </div>
                    <div className="tg-description">
                      <p>
                        ¿No tienes una cuenta?{" "}
                        <Link to="/registro">Regístrate</Link>
                      </p>
                    </div>
                    <div className="tg-description">
                      <p>
                        ¿Olvidaste tu contraseña?{" "}
                        <Link to="/recuperar-contrasena">
                          Recuperar Contraseña
                        </Link>
                      </p>
                    </div>
                  </fieldset>
                </form>
              </div>
              {/*   <div className="tg-logintype">
                <div className="tg-border-heading">
                  <h3>Login with</h3>
                </div>
                <ul>
                  <li className="tg-facebook">
                    <a href="#">facebook</a>
                  </li>
                  <li className="tg-twitter">
                    <a href="#">twitter</a>
                  </li>
                  <li className="tg-googleplus">
                    <a href="#">google+</a>
                  </li>
                  <li className="tg-linkedin">
                    <a href="#">linkedin</a>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </Layout>
  );
};

export default LoginPage;
