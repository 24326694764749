import api from "../../api/api";
import {
  RECARGA_PLACE_FAIL,
  RECARGA_PLACE_REQUEST,
  RECARGA_PLACE_SUCCESS,
  RECARGA_RESULT_FAIL,
  RECARGA_RESULT_REQUEST,
  RECARGA_RESULT_SUCCESS,
  RECARGA_TRANSFERENCIA_RESULT_ADD,
} from "../constants/recargasConstants";

export const getTempCodeRecarga = (email) => async (dispatch, getState) => {
  try {
    dispatch({ type: RECARGA_PLACE_REQUEST });

    const request = await api.get(`/codigo`);
    //console.log(request);
    const usuario = { email };
    const codigo = request.data.codigo;
    const { data } = await api.post(`/recarga`, { codigo, usuario });
    dispatch({
      type: RECARGA_PLACE_SUCCESS,
      payload: data,
    });
    dispatch({
      type: RECARGA_TRANSFERENCIA_RESULT_ADD,
      payload: data,
    });
    //console.log(data);
    /* localStorage.setItem(
      "reserva",
      
    ); */
  } catch (error) {
    console.log(error);
    dispatch({
      type: RECARGA_PLACE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getRecargaResult = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: RECARGA_RESULT_REQUEST });

    const { data } = await api.get(`/transaccion/recarga/${id}`);
    dispatch({
      type: RECARGA_RESULT_SUCCESS,
      payload: data,
    });
    //console.log(data);
    /* localStorage.setItem(
      "reserva",
      
    ); */
  } catch (error) {
    console.log(error);
    dispatch({
      type: RECARGA_RESULT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
