/* eslint-disable */
import React, { useState } from "react";

import ReactMapGL, { Marker, Popup } from "react-map-gl";
// added the following 6 lines.
import mapboxgl from "mapbox-gl";

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
//

import { FaMapMarkerAlt } from "react-icons/fa";
import "mapbox-gl/dist/mapbox-gl.css";
//eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const MapConponent = () => {
  const [viewport, setViewport] = useState({
    latitude: -2.85488290804471,
    longitude: -78.9082347707168,
    width: "100vw",
    height: "50vh",
    zoom: 16,
  });

  return (
    <div /* style={{ height: "50vh", width: "100vw", textAlign: "center" }} */>
      <ReactMapGL
        {...viewport}
        scrollZoom={false}
        mapboxApiAccessToken="pk.eyJ1IjoiYXJpZWxicmF2bzEyIiwiYSI6ImNrZHpscHRyMTFlMWIyd29hZ2ljc2hqbDEifQ.IMRwImie8muWS3Q3vuAKTw"
        mapStyle="mapbox://styles/arielbravo12/ckw5b4pj6002u14pecjf0l013"
        onViewportChange={(viewport) => {
          setViewport(viewport);
        }}
        /* className={styles.map} */
      >
        <Marker latitude={-2.8542951192872197} longitude={-78.9082708612461}>
          <div className="marker temporary-marker">
            <span>
              <FaMapMarkerAlt
                style={{
                  fontSize: "50px",
                  color: "#8dbe22",
                  fontWeight: "100",
                }}
              />
            </span>
          </div>
        </Marker>
        <Popup latitude={-2.8544332845225195} longitude={-78.90801010673411}>
          <h6>Padel Cuenca</h6>
        </Popup>{" "}
        *
      </ReactMapGL>
    </div>
  );
};

export default MapConponent;
