export const RESERVA_ADD_COURT = "RESERVA_ADD_COURT";
export const RESERVA_DELETE_COURT = "RESERVA_DELETE_COURT";

export const RESERVA_ADD_USER = "RESERVA_ADD_USER";
export const RESERVA_DELETE_USER = "RESERVA_DELETE_USER";

export const RESERVA_ADD_SCHEDULE = "RESERVA_ADD_SCHEDULE";
export const RESERVA_DELETE_SCHEDULE = "RESERVA_DELETE_SCHEDULE";

export const RESERVA_PLACE_REQUEST = "RESERVA_PLACE_REQUEST";
export const RESERVA_PLACE_SUCCESS = "RESERVA_DELETE_SUCESS";
export const RESERVA_PLACE_FAIL = "RESERVA_DELETE_FAIL";

export const RESERVA_DELETE_PLACE = "RESERVA_DELETE_PLACE";

export const RESERVA_DETAILS_REQUEST = "RESERVA_DETAILS_REQUEST";
export const RESERVA_DETAILS_SUCCESS = "RESERVA_DETAILS_SUCESS";
export const RESERVA_DETAILS_FAIL = "RESERVA_DETAILS_FAIL";
export const RESERVA_DETAILS_RESET = "RESERVA_DETAILS_RESET";

export const RESERVA_LIST_MY_REQUEST = "ORDER_LIST_MY_REQUEST";
export const RESERVA_LIST_MY_SUCCESS = "ORDER_LIST_MY_SUCESS";
export const RESERVA_LIST_MY_FAIL = "ORDER_LIST_MY_FAIL";
export const RESERVA_LIST_MY_RESET = "ORDER_LIST_MY_RESET";

export const RESERVA_ADD_CODIGO_REQUEST = "RESERVA_ADD_CODIGO_REQUEST";
export const RESERVA_ADD_CODIGO_SUCCESS = "RESERVA_ADD_SUCCESS";
export const RESERVA_ADD_CODIGO_FAIL = "RESERVA_ADD_FAIL";
export const RESERVA_ADD_CODIGO_RESET = "RESERVA_ADD_CODIGO_RESET";

export const RESERVA_DELETE_REQUEST = "RESERVA_DELETE_REQUEST";
export const RESERVA_DELETE_SUCESS = "RESERVA_DELETE_SUCESS";
export const RESERVA_DELETE_FAIL = "RESERVA_DELETE_FAIL";
export const RESERVA_DELETE_RESET = "RESERVA_DELETE_RESET";
