import { BrowserRouter as Router, Route } from "react-router-dom";
import "./App.css";
import HomeScreen from "./pages/HomeScreen";
import ContactPage from "./pages/ContactPage";
import GuestUserPagoPage from "./pages/GuestUserScreen";
import HorariosPage from "./pages/HorariosPage";
import ResultadoPagoPage from "./pages/ResultradoPago";
import CanchasReservaPage from "./pages/CanchasScreen";

import AboutPage from "./pages/AboutPage";
import RecargarPage from "./pages/RecargarPage";
import PagoReservaPage from "./pages/PagoReservaPage";
import ResultadoInvitadoPago from "./pages/ResultradoPago";
import ConsultasPage from "./pages/ConsultaPage";
import MisReservasPage from "./pages/MisReservasPage";
import ReservaDetailsPage from "./pages/ReservaDetailsScreen";
import SeleccionarPagoPage from "./pages/seleccionarPagoPage";
import TransferenciaPagoPage from "./pages/trasnferenciaPagoPage";

//Usuario screen
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import RecuperarContraPage from "./pages/RecuperarContrasenaPage";
import NuevaContraPage from "./pages/NuevaContrasenaPage";

//reagendamiento screens
import CanchasReagendamiento from "./pages/ReagendarCanchasPage";
import HorarioReagendamientoPage from "./pages/ReagendarHorarioPage";
import ResumenReagendamientoPage from "./pages/ReagendamientoResumenPage";

//recargas screens
import RecargaResultScreenPage from "./pages/ResultadoRecarga";
import ResumenReservaUsuarioPage from "./pages/ResumenReservaUsuarioPage";
import BeneficiosPage from "./pages/BeneficiosPage";
import SeleccionarPagoRecargaScreen from "./pages/SeleccionarPagoRecargaPage";
import PagoTransferenciaRecargaScreen from "./pages/RecargarTransferenciaPage";

import UserPage from "./pages/UserPage";
/* import ErrorPage from "./screens/ErrorPage"; */
import ReservaPagoErrorPage from "./pages/ErrorPagoPayphonePage";
import RecargaPagoErrorPage from "./pages/ErrorRecargaPago";

//opcionales
import PagoRamiro from "./pages/PayRamiro";
import PagoRamiroResult from "./pages/PagoResultadoRamiroPage";
import PagoRamiroError from "./pages/PayRamiroErrorResult";
/* import Layout from "./components/Layout/Layout"; */
function App() {
  return (
    <Router>
      <Route path="/pago-ramiro" component={PagoRamiro} exact />
      <Route
        path="/pago-ramiro/resultado/:id?"
        component={PagoRamiroResult}
        exact
      />
      <Route path="/pago-ramiro/error/:id?" component={PagoRamiroError} exact />

      <Route path="/login" component={LoginPage} exact />
      <Route path="/registro" component={RegisterPage} exact />
      <Route path="/usuario" component={UserPage} exact />
      <Route
        path="/recuperar-contrasena"
        component={RecuperarContraPage}
        exact
      />
      <Route path="/nueva-contrasena/:id" component={NuevaContraPage} exact />
      <Route path="/about-us" component={AboutPage} exact />
      <Route path="/beneficios" component={BeneficiosPage} exact />
      <Route path="/contacto" component={ContactPage} exact />
      <Route path="/recargar/payphone" component={RecargarPage} exact />
      <Route
        path="/recargar/transferencia"
        component={PagoTransferenciaRecargaScreen}
        exact
      />
      <Route path="/recargar" component={SeleccionarPagoRecargaScreen} exact />
      <Route path="/reservas/invitado" component={GuestUserPagoPage} exact />
      <Route path="/reservas/cancha" component={CanchasReservaPage} exact />
      <Route path="/reservas/horario" component={HorariosPage} exact />
      <Route
        path="/reservas/metodo-pago"
        component={SeleccionarPagoPage}
        exact
      />
      <Route path="/consulta" component={ConsultasPage} exact />
      <Route path="/usuario/reservas" component={MisReservasPage} exact />

      <Route path="/reagendar/cancha" component={CanchasReagendamiento} />
      <Route path="/reagendar/horario" component={HorarioReagendamientoPage} />
      <Route path="/reagendar/resumen" component={ResumenReagendamientoPage} />
      <Route
        path="/usuario/reservas/:id"
        component={ReservaDetailsPage}
        exact
      />
      <Route
        path="/resultado-pago/:id"
        component={ResultadoInvitadoPago}
        exact
      />
      {/* creo que la ruta de abajo no se esta utilizando */}
      <Route
        path="/reservas/confirmar:id?"
        component={ResultadoPagoPage}
        exact
      />
      <Route
        path="/recargar/resultado-recarga/:id?"
        component={RecargaResultScreenPage}
        exact
      />
      <Route path="/reservas/pago/payphone" component={PagoReservaPage} exact />
      <Route
        path="/reservas/resumen-reserva"
        component={ResumenReservaUsuarioPage}
        exact
      />
      <Route
        path="/reservas/pago/transferencia"
        component={TransferenciaPagoPage}
        exact
      />
      <Route
        path="/reservas/resultado/error/:id"
        component={ReservaPagoErrorPage}
        exact
      />
      <Route
        path="/recarga/resultado/error/:id"
        component={RecargaPagoErrorPage}
        exact
      />
      {/* reagendamiento */}

      <Route path="/" component={HomeScreen} exact />
      {/*  <Route path="*" component={ErrorPage} /> */}
    </Router>
  );
}

export default App;
