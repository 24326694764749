import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../components/Layout/Layout";
import MobileMenu from "../components/Header/MobileMenu";
import Header from "../components/Header/Header";
import Footer from "../components/Layout/Footer";
import Banner from "../components/Banner";
import cancha from "../images/cancha_1 Banco Guayaquil.jpg";
import cancha2 from "../images/cancha_2 American Express.jpg";
import Loader from "../components/Loader";
import Message from "../components/Alerts";

import { listCourts } from "../redux/actions/courtActions";
import {
  addCourtToReserva,
  addUserToReserva,
  deleteScheduleToReserva,
} from "../redux/actions/reservaActions";
import { placeReservaReset } from "../redux/actions/reservaPlaceActions";
import MaterialStepperReserva from "../components/ReservasPage/MaterialStepper";
import "../components/ReservasPage/courtCardStyles.css";
import { RESERVA_ADD_CODIGO_RESET } from "../redux/constants/reservasConstants";
import Seo from "../components/seo";
const CanchasScreen = ( { history } ) => {
  const dispatch = useDispatch();

  const courts = useSelector( ( state ) => state.courtList );
  const { loading, error, courts: courtsList } = courts;
  const { user } = useSelector( ( state ) => state.reserva );
  const userLogin = useSelector( ( state ) => state.userLogin );
  const { userInfo } = userLogin;

  const addCourtHandler = ( id, nombre, direccion, precio, estado ) => {
    dispatch( addCourtToReserva( id, nombre, direccion, precio, estado ) );
    history.push( "/reservas/horario" );
  };

  //console.log(user);
  //console.log(userInfo);
  useEffect( () => {
    dispatch( deleteScheduleToReserva );
    dispatch( placeReservaReset );
    dispatch( { type: RESERVA_ADD_CODIGO_RESET } );
    if ( userInfo && user.email === undefined ) {
      dispatch( addUserToReserva( userInfo.email ) );
    }
    if ( !user.email ) {
      history.push( "/reservas/invitado" );
    }

    dispatch( listCourts() );
    const script = document.createElement( "script" );
    script.type = "text/javascript";
    script.src = "/static/js/vendor/jquery-library.js";
    script.async = true;
    script.onload = () => {
      const script13 = document.createElement( "script" );
      script13.type = "text/javascript";
      script13.src = "/static/js/jquery-ui.js";
      script13.async = true;

      const script2 = document.createElement( "script" );
      script2.type = "text/javascript";
      script2.src = "/static/js/vendor/modernizr-2.8.3-respond-1.4.2.min.js";
      script2.async = true;
      const script3 = document.createElement( "script" );
      script3.type = "text/javascript";
      script3.src = "/static/js/vendor/bootstrap.min.js";
      script3.async = true;
      const script4 = document.createElement( "script" );
      script4.type = "text/javascript";
      script4.src = "/static/js/owl.carousel.js";
      script4.async = true;
      const script5 = document.createElement( "script" );
      script5.type = "text/javascript";
      script5.src = "/static/js/isotope.pkgd.js";
      script5.async = true;
      const script6 = document.createElement( "script" );
      script6.type = "text/javascript";
      script6.src = "/static/js/prettyPhoto.js";
      script6.async = true;
      const script7 = document.createElement( "script" );
      script7.type = "text/javascript";
      script7.src = "/static/js/customScrollbar.min.js";
      script7.async = true;
      const script9 = document.createElement( "script" );
      script9.type = "text/javascript";
      script9.src = "/static/js/swiper.min.js";
      script9.async = true;
      const script10 = document.createElement( "script" );
      script10.type = "text/javascript";
      script10.src = "/static/js/countTo.js";
      script10.async = true;
      const script11 = document.createElement( "script" );
      script11.type = "text/javascript";
      script11.src = "/static/js/appear.js";
      script11.async = true;
      const script8 = document.createElement( "script" );
      script8.type = "text/javascript";
      script8.src = "/static/js/main.js";
      script8.async = true;
      document.body.appendChild( script13 );
      document.body.appendChild( script2 );
      document.body.appendChild( script3 );
      document.body.appendChild( script4 );
      document.body.appendChild( script5 );
      document.body.appendChild( script6 );
      document.body.appendChild( script7 );
      document.body.appendChild( script9 );
      document.body.appendChild( script10 );
      document.body.appendChild( script11 );
      document.body.appendChild( script8 );
    };

    document.body.appendChild( script );
  }, [ dispatch, userInfo, history, user ] );
  return (
    <Layout>
      <Seo
        titlePage="Canchas"
        descriptionPage="Pádel Cuenca. Somos un club conformado por personas amantes del deporte, enfocados en fomentar la vida activa y saludable, priorizando el ejercicio como un hábito que beneficie nuestro estado físico y mental, Disponemos de canchas de Pádel en Cuenca Ecuador para que pueda realizar una reserva."
      />
      <div id="tg-wrapper" className="tg-wrapper tg-haslayout">
        <MobileMenu />
        <Header />
        <Banner title="Reservas" />
        <main id="tg-main" className="tg-main tg-haslayout">
          <section className="tg-main-section tg-paddingbottom-zero tg-haslayout">
            <div className="container">
              <div className="tg-section-name">
                <h2>reserva</h2>
              </div>

              <div className="col-sm-11 col-xs-11 pull-right">
                <MaterialStepperReserva step={ 1 } />
                {/*  <ReservaSteps step={1} /> */ }
                <div className="content-wrapper">
                  { loading ? (
                    <Loader />
                  ) : error ? (
                    <Message
                      message="Por favor inténtelo más tarde"
                      severity="warning"
                    />
                  ) : (
                    courtsList.map( ( cou, index ) => (
                      <div className="news-card" key={ index }>
                        { cou.estado_cancha.id === 1 ? (
                          <div
                            role="button"
                            onClick={ () => {
                              addCourtHandler(
                                cou.id,
                                cou.nombre,
                                cou.direccion,
                                cou.precio,
                                cou.estado_cancha.id_estado_cancha
                              );
                            } }
                            className="news-card__card-link"
                          ><span style={ { display: "none" } }>Ver más detalle</span></div>
                        ) : null }

                        <img
                          src={ cou.id === 1 ? cancha : cancha2 }
                          alt=""
                          className="news-card__image"
                        />
                        <div className="news-card__text-wrapper">
                          <h2 className="news-card__title">{ cou.nombre }</h2>
                          { cou.estado_cancha.id === 1 ? (
                            <div
                              className="news-card__post-date"
                              style={ { color: "green", fontWeight: "900" } }
                            >
                              Disponible
                            </div>
                          ) : (
                            <div
                              className="news-card__post-date"
                              style={ { color: "red", fontWeight: "900" } }
                            >
                              No Disponible
                            </div>
                          ) }
                          <div className="news-card__post-date"></div>
                          <div className="news-card__details-wrapper">
                            <p className="news-card__excerpt">
                              { cou.descripcion }, ubicada en { cou.direccion }.
                              Horario de { cou.hora_apertura } a { cou.hora_cierre }
                            </p>
                            <p
                              style={ {
                                fontWeight: "900",
                                fontSize: "16px",
                              } }
                            >
                              Precio: Varía según el horario
                            </p>
                            { cou.estado_cancha.id === 1 ? (
                              <button
                                href="#"
                                className="news-card__read-more"
                                onClick={ () => {
                                  addCourtHandler(
                                    cou.id,
                                    cou.nombre,
                                    cou.direccion,
                                    cou.precio,
                                    cou.estado_cancha.id_estado_cancha
                                  );
                                } }
                              >
                                Reservar
                                <i className="fas fa-long-arrow-alt-right"></i>
                              </button>
                            ) : null }
                          </div>
                        </div>
                      </div>
                    ) )
                  ) }
                </div>
              </div>
            </div>
          </section>
        </main>
        <Footer />
      </div>
    </Layout>
  );
};

export default CanchasScreen;
