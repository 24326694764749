import api from "../../api/api";
import {
  SCHEDULE_LIST_FAIL,
  SCHEDULE_LIST_REQUEST,
  SCHEDULE_LIST_SUCESS,
  SCHEDULE_REAGENDAMIENTO_LIST_FAIL,
  SCHEDULE_REAGENDAMIENTO_LIST_REQUEST,
  SCHEDULE_REAGENDAMIENTO_LIST_SUCESS,
} from "../constants/scheduleConstants";

export const listScheduleActions = (fecha, cancha) => async (dispatch) => {
  try {
    dispatch({ type: SCHEDULE_LIST_REQUEST });

    const { data } = await api.post(`/horario/disponible`, { fecha, cancha });

    dispatch({
      type: SCHEDULE_LIST_SUCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SCHEDULE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listScheduleReagendamiento =
  (fecha, hora, fecha_actual, cancha) => async (dispatch) => {
    try {
      dispatch({ type: SCHEDULE_REAGENDAMIENTO_LIST_REQUEST });

      const { data } = await api.post(`/horario/reagendamiento`, {
        fecha,
        hora,
        fecha_actual,
        cancha,
      });

      dispatch({
        type: SCHEDULE_REAGENDAMIENTO_LIST_SUCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SCHEDULE_REAGENDAMIENTO_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
