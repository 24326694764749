import React, { useEffect } from "react";
import Layout from "../components/Layout/Layout";
import MobileMenu from "../components/Header/MobileMenu";
import Header from "../components/Header/Header";
import HomeSlider from "../components/Header/HomeSlider";
import AboutUs from "../components/HomePage/aboutus";
import UpcomingMatch from "../components/HomePage/UpcomingMatch";
import Statistics from "../components/HomePage/Statistics";
import Video from "../components/HomePage/Video";
import Sponsors from "../components/AboutPage/Sponsors";
import BlogHome from "../components/HomePage/BlogHome";
import Footer from "../components/Layout/Footer";
import ComoJugarPadel from "../components/HomePage/ComoJugarPadel";
import BeneficiosVip from "../components/HomePage/BeneficiosVip";
import BeneficiosTable from "../components/HomePage/BeneficiosTable";
import Seo from "../components/seo";
import { RECARGA_PLACE_RESET } from "../redux/constants/recargasConstants";
import { useDispatch } from "react-redux";

const HomeScreen = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: RECARGA_PLACE_RESET });
    window.scrollTo(0, 0);
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "/static/js/vendor/jquery-library.js";
    script.async = true;
    script.onload = () => {
      const script13 = document.createElement("script");
      script13.type = "text/javascript";
      script13.src = "/static/js/jquery-ui.js";
      script13.async = true;

      const script2 = document.createElement("script");
      script2.type = "text/javascript";
      script2.src = "/static/js/vendor/modernizr-2.8.3-respond-1.4.2.min.js";
      script2.async = true;
      const script3 = document.createElement("script");
      script3.type = "text/javascript";
      script3.src = "/static/js/vendor/bootstrap.min.js";
      script3.async = true;
      const script4 = document.createElement("script");
      script4.type = "text/javascript";
      script4.src = "/static/js/owl.carousel.js";
      script4.async = true;
      const script5 = document.createElement("script");
      script5.type = "text/javascript";
      script5.src = "/static/js/isotope.pkgd.js";
      script5.async = true;
      const script6 = document.createElement("script");
      script6.type = "text/javascript";
      script6.src = "/static/js/prettyPhoto.js";
      script6.async = true;
      const script7 = document.createElement("script");
      script7.type = "text/javascript";
      script7.src = "/static/js/customScrollbar.min.js";
      script7.async = true;
      const script9 = document.createElement("script");
      script9.type = "text/javascript";
      script9.src = "/static/js/swiper.min.js";
      script9.async = true;
      const script10 = document.createElement("script");
      script10.type = "text/javascript";
      script10.src = "/static/js/countTo.js";
      script10.async = true;
      const script11 = document.createElement("script");
      script11.type = "text/javascript";
      script11.src = "/static/js/appear.js";
      script11.async = true;
      const script8 = document.createElement("script");
      script8.type = "text/javascript";
      script8.src = "/static/js/main.js";
      script8.async = true;
      document.body.appendChild(script13);
      document.body.appendChild(script2);
      document.body.appendChild(script3);
      document.body.appendChild(script4);
      document.body.appendChild(script5);
      document.body.appendChild(script6);
      document.body.appendChild(script7);
      document.body.appendChild(script9);
      document.body.appendChild(script10);
      document.body.appendChild(script11);
      document.body.appendChild(script8);
    };

    document.body.appendChild(script);
  }, [dispatch]);
  return (
    <Layout>
      <Seo
        titlePage="Inicio"
        descriptionPage="Pádel Cuenca. Somos un club conformado por personas amantes del deporte, enfocados en fomentar la vida activa y saludable, priorizando el ejercicio como un hábito que beneficie nuestro estado físico y mental, Disponemos de canchas de Pádel en Cuenca Ecuador para que pueda realizar una reserva."
      />
      <div id="tg-wrapper" className="tg-wrapper tg-haslayout">
        <MobileMenu />
        <Header />
        <HomeSlider />
        <main id="tg-main" className="tg-main tg-haslayout">
          <AboutUs isPage={true} />
          <BeneficiosVip />
          <BeneficiosTable />
          <UpcomingMatch />
          <Sponsors />
          <Statistics />
          {/* <Fixures /> */}
          <ComoJugarPadel />
          <Video />

          {/*   */}
          <BlogHome />
        </main>
        <Footer />
      </div>
    </Layout>
  );
};

export default HomeScreen;
