import React from "react";
import PadelHistoryImage from "../../images/padelHistoryImagen.jpg";
import { Link } from "react-router-dom";

const PadelHistory = () => {
  return (
    <section className="tg-main-section tg-haslayout">
      <div className="container">
        <div className="tg-section-name">
          <h2>Historia del Pádel</h2>
        </div>
        <div className="col-sm-11 col-xs-11 pull-right">
          <div className="row">
            <div className="tg-latestresult tg-oursponsers">
              <div className="row">
                <div className="col-md-5 col-sm-12 col-xs-12">
                  <div className="tg-contentbox">
                    <div className="tg-section-heading">
                      <h2>¿QUÉ ES EL PÁDEL?</h2>
                    </div>
                    <div className="tg-description">
                      <p>
                        El pádel es un deporte de raqueta que se juega
                        normalmente por parejas, el juego consiste en pasar la
                        pelota al otro campo permitiendo solo un bote en nuestro
                        campo, si conseguimos que nuestro oponente no la
                        devuelva ganamos el punto.
                      </p>
                      <p>
                        Su origen es mexicano y data de 1962, desde entonces ha
                        ido evolucionando hasta que llegó a España a principios
                        de los 90 y acabo popularizándose hasta conocerse como
                        hoy en día, siendo el World Padel Tour el circuito
                        profesional de pádel.
                      </p>
                    </div>
                    <div className="tg-btnbox" style={{ marginTop: "20px" }}>
                      <Link className="tg-btn" to="/reservas/invitado">
                        <span>reservar</span>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-7 col-sm-12 col-xs-12">
                  <img src={PadelHistoryImage} alt="Historia del Pádel" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PadelHistory;
