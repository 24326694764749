import {
  CLOSE_NAVIGATION,
  OPEN_NAVIGATION,
} from "../constants/navigationConstants";

export const bavigationReducer = (state = { isOpen: false }, action) => {
  switch (action.type) {
    case CLOSE_NAVIGATION:
      return { isOpen: false };
    case OPEN_NAVIGATION:
      return { isOpen: true };

    default:
      return state;
  }
};
