import api from "../../api/api";
import {
  COURT_LIST_FAIL,
  COURT_LIST_REQUEST,
  COURT_LIST_SUCESS,
} from "../constants/courtConstants";

export const listCourts = () => async (dispatch) => {
  try {
    dispatch({ type: COURT_LIST_REQUEST });

    const { data } = await api.get(`/cancha`);

    dispatch({
      type: COURT_LIST_SUCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: COURT_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
