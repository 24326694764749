import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import MobileMenu from "../components/Header/MobileMenu";
import Header from "../components/Header/Header";
import Footer from "../components/Layout/Footer";
import Banner from "../components/Banner";
import Loader from "../components/Loader";
import TicketResumen from "../components/TicketResumen";
import { getRecargaResult } from "../redux/actions/recargasActions";
import { RECARGA_PLACE_RESET } from "../redux/constants/recargasConstants";
import RecargaCard from "../components/Recargaspage/RecargasCard";
import { Alert } from "@mui/material";
import { getSaldo } from "../redux/actions/saldoActions";
import Seo from "../components/seo";
const ResultadoRecargaPage = ({ match, history }) => {
  const [seconds, setSeconds] = useState(5);
  const transaccionId = match.params.id;
  const dispatch = useDispatch();
  const recargaResultData = useSelector((state) => state.recargaResult);
  const userLogin = useSelector((state) => state.userLogin);
  const saldoUsuario = useSelector((state) => state.saldo);
  const { loading: loadingSaldo, saldo, error: errorSaldo } = saldoUsuario;
  const { userInfo } = userLogin;
  const { loading, recargaResult, error } = recargaResultData;

  if (recargaResult !== undefined) {
    if (recargaResult.statusCode === undefined) {
      if (seconds > 0) {
        setTimeout(() => setSeconds(seconds - 1), 5000);
      }
    }
  }
  useEffect(() => {
    if (!userInfo) {
      history.push("/login");
    }
    dispatch({ type: RECARGA_PLACE_RESET });
    dispatch(getRecargaResult(transaccionId));
    if (!loadingSaldo) {
      dispatch(getSaldo(userInfo.email));
    }

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "/static/js/vendor/jquery-library.js";
    script.async = true;
    script.onload = () => {
      const script13 = document.createElement("script");
      script13.type = "text/javascript";
      script13.src = "/static/js/jquery-ui.js";
      script13.async = true;

      const script2 = document.createElement("script");
      script2.type = "text/javascript";
      script2.src = "/static/js/vendor/modernizr-2.8.3-respond-1.4.2.min.js";
      script2.async = true;
      const script3 = document.createElement("script");
      script3.type = "text/javascript";
      script3.src = "/static/js/vendor/bootstrap.min.js";
      script3.async = true;
      const script4 = document.createElement("script");
      script4.type = "text/javascript";
      script4.src = "/static/js/owl.carousel.js";
      script4.async = true;
      const script5 = document.createElement("script");
      script5.type = "text/javascript";
      script5.src = "/static/js/isotope.pkgd.js";
      script5.async = true;
      const script6 = document.createElement("script");
      script6.type = "text/javascript";
      script6.src = "/static/js/prettyPhoto.js";
      script6.async = true;
      const script7 = document.createElement("script");
      script7.type = "text/javascript";
      script7.src = "/static/js/customScrollbar.min.js";
      script7.async = true;
      const script9 = document.createElement("script");
      script9.type = "text/javascript";
      script9.src = "/static/js/swiper.min.js";
      script9.async = true;
      const script10 = document.createElement("script");
      script10.type = "text/javascript";
      script10.src = "/static/js/countTo.js";
      script10.async = true;
      const script11 = document.createElement("script");
      script11.type = "text/javascript";
      script11.src = "/static/js/appear.js";
      script11.async = true;
      const script8 = document.createElement("script");
      script8.type = "text/javascript";
      script8.src = "/static/js/main.js";
      script8.async = true;
      document.body.appendChild(script13);
      document.body.appendChild(script2);
      document.body.appendChild(script3);
      document.body.appendChild(script4);
      document.body.appendChild(script5);
      document.body.appendChild(script6);
      document.body.appendChild(script7);
      document.body.appendChild(script9);
      document.body.appendChild(script10);
      document.body.appendChild(script11);
      document.body.appendChild(script8);
    };

    document.body.appendChild(script);
  }, [dispatch, transaccionId, seconds, history, userInfo, loadingSaldo]);

  return (
    <Layout>
      <Seo
        titlePage="Resultado Recarga"
        descriptionPage="Pádel Cuenca. Somos un club conformado por personas amantes del deporte, enfocados en fomentar la vida activa y saludable, priorizando el ejercicio como un hábito que beneficie nuestro estado físico y mental, Disponemos de canchas de Pádel en Cuenca Ecuador para que pueda realizar una reserva."
      />
      <div id="tg-wrapper" className="tg-wrapper tg-haslayout">
        <MobileMenu />
        <Header />
        <Banner title="Resultado" />
        <section className="tg-main-section tg-paddingbottom-zero tg-haslayout">
          <div className="container">
            <div className="tg-section-name">
              <h2>resultado</h2>
            </div>

            {/*  <MaterialRecargasSteps step={1} /> */}

            <div>
              <div className="content-wrapper">
                <h3>Resultado recarga</h3>
                {/*  <TicketResumen /> */}
              </div>
              {loading ? (
                <Loader />
              ) : error ? (
                <Alert
                  severity="error"
                  message="Algo salió mal. Por favor intentelo más tarde."
                />
              ) : (
                recargaResult && (
                  <>
                    <div class="row" style={{ marginBottom: "30px" }}>
                      {recargaResult.codigo ? (
                        <div class="tg-tickets">
                          <div className="col-md-6 col-sm-12 col-xs-12">
                            <div
                              style={{ textAlign: "center", marginTop: "15px" }}
                            >
                              <h6>Saldo Actual</h6>
                              {loadingSaldo ? (
                                <Loader />
                              ) : errorSaldo ? (
                                <Alert
                                  severity="error"
                                  mesgae="Algo Salió Mal"
                                />
                              ) : saldo.saldo ? (
                                <RecargaCard title={`Saldo $${saldo.saldo}`} />
                              ) : (
                                <RecargaCard title={`Saldo $${0}`} />
                              )}
                            </div>
                          </div>
                          <div
                            className="col-md-4 col-sm-12 col-xs-12"
                            style={{ marginTop: "15px" }}
                          >
                            <h6>Recarga</h6>
                            <div style={{ textAlign: "center" }}>
                              <TicketResumen
                                codigo={recargaResult.codigo}
                                email={recargaResult.email}
                                estado={recargaResult.statusCode}
                                cantidad={recargaResult.amount}
                                isRecarga={true}
                              />
                            </div>
                          </div>
                          <div className="col-md-12 col-sm-12 col-xs-12">
                            <div
                              className="buttonContainer"
                              style={{ textAlign: "center" }}
                            >
                              <Link
                                class="tg-btn"
                                to="/reservas/invitado"
                                style={{ marginTop: "15px", marginLeft: "5px" }}
                              >
                                realizar una reserva
                              </Link>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div style={{ textAlign: "center" }}>
                          <Loader />
                          <p style={{ fontSize: "20px" }}>
                            Por favor espere mientras validamos su pago.
                          </p>
                        </div>
                      )}
                    </div>
                  </>
                )
              )}
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </Layout>
  );
};

export default ResultadoRecargaPage;
