import React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import MobileStepper from "@mui/material/MobileStepper";
import useDimentions from "../WindowDimentions";
import "../../styles/materialStepper.css";
const stepsDatos = [
  {
    label: "Iniciar Sesión",
    description: `Inicie sesión o continúe como invitado`,
  },
  {
    label: "Cancha",
    description: "Seleccione una Cancha.",
  },
  {
    label: "Horario",
    description: `Seleccione el día y horario a reservar`,
  },
  {
    label: "Pago",
    description: `Pago de la reserva.`,
  },
];

const MaterialStepper = ({ step }) => {
  const { width } = useDimentions();
  return (
    <div>
      {width > 630 ? (
        <Box sx={{ width: "100%" }} style={{ marginBottom: "70px" }}>
          <Stepper activeStep={step}>
            <Step>
              <StepLabel>Iniciar Sesión</StepLabel>
            </Step>
            <Step>
              <StepLabel>Cancha</StepLabel>
            </Step>
            <Step>
              <StepLabel>Horario</StepLabel>
            </Step>
            <Step>
              <StepLabel>Pago</StepLabel>
            </Step>
          </Stepper>
        </Box>
      ) : (
        <div style={{ textAlign: "center" }}>
          {" "}
          <MobileStepper
            variant="text"
            steps={4}
            position="static"
            activeStep={step}
          />
          <Box sx={{ maxWidth: 400, width: "100%", p: 2 }}>
            <p style={{ fontWeight: "600" }}>{stepsDatos[step].label}</p>{" "}
            <p>{stepsDatos[step].description}</p>
          </Box>
        </div>
      )}
    </div>
  );
};

export default MaterialStepper;
