import {
  REAGENDAMIENTO_ADD_COURT,
  REAGENDAMIENTO_ADD_FECHA,
  REAGENDAMIENTO_ADD_ID_HORARIO,
  REAGENDAMIENTO_ADD_RESERVA,
  REAGENDAMIENTO_DELETE_COURT,
  REAGENDAMIENTO_DELETE_FECHA,
  REAGENDAMIENTO_DELETE_ID_HORARIO,
  REAGENDAMIENTO_DELETE_RESERVA,
  REAGENDAMIENTO_ADD_HORA,
  REAGENDAMIENTO_DELETE_HORA,
  REAGENDAMIENTO_ADD_OLD_DATE,
  REAGENDAMIENTO_ADD_OLD_HOUR,
  REAGENDAMIENTO_DELETE_OLD_DATE,
  REAGENDAMIENTO_DELETE_OLD_HOUR,
  REAGENDAMIENTO_PLACE_FAIL,
  REAGENDAMIENTO_PLACE_REQUEST,
  REAGENDAMIENTO_PLACE_SUCCESS,
  REAGENDAMIENTO_PLACE_RESET,
} from "../constants/reagendamientoConstants";

export const reagendamientoReducer = (
  state = {
    cancha: {},
    reserva: {},
    horarioId: {},
    fecha: {},
    hora: {},
    old_hour: {},
    old_date: {},
  },
  action
) => {
  switch (action.type) {
    case REAGENDAMIENTO_ADD_COURT:
      return {
        loading: true,
        ...state,
        cancha: action.payload,
      };
    case REAGENDAMIENTO_DELETE_COURT:
      return {
        loading: true,
        ...state,
        cancha: {},
      };
    case REAGENDAMIENTO_ADD_RESERVA:
      return {
        loading: true,
        ...state,
        reserva: action.payload,
      };
    case REAGENDAMIENTO_DELETE_RESERVA:
      return {
        loading: true,
        ...state,
        reserva: {},
      };
    case REAGENDAMIENTO_ADD_ID_HORARIO:
      return {
        loading: true,
        ...state,
        horarioId: action.payload,
      };
    case REAGENDAMIENTO_DELETE_ID_HORARIO:
      return {
        loading: true,
        ...state,
        horarioId: [],
      };
    case REAGENDAMIENTO_ADD_FECHA:
      return {
        loading: true,
        ...state,
        fecha: action.payload,
      };
    case REAGENDAMIENTO_DELETE_FECHA:
      return {
        loading: true,
        ...state,
        fecha: {},
      };
    case REAGENDAMIENTO_ADD_HORA:
      return {
        loading: true,
        ...state,
        hora: action.payload,
      };
    case REAGENDAMIENTO_DELETE_HORA:
      return {
        loading: true,
        ...state,
        hora: {},
      };
    case REAGENDAMIENTO_ADD_OLD_DATE:
      return {
        loading: true,
        ...state,
        old_date: action.payload,
      };
    case REAGENDAMIENTO_DELETE_OLD_DATE:
      return {
        loading: true,
        ...state,
        old_date: {},
      };
    case REAGENDAMIENTO_ADD_OLD_HOUR:
      return {
        loading: true,
        ...state,
        old_hour: action.payload,
      };
    case REAGENDAMIENTO_DELETE_OLD_HOUR:
      return {
        loading: true,
        ...state,
        old_hour: {},
      };

    default:
      return state;
  }
};

export const reagendamientoPlaceReducer = (state = {}, action) => {
  switch (action.type) {
    case REAGENDAMIENTO_PLACE_REQUEST:
      return {
        loading: true,
        reagendamientoResult: {},
      };
    case REAGENDAMIENTO_PLACE_SUCCESS:
      return {
        loading: false,
        reagendamientoResult: action.payload,
      };
    case REAGENDAMIENTO_PLACE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case REAGENDAMIENTO_PLACE_RESET:
      return {};
    default:
      return state;
  }
};
