import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
//import { useStaticQuery, graphql } from "gatsby"

function SEO({
  title,
  author,
  image,
  siteUrl,
  descriptionPage,
  lang,
  meta,
  keywords,
  titlePage,
}) {
  return (
    <Helmet
      htmlAttributes={{ lang: lang }}
      title={`${titlePage} | ${title} `}
      meta={[
        {
          name: `description`,
          content: descriptionPage,
        },
        {
          name: `image`,
          content: image,
        },
        {
          property: `og:url`,
          content: siteUrl,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: descriptionPage,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: `${siteUrl} ${image}`,
        },
        {
          property: `og:image:width`,
          content: `400`,
        },
        {
          property: `og:image:height`,
          content: `300`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: descriptionPage,
        },
        {
          name: `twitter:image`,
          content: `${siteUrl} ${image}`,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
    />
  );
}

SEO.defaultProps = {
  lang: `es`,
  meta: [],
  author: "",
  keywords: [
    "Pádel",
    "Pádel Cuenca",
    "Reserva",
    "Deporte",
    "Vida Sana",
    "Pista de pádel",
    "Canchas de Pádel",
    "Cuenca",
    "Ecuador",
  ],
  image: `/static/`,
  siteUrl: "https://www.padelcuenca.ec",
  title: `Pádel Cuenca`,
  descriptionPage: `Pádel Cuenca. Somos un club conformado por personas amantes del deporte, enfocados en fomentar la vida activa y saludable, priorizando el ejercicio como un hábito que beneficie nuestro estado físico y mental, Disponemos de canchas de Pádel en Cuenca Ecuador para que pueda realizar una reserva.`,
};

SEO.propTypes = {
  descriptionPage: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  titlePage: PropTypes.string.isRequired,
};

export default SEO;
