import React, { useState } from "react";
import ModalVideo from "react-modal-video";

function matchYoutubeUrl(url) {
  var p =
    /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  return url.match(p) ? RegExp.$1 : false;
}
const VideoHomeBlog = ({ videoUrl }) => {
  const [isOpen, setOpen] = useState(false);
  const videoId = matchYoutubeUrl(videoUrl);
  return (
    <>
      <button onClick={() => setOpen(true)}>
        <img
          src={`https://i.ytimg.com/vi/${videoId}/hqdefault.jpg`}
          alt="Noticias Padel"
        />
      </button>
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId={videoId}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default VideoHomeBlog;
