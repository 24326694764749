import React from "react";
import imagen3 from "../../images/player/paddelplayer4.png";
import { Link } from "react-router-dom";

const UpcomingMatch = () => {
  return (
    <section className=" tg-haslayout tg-bgstyleone">
      <div className="tg-bgboxone"></div>
      <div className="tg-bgboxtwo"></div>
      <div className="tg-bgpattrant">
        <div className="container">
          <div className="row">
            <div className="tg-upcomingmatch-counter">
              <div className="col-md-4 col-sm-4 col-xs-12 hidden-xs">
                <figure>
                  <img src={imagen3} alt="Pádel Cuenca" />
                </figure>
              </div>
              <div className="col-md-8 col-sm-8 col-xs-12">
                <div className="tg-contentbox">
                  <div className="tg-section-heading">
                    <h2>
                      ¿QUÉ ES EL <span>PÁDEL</span> ?
                    </h2>
                  </div>
                  <div className="tg-description">
                    <p>
                      El pádel es un deporte de raqueta que se juega normalmente
                      por parejas, el juego consiste en pasar la pelota al otro
                      campo permitiendo solo un bote en nuestro campo, si
                      conseguimos que nuestro oponente no la devuelva ganamos el
                      punto.
                    </p>
                    <p>
                      Su origen es mexicano y data de 1962, desde entonces ha
                      ido evolucionando hasta que llegó a España a principios de
                      los 90 y acabo popularizándose hasta conocerse como hoy en
                      día, siendo el World Padel Tour el circuito profesional de
                      pádel.
                    </p>
                  </div>
                  {/* <div className="tg-counters">
                    <div className="tg-counter tg-days"></div>
                    <div className="tg-counter tg-hours"></div>
                    <div className="tg-counter tg-minutes"></div>
                    <div className="tg-counter tg-seconds"></div>
                  </div> */}
                  <div className="tg-btnbox">
                    <Link className="tg-btn" to="/about-us">
                      <span>leer más</span>
                    </Link>
                    <Link className="tg-btn" to="/reservas/cancha">
                      <span>reservar</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UpcomingMatch;
