import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../components/Layout/Layout";
import MobileMenu from "../components/Header/MobileMenu";
import Header from "../components/Header/Header";
import Footer from "../components/Layout/Footer";
import Banner from "../components/Banner";
import MaterialRecargasSteps from "../components/Recargaspage/MaterialRecargasStep";

import RecargasCard from "../components/Recargaspage/RecargasCard";
import useDimentions from "../components/WindowDimentions";
import Seo from "../components/seo";
const RecargarPage = ({ history }) => {
  const apiUrl =
    process.env.NODE_ENV === "production"
      ? "https://www.padelcuenca.ec"
      : "http://localhost:3000";
  const { width } = useDimentions();
  const [sdkReady2, setSdkReady2] = useState(false);
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const saldoData = useSelector((state) => state.saldo);
  const codigoData = useSelector((state) => state.tempCodeRecarga);
  const { recargaPlace, error } = codigoData;
  const total = 120 * 100;
  if (!recargaPlace && !error) {
    history.push("/recargar");
  }
  useEffect(() => {
    if (!userInfo) {
      history.push("/login");
    }
    if ((recargaPlace === undefined) & (error === undefined)) {
      history.push("/recargar");
    } else {
      const id = recargaPlace ? recargaPlace.client_transaction_id : 999;
      const scriptToInject = `
  
    payphone.Button({
    
    //token obtenido desde la consola de developer
    token: "rCSOHNyxTVVEh7COI7jEz38XjdJ15PXjMWzwD7wZbFbS4tFaJHHfbIA_q-bsbAbdPUW1T647QWkdsxHGuAWJcZUc2rr2xbgkP0MXAt4L1OAeZllkv1SDlzr9NSv8rzRS0oGG7ojSu9N_4-2O2It7EYrbI0yb_mLXCcTQ8Ya3OpFDl8oSv197oAnbQ_PefCt5uamXtWOThafFvh0Ouw3_Ij_iSbZlTEOy4n0R4moJVcl2afqZnHmidoJmA-9lXJB6-k-XdHxYh2M__hxSOAAcfib4lqtnz_MKRzQdkS9I1TloHvtT9g4khFb9KuH1x4lLcdrNeQ",
    
    //PARÁMETROS DE CONFIGURACIÓN
    btnHorizontal: false,
    btnCard: true,
    
    createOrder: function(actions){
    
    //Se ingresan los datos de la transaccion ej. monto, impuestos, etc
    return actions.prepare({
    
    amount:  ${total},
    amountWithoutTax: ${total},
    currency: "USD",
    clientTransactionId: "${id}"
    });
    
    },
    onComplete: function(model, actions){
    
    //Se confirma el pago realizado
    actions.confirm({
    id: model.id,
    clientTxId: model.clientTxId
    }).then(function(value){
    
    //EN ESTA SECCIÓN SE RECIBE LA RESPUESTA Y SE MUESTRA AL USUARIO                                
    
    if (value.transactionStatus == "Approved"){
    location.replace('${apiUrl}/recargar/resultado-recarga/'+value.transactionId)
    }else{
      location.replace('${apiUrl}/recarga/resultado/error/'+value.transactionId)
    }
    }).catch(function(err){
    console.log(err);
    location.replace('${apiUrl}/recarga/resultado/error/${id}');
    });
    
    }
    }).render("#pp-button");
    `;
      const addPayPhoneScript = async () => {
        const script2 = document.createElement("script");
        script2.type = "text/javascript";
        script2.id = "scriptPayphone";
        script2.innerHTML = scriptToInject;
        script2.async = true;
        script2.onload = () => {
          setSdkReady2(true);
        };

        document.body.appendChild(script2);
      };

      var element = document.getElementById("scriptPayphone") ? true : false;

      if (element) {
        setSdkReady2(true);
      }

      if (sdkReady2 === false) {
        addPayPhoneScript();
      }
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = "/static/js/vendor/jquery-library.js";
      script.async = true;
      script.onload = () => {
        const script13 = document.createElement("script");
        script13.type = "text/javascript";
        script13.src = "/static/js/jquery-ui.js";
        script13.async = true;

        const script2 = document.createElement("script");
        script2.type = "text/javascript";
        script2.src = "/static/js/vendor/modernizr-2.8.3-respond-1.4.2.min.js";
        script2.async = true;
        const script3 = document.createElement("script");
        script3.type = "text/javascript";
        script3.src = "/static/js/vendor/bootstrap.min.js";
        script3.async = true;
        const script4 = document.createElement("script");
        script4.type = "text/javascript";
        script4.src = "/static/js/owl.carousel.js";
        script4.async = true;
        const script5 = document.createElement("script");
        script5.type = "text/javascript";
        script5.src = "/static/js/isotope.pkgd.js";
        script5.async = true;
        const script6 = document.createElement("script");
        script6.type = "text/javascript";
        script6.src = "/static/js/prettyPhoto.js";
        script6.async = true;
        const script7 = document.createElement("script");
        script7.type = "text/javascript";
        script7.src = "/static/js/customScrollbar.min.js";
        script7.async = true;
        const script9 = document.createElement("script");
        script9.type = "text/javascript";
        script9.src = "/static/js/swiper.min.js";
        script9.async = true;
        const script10 = document.createElement("script");
        script10.type = "text/javascript";
        script10.src = "/static/js/countTo.js";
        script10.async = true;
        const script11 = document.createElement("script");
        script11.type = "text/javascript";
        script11.src = "/static/js/appear.js";
        script11.async = true;
        const script8 = document.createElement("script");
        script8.type = "text/javascript";
        script8.src = "/static/js/main.js";
        script8.async = true;
        document.body.appendChild(script13);
        document.body.appendChild(script2);
        document.body.appendChild(script3);
        document.body.appendChild(script4);
        document.body.appendChild(script5);
        document.body.appendChild(script6);
        document.body.appendChild(script7);
        document.body.appendChild(script9);
        document.body.appendChild(script10);
        document.body.appendChild(script11);
        document.body.appendChild(script8);
      };

      document.body.appendChild(script);
    }
  }, [
    history,
    userInfo,
    recargaPlace,
    sdkReady2,
    total,
    dispatch,
    apiUrl,
    error,
  ]);
  return (
    <Layout>
      <Seo
        titlePage="Recargas"
        descriptionPage="Pádel Cuenca. Somos un club conformado por personas amantes del deporte, enfocados en fomentar la vida activa y saludable, priorizando el ejercicio como un hábito que beneficie nuestro estado físico y mental, Disponemos de canchas de Pádel en Cuenca Ecuador para que pueda realizar una reserva."
      />
      <div id="tg-wrapper" className="tg-wrapper tg-haslayout">
        <MobileMenu />
        <Header />
        <Banner title="Recargar" />
        <main id="tg-main" className="tg-main ">
          <section className="tg-main-section  tg-haslayout">
            <div className={width >= 600 ? "container" : ""}>
              <div className="tg-section-name">
                <h2>recargas</h2>
              </div>
              <div className="col-sm-11 col-xs-11 pull-right">
                <MaterialRecargasSteps step={2} />
              </div>

              <div className="row">
                <div
                  className="col-sm-11 col-md-4 col-xs-11 pull-right"
                  style={{ marginTop: "25px" }}
                >
                  <div id="pp-button"></div>
                </div>
                <div
                  className="col-sm-11 col-md-8 col-xs-11 pull-right"
                  style={{ marginTop: "25px" }}
                >
                  <div>
                    <RecargasCard title="Recarga $120" />
                  </div>

                  <h6
                    style={{
                      marginTop: "30px",
                      textAlign: "center",
                      fontSize: "20px",
                    }}
                  >
                    Saldo Actual:{" "}
                    <span
                      style={{
                        fontWeight: "700",
                        color: "#8dbe22",
                      }}
                    >
                      $ {saldoData.saldo ? saldoData.saldo.saldo : 0}
                    </span>
                  </h6>
                </div>
              </div>
            </div>
          </section>
        </main>
        <Footer />
      </div>
    </Layout>
  );
};

export default RecargarPage;
