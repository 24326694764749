import React from "react";
import { Slide } from "react-slideshow-image";
import cancha1 from "../../images/cancha/construccion/1.jpeg";
import cancha2 from "../../images/cancha/construccion/2.jpg";
import cancha3 from "../../images/cancha/construccion/3.jpg";
import cancha4 from "../../images/cancha/construccion/4.jpg";
import cancha5 from "../../images/cancha/construccion/5.jpg";
import cancha6 from "../../images/cancha/construccion/6.jpg";
import cancha7 from "../../images/cancha/construccion/7.jpg";
import cancha8 from "../../images/cancha/construccion/8.jpg";
import cancha9 from "../../images/cancha/construccion/9.jpg";
import cancha10 from "../../images/cancha/construccion/10.jpg";
import "react-slideshow-image/dist/styles.css";
import "../../styles/construccionCancha.css";

const ConstruccionCancha = () => {
  const fadeImages = [
    {
      cancha: cancha1,
      titulo: "construcción",
      descripcion: "cvc asa as sasaa assasaassa asasa fdsfdsdssd sssdsdds",
    },
    { cancha: cancha2, titulo: "dsdssd", descripcion: "cvvc" },
    { cancha: cancha3, titulo: "dsdssd", descripcion: "dfdf" },
    { cancha: cancha4, titulo: "dsdssd", descripcion: "dfd" },
    { cancha: cancha5, titulo: "dsdssd", descripcion: "dfd" },
    { cancha: cancha6, titulo: "dsdssd", descripcion: "vdvdv" },
    { cancha: cancha7, titulo: "dsdssd", descripcion: "vdddv" },
    { cancha: cancha8, titulo: "dsdssd", descripcion: "vdddv" },
    { cancha: cancha9, titulo: "dsdssd", descripcion: "vdddv" },
    { cancha: cancha10, titulo: "dsdssd", descripcion: "vdddv" },
  ];

  const fadeProperties = {
    indicators: true,
    duration: 3000,
    pauseOnHover: true,
  };
  return (
    <div>
      <div style={{ textAlign: "center", padding: "15px" }}>
        <h4>construción de nuestra cancha</h4>
      </div>
      <div className="slide-container">
        <Slide {...fadeProperties}>
          {fadeImages.map((image, index) => (
            <div className="each-fade">
              <div key={index}>
                <img
                  src={image.cancha}
                  alt="Construcción de la cancha Padel Cuenca"
                  style={{ objectFit: "cover", width: "100%" }}
                />
              </div>
            </div>
          ))}
        </Slide>
      </div>
    </div>
  );
};

export default ConstruccionCancha;
