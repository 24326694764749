import React from "react";
import pista1 from "../images/canchapadel1.png";
import ball from "../images/ball1.png";
import paleta from "../images/paleta.png";

const comoSeJuega = [
  {
    titulo: "Pista de pádel",
    img: <img src={pista1} alt="Noticias Padel" />,
    etiqueta: "Cancha",

    descripcion:
      " El lugar donde se juega a pádel, formado por dos rectángulos con unas medidas de la pista de pádel de 20 metros de largo por 10 de ancho.",
  },
  {
    titulo: "La pala de pádel",
    img: <img src={paleta} alt="Noticias Padel" />,
    etiqueta: "Herramientas",

    descripcion:
      " Es la raqueta de pádel con la que se juega, la herramienta para golpear la pelota y poder darle potencia y efectos.",
  },
  {
    titulo: "La pelota",
    img: <img src={ball} alt="Noticias Padel" />,
    etiqueta: "Herramientas",

    descripcion:
      "Según la federación de pádel el diámetro de una pelota de pádel debe ser de 6,32 a 6,77, medida que cumplen la mayoría de fabricantes. ",
  },
];

export default comoSeJuega;
