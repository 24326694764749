import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import "../../styles/beneficiosTable.css";
import normal from "../../images/check.png";
import vip from "../../images/checked.png";
const Beneficios = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  return (
    <section className="tg-main-section tg-haslayout ">
      <div className="container">
        <div className="tg-section-name">
          <h2>Beneficios Socios</h2>
        </div>
        <div className="col-sm-11 col-xs-11 pull-right">
          <h3 style={{ textAlign: "center" }}>recarga y accede a descuentos</h3>
          <div className="">
            <div className="background">
              <div className="containerTablePrice">
                <div className="panel pricing-table">
                  <div className="pricing-plan">
                    <img
                      src={normal}
                      alt="Usuarios Pádel Cuenca"
                      className="pricing-img"
                      style={{ maxWidth: "150px", maxHeight: "100px" }}
                    />
                    <h2 className="pricing-header" style={{ fontSize: "24px" }}>
                      Normal
                    </h2>
                    <ul className="pricing-features">
                      <li className="pricing-features-item">
                        $20 Mañanas lunes a viernes
                      </li>
                      <li className="pricing-features-item">
                        $28 Tardes y noches lunes a viernes
                      </li>
                      <li className="pricing-features-item">
                        $24 Sábados y domingos
                      </li>
                    </ul>
                    {/* <span class="pricing-price">Free</span> */}
                    <Link to="/registro" className="tg-btn">
                      Reserva
                    </Link>
                  </div>

                  <div className="pricing-plan">
                    <img
                      src={vip}
                      alt="Socios Padel Cuenca"
                      className="pricing-img"
                      style={{ maxHeight: "100px" }}
                    />
                    <h2 class="pricing-header" style={{ fontSize: "24px" }}>
                      Con Recarga
                    </h2>
                    <ul className="pricing-features">
                      <li className="pricing-features-item">
                        $16 Mañanas lunes a viernes
                      </li>
                      <li className="pricing-features-item">
                        $24 Tardes y noches lunes a viernes
                      </li>
                      <li className="pricing-features-item">
                        $20 Sábados y domingos
                      </li>
                    </ul>
                    {/*  <span class="pricing-price">$400</span> */}
                    {userInfo ? (
                      <Link to="/recargar" className="tg-btn">
                        Recargar
                      </Link>
                    ) : (
                      <Link to="/registro" className="tg-btn">
                        Registrarse
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Beneficios;
