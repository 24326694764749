import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import { FaWindowClose } from "react-icons/fa";
import MobileMenu from "../components/Header/MobileMenu";
import Header from "../components/Header/Header";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../components/Layout/Footer";
import Banner from "../components/Banner";
import { Link } from "react-router-dom";
//import SearchBar from "../components/ConsultasPage/Searchbar";
import Loader from "../components/Loader";
import Message from "../components/Alerts";
import SnackBar from "../components/Snackbar";
import ResumenListItem from "../components/ConsultasPage/ResumenListItem";
import {
  getReservaDetails,
  deleteReserva,
} from "../redux/actions/reservaActions";
import { RESERVA_DETAILS_RESET } from "../redux/constants/reservasConstants";
import { resetReagendamiento } from "../redux/actions/reagendamientoActions";
import Seo from "../components/seo";
import { RESERVA_DELETE_RESET } from "../redux/constants/reservasConstants";
import "../styles/searchBarComponent.css";

const ConsultaPage = ({ history }) => {
  const dispatch = useDispatch();
  const [codigo, setCodigo] = useState("");
  const [message, setMessage] = useState(null);
  const reservaDet = useSelector((state) => state.reservaDetails);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const { reservaDetails, loading, error } = reservaDet;
  const reservaDelete = useSelector((state) => state.reservaDelete);
  const { success: successDelete } = reservaDelete;
  //console.log(reservaDetails.fecha);
  //console.log(reservaDetails);

  const submitHandler = (e) => {
    e.preventDefault();

    if (codigo.length > 0) {
      dispatch(getReservaDetails(codigo));
    } else {
      setMessage("Ingrese un código");
    }
  };
  const deleteHandler = (id) => {
    dispatch(deleteReserva(id));
    /* if (window.confirm("Cancelar?")) {
      dispatch(deleteReserva(id));
    } */
  };
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "/static/js/vendor/jquery-library.js";
    script.async = true;
    script.onload = () => {
      const script13 = document.createElement("script");
      script13.type = "text/javascript";
      script13.src = "/static/js/jquery-ui.js";
      script13.async = true;

      const script2 = document.createElement("script");
      script2.type = "text/javascript";
      script2.src = "/static/js/vendor/modernizr-2.8.3-respond-1.4.2.min.js";
      script2.async = true;
      const script3 = document.createElement("script");
      script3.type = "text/javascript";
      script3.src = "/static/js/vendor/bootstrap.min.js";
      script3.async = true;
      const script4 = document.createElement("script");
      script4.type = "text/javascript";
      script4.src = "/static/js/owl.carousel.js";
      script4.async = true;
      const script5 = document.createElement("script");
      script5.type = "text/javascript";
      script5.src = "/static/js/isotope.pkgd.js";
      script5.async = true;
      const script6 = document.createElement("script");
      script6.type = "text/javascript";
      script6.src = "/static/js/prettyPhoto.js";
      script6.async = true;
      const script7 = document.createElement("script");
      script7.type = "text/javascript";
      script7.src = "/static/js/customScrollbar.min.js";
      script7.async = true;
      const script9 = document.createElement("script");
      script9.type = "text/javascript";
      script9.src = "/static/js/swiper.min.js";
      script9.async = true;
      const script10 = document.createElement("script");
      script10.type = "text/javascript";
      script10.src = "/static/js/countTo.js";
      script10.async = true;
      const script11 = document.createElement("script");
      script11.type = "text/javascript";
      script11.src = "/static/js/appear.js";
      script11.async = true;
      const script8 = document.createElement("script");
      script8.type = "text/javascript";
      script8.src = "/static/js/main.js";
      script8.async = true;
      document.body.appendChild(script13);
      document.body.appendChild(script2);
      document.body.appendChild(script3);
      document.body.appendChild(script4);
      document.body.appendChild(script5);
      document.body.appendChild(script6);
      document.body.appendChild(script7);
      document.body.appendChild(script9);
      document.body.appendChild(script10);
      document.body.appendChild(script11);
      document.body.appendChild(script8);
    };

    document.body.appendChild(script);
    if (userInfo) {
      history.push("/usuario/reservas");
    }

    window.scrollTo(0, 0);

    dispatch({ type: RESERVA_DETAILS_RESET });
    dispatch({ type: RESERVA_DELETE_RESET });

    dispatch(resetReagendamiento());
  }, [dispatch, userInfo, history]);
  return (
    <Layout>
      <Seo
        titlePage="Consulta"
        descriptionPage="Pádel Cuenca. Somos un club conformado por personas amantes del deporte, enfocados en fomentar la vida activa y saludable, priorizando el ejercicio como un hábito que beneficie nuestro estado físico y mental, Disponemos de canchas de Pádel en Cuenca Ecuador para que pueda realizar una reserva."
      />

      {message && <SnackBar message={message} severity="error" />}
      <div id="tg-wrapper" className="tg-wrapper tg-haslayout">
        <MobileMenu />
        <Header />
        <Banner title="Consultas Reservas" />
        <main id="tg-main" className="tg-main tg-haslayout">
          <section className="tg-main-section tg-paddingbottom-zero tg-haslayout">
            <div className="container">
              <div className="tg-section-name">
                <h2>consulta</h2>
              </div>
              {successDelete ? (
                <div
                  className="col-sm-11 col-xs-11 pull-right"
                  style={{ margin: "50px 0px", textAlign: "center" }}
                >
                  <SnackBar
                    severity="success"
                    message="Reserva cancelada con éxito"
                  />

                  <Link
                    className="tg-btn"
                    to="/reservas/invitado"
                    style={{ minWidth: "220px" }}
                  >
                    Reservar
                  </Link>
                </div>
              ) : (
                <>
                  {" "}
                  <div className="col-sm-11 col-xs-11 pull-right">
                    <div
                      className="row"
                      style={{ marginTop: "40px", textAlign: "center" }}
                    >
                      <h5>
                        ingrese su código de la reserva para realizar la
                        consulta
                      </h5>
                    </div>

                    <div>
                      <div class="container">
                        <div class="search_wrap search_wrap_1">
                          <div class="search_box">
                            <form onSubmit={submitHandler}>
                              <input
                                type="text"
                                class="input"
                                placeholder="Ingrese su código."
                                value={codigo}
                                onChange={(e) => setCodigo(e.target.value)}
                              />
                              <button class="btn btn_common" type="submit">
                                <i class="fa fa-search"></i>
                              </button>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-11 col-xs-11 pull-right">
                    {error && <Message severity="error" message={error} />}
                    {loading && (
                      <div style={{ textAlign: "center" }}>
                        {" "}
                        <Loader />
                      </div>
                    )}
                    {reservaDetails && (
                      <>
                        <h5 style={{ margin: "15px 0" }}>
                          Fecha en que se realizó la reserva:{" "}
                          <span style={{ color: "#8dbe22" }}>
                            {reservaDetails.fecha_reserva.slice(0, 10)}
                          </span>
                        </h5>
                        <h5 style={{ margin: "15px 0" }}>
                          Total Pagado:{" "}
                          <span style={{ color: "#8dbe22" }}>
                            $ {reservaDetails.total}
                          </span>
                        </h5>
                        <h5 style={{ margin: "15px 0" }}>
                          Estado:{" "}
                          {reservaDetails.estado === "PENDIENTE DE PAGO" ? (
                            <span
                              style={{
                                padding: "5px",
                                backgroundColor: "#eed202",
                                color: "white",
                                fontSize: "16px",
                              }}
                            >
                              {reservaDetails.estado}
                            </span>
                          ) : reservaDetails.estado === "CONFIRMADO" ? (
                            <span
                              style={{
                                padding: "5px",
                                backgroundColor: "green",
                                color: "white",
                                fontSize: "16px",
                              }}
                            >
                              {reservaDetails.estado}
                            </span>
                          ) : null}
                        </h5>
                        <h5 style={{ margin: "15px 0" }}>Horario</h5>
                      </>
                    )}

                    <div class="row">
                      <div class="tg-tickets">
                        {reservaDetails &&
                          reservaDetails.horario.map((det, index) => (
                            <ResumenListItem
                              index={index}
                              fecha={det.fecha}
                              hora={det.hora}
                              cancha={det.cancha}
                              precio={det.precio}
                              id={reservaDetails.reserva}
                              horarioId={det.id}
                              estado={reservaDetails.estado}
                              es_reagendable={det.reagendar}
                            />
                          ))}
                      </div>
                    </div>
                    {reservaDetails &&
                      reservaDetails.estado === "PENDIENTE DE PAGO" && (
                        <div className="buttonRight">
                          <button
                            className="tg-btn"
                            style={{
                              background: "#F9F9F9",
                              color: "red",
                              marginTop: "15px",
                              minWidth: "250px",
                            }}
                            onClick={() => deleteHandler(reservaDetails.codigo)}
                          >
                            <FaWindowClose /> Cancelar Reserva
                          </button>
                        </div>
                      )}
                  </div>
                </>
              )}
            </div>
          </section>
        </main>
        <Footer />
      </div>
    </Layout>
  );
};

export default ConsultaPage;
