import React, { useEffect, useState } from "react";
import api from "../../api/api";
import Loader from "../Loader";
import Message from "../Alerts";

const Statistics = () => {
  const [indicadores, setIndicadores] = useState(null);
  const [error, setError] = useState(false);
  useEffect(() => {
    const getIndicadores = async () => {
      try {
        const { data } = await api.get("/empresa/indicadores");
        setIndicadores(data);
      } catch (error) {
        setError(true);
      }
    };

    getIndicadores();
  }, []);
  return (
    <section className="tg-main-section tg-haslayout tg-bgdark">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-xs-12">
            {indicadores ? (
              <>
                <div className="tg-statistics">
                  <div className="tg-statistic tg-goals">
                    <span className="tg-icon icon-Icon1"></span>
                    <h2>
                      <span
                        className="tg-statistic-count"
                        data-from="0"
                        data-to={indicadores.num_usuarios}
                        data-speed="8000"
                        data-refresh-interval="50"
                      >
                        {indicadores.num_usuarios}
                      </span>
                    </h2>
                    <h3>Número de Jugadores</h3>
                  </div>
                  <div className="tg-statistic tg-activeplayers">
                    <span className="tg-icon icon-Icon2"></span>
                    <h2>
                      <span
                        className="tg-statistic-count"
                        data-from="0"
                        data-to={indicadores.num_horas}
                        data-speed="8000"
                        data-refresh-interval="50"
                      >
                        {indicadores.num_horas}
                      </span>
                    </h2>
                    <h3>Horas Jugadas</h3>
                  </div>
                  <div className="tg-statistic tg-activeteams">
                    <span className="tg-icon icon-Icon3"></span>
                    <h2>
                      <span
                        className="tg-statistic-count"
                        data-from="0"
                        data-to={indicadores.num_juegos}
                        data-speed="8000"
                        data-refresh-interval="50"
                      >
                        {indicadores.num_juegos}
                      </span>
                    </h2>
                    <h3>Reservas Realizadas</h3>
                  </div>
                  {/* <div className="tg-statistic tg-earnedawards">
                    <span className="tg-icon icon-Icon4"></span>
                    <h2>
                      <span
                        className="tg-statistic-count"
                        data-from="0"
                        data-to="197"
                        data-speed="8000"
                        data-refresh-interval="50"
                      >
                        197
                      </span>
                    </h2>
                    <h3>Earned Awards</h3>
                  </div> */}
                </div>
              </>
            ) : error ? null : (
              <div style={{ textAlign: "center" }}>
                {" "}
                <Loader />
              </div>
            )}
            {error && (
              <div style={{ textAlign: "center" }}>
                <Message
                  severity="warning"
                  message="Algo salió mal, intentelo de nuevo"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Statistics;
