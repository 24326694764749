import api from "../../api/api";
import {
  REAGENDAMIENTO_ADD_COURT,
  REAGENDAMIENTO_ADD_FECHA,
  REAGENDAMIENTO_ADD_ID_HORARIO,
  REAGENDAMIENTO_ADD_RESERVA,
  REAGENDAMIENTO_DELETE_COURT,
  REAGENDAMIENTO_DELETE_FECHA,
  REAGENDAMIENTO_DELETE_ID_HORARIO,
  REAGENDAMIENTO_DELETE_RESERVA,
  REAGENDAMIENTO_ADD_HORA,
  REAGENDAMIENTO_DELETE_HORA,
  REAGENDAMIENTO_ADD_OLD_DATE,
  REAGENDAMIENTO_ADD_OLD_HOUR,
  REAGENDAMIENTO_DELETE_OLD_DATE,
  REAGENDAMIENTO_DELETE_OLD_HOUR,
  REAGENDAMIENTO_PLACE_FAIL,
  REAGENDAMIENTO_PLACE_REQUEST,
  REAGENDAMIENTO_PLACE_SUCCESS,
  REAGENDAMIENTO_PLACE_RESET,
} from "../constants/reagendamientoConstants";

export const addReservaToReagendamiento = (id) => async (dispatch) => {
  dispatch({
    type: REAGENDAMIENTO_ADD_RESERVA,
    payload: id,
  });
};

export const addIdHorarioToReagendamiento = (id) => async (dispatch) => {
  dispatch({
    type: REAGENDAMIENTO_ADD_ID_HORARIO,
    payload: id,
  });
};

export const addCourtToReagendamiento = (id) => async (dispatch) => {
  dispatch({
    type: REAGENDAMIENTO_ADD_COURT,
    payload: { id },
  });
};

export const addFechaToReagendamiento = (fecha) => async (dispatch) => {
  dispatch({
    type: REAGENDAMIENTO_ADD_FECHA,
    payload: { fecha },
  });
};

export const addHoraToReagendamiento = (hora) => async (dispatch) => {
  dispatch({
    type: REAGENDAMIENTO_ADD_HORA,
    payload: { hora },
  });
};

export const addOldHourToReagendamiento = (oldHour) => async (dispatch) => {
  dispatch({
    type: REAGENDAMIENTO_ADD_OLD_HOUR,
    payload: { oldHour },
  });
};

export const addOldDateToReagendamiento = (oldDate) => async (dispatch) => {
  dispatch({
    type: REAGENDAMIENTO_ADD_OLD_DATE,
    payload: { oldDate },
  });
};

export const placeReagendamiento =
  (reagendamientoData, hora, fecha) => async (dispatch, getState) => {
    console.log(reagendamientoData);
    console.log(hora);
    console.log(fecha);
    /*   dispatch({
      type: RESERVA_ADD_SCHEDULE,
      payload: schedule,
    }); */
    try {
      dispatch({ type: REAGENDAMIENTO_PLACE_REQUEST });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      // console.log(bodyData);
      const reserva = { id: reagendamientoData.reserva };
      const horarioId = reagendamientoData.horarioId;
      const horario = [{ id: horarioId, fecha, hora }];
      const cancha = { id: reagendamientoData.cancha.id };
      const { data } = await api.post(
        "/reserva/reagendamiento",
        { reserva, horario, cancha },
        config
      );

      dispatch({
        type: REAGENDAMIENTO_PLACE_SUCCESS,
        payload: data,
      });
      /* localStorage.setItem(
      "reserva",
      
    ); */
    } catch (error) {
      console.log(error);
      dispatch({
        type: REAGENDAMIENTO_PLACE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const resetReagendamiento = () => (dispatch) => {
  //localStorage.removeItem("userInfo");
  dispatch({ type: REAGENDAMIENTO_DELETE_COURT });
  dispatch({ type: REAGENDAMIENTO_DELETE_FECHA });
  /*  dispatch({ type: ORDER_LIST_MY_RESET }); */
  dispatch({ type: REAGENDAMIENTO_DELETE_RESERVA });
  dispatch({ type: REAGENDAMIENTO_DELETE_ID_HORARIO });
  dispatch({ type: REAGENDAMIENTO_DELETE_HORA });
  dispatch({ type: REAGENDAMIENTO_DELETE_OLD_HOUR });
  dispatch({ type: REAGENDAMIENTO_DELETE_OLD_DATE });
  dispatch({ type: REAGENDAMIENTO_PLACE_RESET });
};
