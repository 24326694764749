import React from "react";
import { FaFacebookF, FaTiktok, FaInstagram } from "react-icons/fa";

const social = [
  {
    link: "https://www.facebook.com/padel_cuenca-101854785623902",
    icon: "fa fa-facebook",
    reactIcon: <FaFacebookF />,
    nombre: "Facebook",
  },

  {
    link: "https://www.instagram.com/padel_cuenca/",
    icon: "fa fa-instagram",
    reactIcon: <FaInstagram />,
    nombre: "Instagram",
  },
  {
    link: "https://www.tiktok.com/@padelcuenca",
    icon: "fa fa-tiktok",
    reactIcon: <FaTiktok />,
    nombre: "Tik Tok",
  },
];

export default social;
