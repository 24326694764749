import React from "react";
import { Link } from "react-router-dom";
import fondo from "../images/bg-pattran.png";

const Banner = ( { title } ) => {
  return (
    <div className="tg-banner tg-haslayout">
      <div className="tg-imglayer">
        <img src={ fondo } alt="Fondo Padel Cuenca" />
      </div>
      <div className="container">
        <div className="row">
          <div className="tg-banner-content tg-haslayout">
            <div className="tg-pagetitle">
              <h1>{ title }</h1>
            </div>
            <ol className="tg-breadcrumb">
              <li>
                <Link to="/">Inicio</Link>
              </li>
              <li className="active">{ title }</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
