import React from "react";
import "../styles/ticketComponent.css";

const TicketResumen = ({ codigo, email, estado, cantidad, isRecarga }) => {
  return (
    <div className="cardWrap">
      <div className="card cardLeft">
        <h1>
          Padel <span>Cuenca</span>
        </h1>
        <div className="title" style={{ textAlign: "left" }}>
          <h2>
            {isRecarga
              ? "RECARGA CANCHA PADEL CUENCA"
              : "RESERVA CANCHA PADEL CUENCA"}
          </h2>
          <span>{isRecarga ? "recarga" : "reserva"}</span>
        </div>
        <div className="name" style={{ textAlign: "left" }}>
          <h2 style={{ fontSize: "10px" }}>{email}</h2>
          <span>correo</span>
        </div>
        <div className="seat">
          {estado === 3 ? (
            <h2 style={{ color: "#8dbe22" }}>aprobado</h2>
          ) : (
            <h2 style={{ color: "#ffcc00" }}>pendiente</h2>
          )}
          <span>estado</span>
        </div>
        <div className="time">
          {cantidad ? (
            <h2 style={{ fontWeight: "900" }}>$ {cantidad} </h2>
          ) : (
            <h2 style={{ fontWeight: "900" }}>--</h2>
          )}
          <span>cant</span>
        </div>
      </div>
      <div className="card cardRight">
        <div className="eye"></div>
        <div className="number">
          <h3 style={{ textTransform: "none" }}>{codigo}</h3>
          <span>código</span>
        </div>
        <div className="barcode"></div>
      </div>
    </div>
  );
};

export default TicketResumen;
