import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaArrowLeft } from "react-icons/fa";
import { AiFillBank } from "react-icons/ai";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Layout from "../components/Layout/Layout";
import MobileMenu from "../components/Header/MobileMenu";
import Header from "../components/Header/Header";
import Footer from "../components/Layout/Footer";
import Banner from "../components/Banner";
import Loader from "../components/Loader";
import MaterialSteps from "../components/ReservasPage/MaterialStepper";
import Message from "../components/Alerts";
import { RESERVA_PLACE_RESET } from "../redux/constants/reservaPlace";
import {
  RESERVA_ADD_CODIGO_RESET,
  RESERVA_DELETE_SCHEDULE,
} from "../redux/constants/reservasConstants";
import { placeReserva as placeReservaAction } from "../redux/actions/reservaPlaceActions";
import Seo from "../components/seo";
import useDimentions from "../components/WindowDimentions";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#43424D",
    color: theme.palette.common.white,
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableCellTotal = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 18,
    fontWeight: 700,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const SeleccionarPagoScreen = ({ history }) => {
  const { width } = useDimentions();
  const [metodoPago, setMetodoPago] = useState("PAYPHONE");
  const dispatch = useDispatch();
  const reserva = useSelector((state) => state.reserva);
  const tempCode = useSelector((state) => state.tempCode);
  const { codigo, error } = tempCode;
  const placeReserva = useSelector((state) => state.placeReserva);
  const { loading: loadingPlace } = placeReserva;
  if (!codigo && !error) {
    history.push("/reservas/invitado");
  }

  const handleRegresar = (e) => {
    e.preventDefault();
    dispatch({ type: RESERVA_PLACE_RESET });
    dispatch({ type: RESERVA_ADD_CODIGO_RESET });
    dispatch({ type: RESERVA_DELETE_SCHEDULE });
    history.push("/reservas/horario");
  };

  const handleTransferencia = (e) => {
    e.preventDefault();
    if (codigo.codigo) {
      setMetodoPago("TRANSFERENCIA");
      dispatch(placeReservaAction(reserva, codigo.codigo, 2));
    } else {
      //setfinalizarSeleccion(!finalizarSeleccion);
    }
  };
 /*  const handlePayphone = (e) => {
    e.preventDefault();
    if (codigo.codigo) {
      dispatch(placeReservaAction(reserva, codigo.codigo, 1));
    } else {
      setfinalizarSeleccion(!finalizarSeleccion);
    }
  }; */

  useEffect(() => {
    if (loadingPlace === false) {
      if (metodoPago === "TRANSFERENCIA") {
        history.push("/reservas/pago/transferencia");
      } else {
        history.push("/reservas/pago/payphone");
      }
    }
    if (codigo === undefined && error === undefined) {
      history.push("/reservas/invitado");
    }
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "/static/js/vendor/jquery-library.js";
    script.async = true;
    script.onload = () => {
      const script13 = document.createElement("script");
      script13.type = "text/javascript";
      script13.src = "/static/js/jquery-ui.js";
      script13.async = true;

      const script2 = document.createElement("script");
      script2.type = "text/javascript";
      script2.src = "/static/js/vendor/modernizr-2.8.3-respond-1.4.2.min.js";
      script2.async = true;
      const script3 = document.createElement("script");
      script3.type = "text/javascript";
      script3.src = "/static/js/vendor/bootstrap.min.js";
      script3.async = true;
      const script4 = document.createElement("script");
      script4.type = "text/javascript";
      script4.src = "/static/js/owl.carousel.js";
      script4.async = true;
      const script5 = document.createElement("script");
      script5.type = "text/javascript";
      script5.src = "/static/js/isotope.pkgd.js";
      script5.async = true;
      const script6 = document.createElement("script");
      script6.type = "text/javascript";
      script6.src = "/static/js/prettyPhoto.js";
      script6.async = true;
      const script7 = document.createElement("script");
      script7.type = "text/javascript";
      script7.src = "/static/js/customScrollbar.min.js";
      script7.async = true;
      const script9 = document.createElement("script");
      script9.type = "text/javascript";
      script9.src = "/static/js/swiper.min.js";
      script9.async = true;
      const script10 = document.createElement("script");
      script10.type = "text/javascript";
      script10.src = "/static/js/countTo.js";
      script10.async = true;
      const script11 = document.createElement("script");
      script11.type = "text/javascript";
      script11.src = "/static/js/appear.js";
      script11.async = true;
      const script8 = document.createElement("script");
      script8.type = "text/javascript";
      script8.src = "/static/js/main.js";
      script8.async = true;
      document.body.appendChild(script13);
      document.body.appendChild(script2);
      document.body.appendChild(script3);
      document.body.appendChild(script4);
      document.body.appendChild(script5);
      document.body.appendChild(script6);
      document.body.appendChild(script7);
      document.body.appendChild(script9);
      document.body.appendChild(script10);
      document.body.appendChild(script11);
      document.body.appendChild(script8);
    };

    document.body.appendChild(script);
  }, [history, error, codigo, loadingPlace, metodoPago]);

  return (
    <Layout>
      <Seo
        titlePage="Método de Pago"
        descriptionPage="Pádel Cuenca. Somos un club conformado por personas amantes del deporte, enfocados en fomentar la vida activa y saludable, priorizando el ejercicio como un hábito que beneficie nuestro estado físico y mental, Disponemos de canchas de Pádel en Cuenca Ecuador para que pueda realizar una reserva."
      />

      <div id="tg-wrapper" className="tg-wrapper tg-haslayout">
        <MobileMenu />
        <Header />
        <Banner title="Confirmación" />
        <section className="tg-main-section tg-paddingbottom-zero tg-haslayout">
          <div className={width >= 600 ? "container" : ""}>
            <div className="tg-section-name">
              <h2 style={{color: "#d2006e"}}>pago</h2>
            </div>

            <div className="col-sm-11 col-xs-11 pull-right">
              {/*  <ReservaSteps step={3} /> */}
              <MaterialSteps step={3} />
            </div>
            <div className="col-sm-11 col-xs-11 ">
              <div className="">
                {!error && (
                  <div style={{ textAlign: "center", marginBottom: "35px" }}>
                    <h6
                      style={{
                        fontSize: "24px",
                        fontWeight: "700",
                        color: "#d2006e",
                      }}
                    >
                      Reserva pendiente de Pago
                    </h6>
                  </div>
                )}
              </div>
              <div class="row" style={{ marginBottom: "30px" }}>
                <div>
                  {error ? (
                    <>
                      <Message severity="error" message={error} />
                      <div className="buttonContainer">
                        <button
                          className="tg-btn"
                          style={{
                            background: "#41404B",
                            color: "white",
                            marginTop: "15px",
                          }}
                          onClick={handleRegresar}
                        >
                          <FaArrowLeft /> Regresar
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-md-8 col-sm-12 col-xs-12">
                        <h6 style={{ marginTop: "15px" }}>
                          Resumen de la reserva
                        </h6>
                        <TableContainer component={Paper}>
                          <Table
                            sx={{ minWidth: 400 }}
                            aria-label="customized table"
                          >
                            <TableHead>
                              <TableRow>
                                <StyledTableCell>Descripción</StyledTableCell>
                                <StyledTableCell align="right">
                                  Fecha
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  Hora
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  Precio
                                </StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {reserva.schedule.map((reser, index) => (
                                <StyledTableRow key={index}>
                                  <StyledTableCell component="th" scope="row">
                                    Reserva - {reserva.court.nombre_cancha}
                                  </StyledTableCell>
                                  <StyledTableCell align="right">
                                    {reser.fecha}
                                  </StyledTableCell>
                                  <StyledTableCell align="right">
                                    {reser.hora}
                                  </StyledTableCell>
                                  <StyledTableCell align="right">
                                    ${reser.precio}
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))}
                              <StyledTableRow>
                                <StyledTableCell rowSpan={1} />
                                <StyledTableCellTotal colSpan={2}>
                                  Total
                                </StyledTableCellTotal>
                                <StyledTableCellTotal align="right">
                                  <span>
                                    ${" "}
                                    {reserva &&
                                      parseFloat(
                                        reserva.schedule.reduce(
                                          (prevValue, currentValue) =>
                                            parseFloat(prevValue) +
                                            parseFloat(currentValue.precio),
                                          0
                                        )
                                      )}
                                  </span>
                                </StyledTableCellTotal>
                              </StyledTableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                        {error ? null : (
                          <>
                            <div className="buttonContainer">
                              <button
                                class="tg-btn"
                                onClick={handleRegresar}
                                style={{ marginTop: "15px" }}
                              >
                                <FaArrowLeft /> regresar
                              </button>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="col-md-4 col-sm-12 col-xs-12">
                        <h6 style={{ marginTop: "15px" }}>
                          Seleccione su Método de Pago
                        </h6>
                        {loadingPlace !== undefined ? (
                          <div
                            style={{ textAlign: "center", marginTop: "30px" }}
                          >
                            <Loader />
                            <h5 style={{ margin: "30px 0px" }}>
                              Por favor espere mientras validamos el método de
                              pago.
                            </h5>
                          </div>
                        ) : (
                          <div className="tg-description buttonContainer">
                            {error ? null : (
                              <>
                               {/*  <button
                                  className="tg-btn payButton"
                                  style={{
                                    marginTop: "15px",
                                  }}
                                  onClick={handlePayphone}
                                >
                                  <FaCcMastercard /> <FaCcVisa /> Payphone /
                                  Tarjeta
                                </button> */}
                                <button
                                  className="tg-btn payButton"
                                  style={{
                                    marginTop: "15px",
                                  }}
                                  onClick={handleTransferencia}
                                >
                                  <AiFillBank /> transferencia
                                </button>
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </Layout>
  );
};

export default SeleccionarPagoScreen;
