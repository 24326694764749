import {
  COURT_LIST_REQUEST,
  COURT_LIST_SUCESS,
  COURT_LIST_FAIL,
} from "../constants/courtConstants";

export const courtListReducer = (state = { courts: [] }, action) => {
  switch (action.type) {
    case COURT_LIST_REQUEST:
      return { loading: true, courts: [] };
    case COURT_LIST_SUCESS:
      return { loading: false, courts: action.payload };
    case COURT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
