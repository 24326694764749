import {
  SCHEDULE_LIST_SUCESS,
  SCHEDULE_LIST_REQUEST,
  SCHEDULE_LIST_FAIL,
  SCHEDULE_REAGENDAMIENTO_LIST_FAIL,
  SCHEDULE_REAGENDAMIENTO_LIST_REQUEST,
  SCHEDULE_REAGENDAMIENTO_LIST_SUCESS,
  SCHEDULE_REAGENDAMIENTO_LIST_RESET,
} from "../constants/scheduleConstants";

export const scheduleListReducer = (state = { schedule: [] }, action) => {
  switch (action.type) {
    case SCHEDULE_LIST_REQUEST:
      return { loading: true, schedule: [] };
    case SCHEDULE_LIST_SUCESS:
      return { loading: false, schedule: action.payload };
    case SCHEDULE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const reagendamientoScheduleListReducer = (
  state = { schedule: [] },
  action
) => {
  switch (action.type) {
    case SCHEDULE_REAGENDAMIENTO_LIST_REQUEST:
      return { loading: true, schedule: [] };
    case SCHEDULE_REAGENDAMIENTO_LIST_SUCESS:
      return { loading: false, schedule: action.payload };
    case SCHEDULE_REAGENDAMIENTO_LIST_FAIL:
      return { loading: false, error: action.payload };

    case SCHEDULE_REAGENDAMIENTO_LIST_RESET:
      return { schedule: [] };
    default:
      return state;
  }
};
