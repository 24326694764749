import React from "react";
import { Link } from "react-router-dom";
import socialLinks from "../../constants/social-links";
import logo from "../../images/padellogo2.svg";

const Footer = () => {
  return (
    <footer id="tg-footer" className="tg-footer tg-haslayout">
      <div className="tg-haslayout tg-footerinfobox">
        <div className="tg-bgboxone"></div>
        <div className="tg-bgboxtwo"></div>
        <div className="tg-footerinfo">
          <div className="container">
            <div className="row">
              <div className="col-sm-4">
                <div className="tg-footercol">
                  <div className="tg-posttitle">
                    <h3>pádel cuenca</h3>
                  </div>
                  <div className="tg-description">
                    <p>
                      Somos un club conformado por personas amantes del deporte,
                      enfocados en fomentar la vida activa y saludable,
                      priorizando el ejercicio como un hábito que beneficie
                      nuestro estado físico y mental, queremos que más gente se
                      ejercite por medio de diferentes deportes, en este caso el
                      pádel, un deporte divertido, que conlleva muchas
                      características como la agilidad, velocidad, fuerza,
                      coordinación, trabajo en equipo.
                    </p>
                  </div>
                  <form className="tg-form-newsletter">
                    <fieldset>
                      <Link className="tg-btn" to="/reservas/invitado">
                        RESERVAR
                      </Link>
                    </fieldset>
                  </form>
                  <div className="tg-posttitle">
                    <h3>popular</h3>
                  </div>
                  <div className="tg-tags">
                    <a
                      className="tg-tag"
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.worldpadeltour.com/"
                    >
                      world padel tour
                    </a>
                    <a
                      className="tg-tag"
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.worldpadeltour.com/jugadores"
                    >
                      ranking jugadores
                    </a>

                    <Link className="tg-tag" to="/reservas/invitado">
                      reservar
                    </Link>
                    <Link className="tg-tag" to="/consulta">
                      consulta reservas
                    </Link>
                    <a
                      className="tg-tag"
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.worldpadeltour.com/sobre-world-padel-tour/reglamento/"
                    >
                      reglas
                    </a>
                    <Link className="tg-tag" to="/registro">
                      registrarse
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="tg-footercol">
                  <div className="tg-posttitle">
                    <h3>Redes Sociales</h3>
                  </div>
                  <div className="tg-toprated">
                    <ul>
                      {socialLinks.map((social, index) => (
                        <li key={index}>
                          <figure style={{ marginTop: "20px" }}>
                            <span style={{ fontSize: "25px" }}>
                              {social.reactIcon}
                            </span>
                          </figure>

                          <div className="tg-productcontent">
                            <h4>
                              <a
                                href={social.link}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {social.nombre}{" "}
                              </a>
                            </h4>

                            <span className="tg-price">
                              Encuéntranos en {social.nombre}
                            </span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="tg-footercol">
                  <div className="tg-haslayout">
                    <strong className="tg-logo">
                      <Link to="/">
                        <img src={logo} alt="Padel Cuenca" />
                      </Link>
                    </strong>
                  </div>
                  <h6 style={{ color: "white" }}>Misión</h6>
                  <div className="tg-description">
                    <p>
                      Contar con un espacio de esparcimiento para todas las
                      edades, con el propósito de fomentar la cultura deportiva
                      dentro de un estilo de vida saludable
                    </p>
                  </div>
                  <h6 style={{ color: "white" }}>Visión</h6>
                  <div className="tg-description">
                    <p>
                      Fortalecer el crecimiento del pádel a nivel local y
                      nacional, entregando un servicio de calidad que brinde las
                      mejores condiciones y se aproveche de la mejor forma la
                      práctica de este deporte.
                    </p>
                  </div>
                  <ul className="tg-contactinfo">
                    <li>
                      <i className="fa fa-home"></i>
                      <address>
                        Calle Pueblo de los Quitus (Challuabamba) - Cuenca
                        Ecuador
                      </address>
                    </li>
                    <li>
                      <i className="fa fa-envelope-o"></i>
                      <i>
                        {" "}
                        <a
                          href="mailto:padelcuenca2021@gmail.com"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="detallesLink2"
                         
                        >
                          padelcuenca2021@gmail.com
                        </a>
                      </i>
                    </li>
                    <li>
                      <i className="fa fa-phone"></i>
                      <span>
                        <a
                          href="tel:+593 98 433 7828"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="detallesLink2"
                          style={{ color: "white" }}
                        >
                          +593 98 433 7828
                        </a>
                      </span>
                    </li>
                  </ul>
                  <div className="tg-haslayout">
                    <Link className="tg-btn" to="/about-us">
                      Leer más
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="tg-footerbar">
        <div className="container">
          <span className="tg-copyright">
            <a
              target="_blank"
              rel="noreferrer"
              className="copyRightlink"
              href="https://eiteck.com/home"
            >
              Copyright © 2021 eiteck
            </a>
          </span>

          <nav className="tg-footernav">
            <ul>
              <li>
                <Link to="/">Inicio</Link>
              </li>
              <li>
                <Link to="/reservas/invitado">Reservas</Link>
              </li>
              <li>
                <Link to="/contacto">Contacto</Link>
              </li>
              <li>
                <Link to="/about-us">Acerca</Link>
              </li>
              <li>
                <Link to="/consulta">Consulta Reserva</Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
