import api from "../../api/api";
import {
  GET_SALDO_SUCCESS,
  GET_SALDO_REQUEST,
  GET_SALDO_FAIL,
} from "../constants/saldoConstants";

export const getSaldo = (userEmail) => async (dispatch) => {
  try {
    dispatch({ type: GET_SALDO_REQUEST });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await api.post(
      `/usuario/saldo`,

      { email: userEmail },
      config
    );
    //console.log("aaaaaaaa");
    dispatch({
      type: GET_SALDO_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_SALDO_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
