export const RECARGA_PLACE_REQUEST = "RECARGA_PLACE_REQUEST";
export const RECARGA_PLACE_SUCCESS = "RECARGA_DELETE_SUCESS";
export const RECARGA_PLACE_FAIL = "RECARGA_DELETE_FAIL";
export const RECARGA_PLACE_RESET = "RECARGA_DELETE_RESET";

export const RECARGA_RESULT_REQUEST = "RECARGA_RESULT_REQUEST";
export const RECARGA_RESULT_SUCCESS = "RECARGA_RESULT_SUCESS";
export const RECARGA_RESULT_FAIL = "RECARGA_RESULT_FAIL";
export const RECARGA_RESULT_RESET = "RECARGA_RESULT_RESET";

export const RECARGA_TRANSFERENCIA_RESULT_ADD =
  "RECARGA_TRANSFERENCIA_RESULT_ADD";
export const RECARGA_TRANSFERENCIA_RESULT_RESET =
  "RECARGA_TRANSFERENCIA_RESULT_RESET";
