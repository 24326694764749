import React, { useState, useEffect } from "react";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import { FaArrowLeft, FaCheckCircle } from "react-icons/fa";
import DayPicker from "react-day-picker";
import { useDispatch, useSelector } from "react-redux";
import api from "../api/api";

import "react-day-picker/lib/style.css";
import Layout from "../components/Layout/Layout";
import MobileMenu from "../components/Header/MobileMenu";
import Header from "../components/Header/Header";
import Footer from "../components/Layout/Footer";
import Banner from "../components/Banner";

import Box from "@mui/material/Box";

import Grid from "@mui/material/Grid";

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Message from "../components/Alerts";
import SnackBar from "../components/Snackbar";

import { listScheduleActions } from "../redux/actions/scheduleActions";
import {
  deleteCourtToReserva,
  getTempCode,
} from "../redux/actions/reservaActions";
import Loader from "../components/Loader";
import MaterialStepperReserva from "../components/ReservasPage/MaterialStepper";

import "../styles/dayPickerComponent.css";
import SelectedDayCard from "../components/SelectedDayCard";

import Seo from "../components/seo";
const HorariosPage = ( { history } ) => {
  const [ minValue, setMinValue ] = useState( null );
  /*  const [errorMinValue, seterrorMinValue] = useState(null);
   const [loadingMinValue, setloadingMinValue] = useState(null); */

  const day = new Date()
    .toLocaleString( "sv-SE", {
      timeZone: "America/Guayaquil",
    } )
    .slice( 0, 10 );
  const currentDay = new Date();
  const dispatch = useDispatch();
  const [ selectedDay, setSelectedDay ] = useStateWithCallbackLazy( currentDay );
  const oneDay = 24 * 60 * 60 * 1000;
  const diffDays = Math.round( ( selectedDay - currentDay ) / oneDay );

  const [ finalizarSeleccion, setfinalizarSeleccion ] = useState( false );
  const [ selectedSchedule, setSelectedSchedule ] = useState( [] );
  const reserva = useSelector( ( state ) => state.reserva );
  const placeReserva = useSelector( ( state ) => state.tempCode );
  const { loading: loadingPlace } = placeReserva;

  const userLogin = useSelector( ( state ) => state.userLogin );
  const { userInfo } = userLogin;
  const scheduleList = useSelector( ( state ) => state.schedule );
  const { loading, error, schedule } = scheduleList;

  const saldoUsuario = useSelector( ( state ) => state.saldo );

  const MONTHS = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  const WEEKDAYS_LONG = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miercoles",
    "Jueves",
    "Viernes",
    "Sábado",
  ];
  const WEEKDAYS_SHORT = [ "Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa" ];
  const modifiersStyles = {
    birthday: {
      color: "white",
      backgroundColor: "#ffc107",
    },
    thursdays: {
      color: "#ffc107",
      backgroundColor: "#fffdee",
    },
    outside: {
      backgroundColor: "white",
    },
  };

  const handleDayClick = ( daySelec ) => {
    setSelectedDay( daySelec, ( current ) => {
      dispatch(
        listScheduleActions(
          current.toISOString().slice( 0, 10 ),
          reserva.court.cancha
        )
      );
    } );
  };

  const handleRegresar = ( e ) => {
    e.preventDefault();
    dispatch( deleteCourtToReserva() );
    history.push( "/reservas/cancha" );
  };

  const handleFinalizar = ( e ) => {
    e.preventDefault();
    if ( selectedSchedule.length > 0 ) {
      dispatch( getTempCode( selectedSchedule ) );
    } else {
      setfinalizarSeleccion( !finalizarSeleccion );
    }
  };


  useEffect( () => {
    const add = async () => {
      try {
        const { data } = await api.get( "/precio/minimo" );
        //console.log(data);
        setMinValue( data.precio_minimo );
      } catch ( error ) {

      }
    };

    add();

    const script = document.createElement( "script" );
    script.type = "text/javascript";
    script.src = "/static/js/vendor/jquery-library.js";
    script.async = true;
    script.onload = () => {
      const script13 = document.createElement( "script" );
      script13.type = "text/javascript";
      script13.src = "/static/js/jquery-ui.js";
      script13.async = true;

      const script2 = document.createElement( "script" );
      script2.type = "text/javascript";
      script2.src = "/static/js/vendor/modernizr-2.8.3-respond-1.4.2.min.js";
      script2.async = true;
      const script3 = document.createElement( "script" );
      script3.type = "text/javascript";
      script3.src = "/static/js/vendor/bootstrap.min.js";
      script3.async = true;
      const script4 = document.createElement( "script" );
      script4.type = "text/javascript";
      script4.src = "/static/js/owl.carousel.js";
      script4.async = true;
      const script5 = document.createElement( "script" );
      script5.type = "text/javascript";
      script5.src = "/static/js/isotope.pkgd.js";
      script5.async = true;
      const script6 = document.createElement( "script" );
      script6.type = "text/javascript";
      script6.src = "/static/js/prettyPhoto.js";
      script6.async = true;
      const script7 = document.createElement( "script" );
      script7.type = "text/javascript";
      script7.src = "/static/js/customScrollbar.min.js";
      script7.async = true;
      const script9 = document.createElement( "script" );
      script9.type = "text/javascript";
      script9.src = "/static/js/swiper.min.js";
      script9.async = true;
      const script10 = document.createElement( "script" );
      script10.type = "text/javascript";
      script10.src = "/static/js/countTo.js";
      script10.async = true;
      const script11 = document.createElement( "script" );
      script11.type = "text/javascript";
      script11.src = "/static/js/appear.js";
      script11.async = true;
      const script8 = document.createElement( "script" );
      script8.type = "text/javascript";
      script8.src = "/static/js/main.js";
      script8.async = true;
      document.body.appendChild( script13 );
      document.body.appendChild( script2 );
      document.body.appendChild( script3 );
      document.body.appendChild( script4 );
      document.body.appendChild( script5 );
      document.body.appendChild( script6 );
      document.body.appendChild( script7 );
      document.body.appendChild( script9 );
      document.body.appendChild( script10 );
      document.body.appendChild( script11 );
      document.body.appendChild( script8 );
    };

    document.body.appendChild( script );
    window.scrollTo( 0, 0 );
    if ( loadingPlace === false ) {
      if ( userInfo && minValue && saldoUsuario.saldo.saldo >= minValue ) {
        history.push( "/reservas/resumen-reserva" );
      } else {
        history.push( "/reservas/metodo-pago" );
      }
    }
    /*     console.log(reserva); */
    if ( reserva.court.cancha ) {
      dispatch( listScheduleActions( day, reserva.court.cancha ) );
    } else {
      history.push( "/reservas/cancha" );
    }
  }, [
    dispatch,
    reserva,
    day,
    history,
    userInfo,
    loadingPlace,
    saldoUsuario,
    minValue,
  ] );
  return (
    <Layout>
      <Seo
        titlePage="Horarios"
        descriptionPage="Pádel Cuenca. Somos un club conformado por personas amantes del deporte, enfocados en fomentar la vida activa y saludable, priorizando el ejercicio como un hábito que beneficie nuestro estado físico y mental, Disponemos de canchas de Pádel en Cuenca Ecuador para que pueda realizar una reserva."
      />
      { finalizarSeleccion && (
        <SnackBar
          severity="error"
          message="Por favor seleccione al menos una fecha para la reserva"
        />
      ) }
      <div id="tg-wrapper" className="tg-wrapper tg-haslayout">
        <MobileMenu />
        <Header />
        <Banner title="Reservas" />
        <main id="tg-main" className="tg-main tg-haslayout">
          <section className="tg-main-section tg-paddingbottom-zero tg-haslayout">
            <div className="container">
              <div className="tg-section-name">
                <h2 style={ { color: "#d2006e" } }>reserva</h2>
              </div>
              <div className="col-sm-11 col-xs-11 pull-right">
                <MaterialStepperReserva step={ 2 } />
              </div>
              <div className="col-sm-11 col-xs-11 p">
                <div className="row" style={ { marginTop: "40px" } }>
                  <div className="tg-contactus tg-haslayout">
                    { loadingPlace !== undefined ? (
                      <div style={ { textAlign: "center", marginTop: "30px" } }>
                        <Loader />
                        <h5 style={ { margin: "30px 0px" } }>
                          Por favor espere mientras validamos el horario.
                        </h5>
                      </div>
                    ) : (
                      <div className="row">
                        <div className="col-md-5 col-sm-12 col-xs-12">
                          <div className="tg-contactinfobox">
                            <div className="tg-section-heading">
                              <h2 style={ { color: "#d2006e" } }>Por favor seleccione una fecha</h2>
                            </div>

                            <DayPicker
                              onDayClick={ handleDayClick }
                              selectedDays={ selectedDay }
                              months={ MONTHS }
                              weekdaysLong={ WEEKDAYS_LONG }
                              weekdaysShort={ WEEKDAYS_SHORT }
                              modifiersStyles={ modifiersStyles }
                              className="daypicker"
                            />
                          </div>
                        </div>
                        {/* USER LOGIN---------------------------------------------------------- */ }
                        { userInfo ? (
                          saldoUsuario.saldo &&
                            minValue &&
                            /*---------------------------------------------------------------- user login with saldo */
                            saldoUsuario.saldo.saldo >= minValue ? (
                            <div className="col-md-7 col-sm-12 col-xs-12">
                              { schedule.disponible ? (
                                schedule.disponible.length > 0 &&
                                  diffDays >= 0 ? (
                                  <h5>Disponible </h5>
                                ) : (
                                  <div>
                                    <h5>
                                      No existen horarios disponibles en este
                                      día
                                    </h5>
                                  </div>
                                )
                              ) : (
                                ""
                              ) }

                              { loading ? (
                                <Loader />
                              ) : error ? (
                                <Message
                                  severity="error"
                                  message="Algo salió mal, por favor intentalo mas tarde"
                                />
                              ) : (
                                <>
                                  <Box sx={ { flexGrow: 1 } }>
                                    <Grid
                                      container
                                      spacing={ { xs: 2, md: 3 } }
                                      columns={ { xs: 4, sm: 8, md: 12 } }
                                    >
                                      { schedule.disponible
                                        ? schedule.disponible.map( ( disp, i ) => (
                                          <Grid item xs={ 2 } sm={ 4 } md={ 4 } key={ i }>
                                            { disp && selectedDay && (
                                              <div className="horarioCheckBox horarioDisponible">
                                                <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      onChange={ ( e ) => {
                                                        e.preventDefault();
                                                        const isChecked = e.target.checked;

                                                        if ( isChecked ) {
                                                          setSelectedSchedule( ( prevSchedule ) => [
                                                            ...prevSchedule,
                                                            {
                                                              fecha: selectedDay.toISOString().slice( 0, 10 ),
                                                              hora: disp.hora,
                                                              precio: disp.precio - disp.descuento,
                                                            },
                                                          ] );
                                                        } else {
                                                          setSelectedSchedule( ( prevSchedule ) =>
                                                            prevSchedule.filter(
                                                              ( di ) =>
                                                                di.fecha !==
                                                                selectedDay.toISOString().slice( 0, 10 ) ||
                                                                di.hora !== disp.hora
                                                            )
                                                          );
                                                        }
                                                      } }
                                                      checked={ selectedSchedule.some(
                                                        ( o ) =>
                                                          o.fecha === selectedDay.toISOString().slice( 0, 10 ) &&
                                                          o.hora === disp.hora
                                                      ) }
                                                      color="success"
                                                      sx={ {
                                                        "& .MuiSvgIcon-root": {
                                                          fontSize: 22,
                                                        },
                                                      } }
                                                    />
                                                  }
                                                  label={
                                                    <div style={ { fontSize: "14px" } }>
                                                      <span>Hora: { disp.hora.slice( 0, 5 ) }</span>
                                                      <p style={ { fontWeight: "700" } }>
                                                        Precio: ${ disp.precio - disp.descuento }
                                                      </p>
                                                    </div>
                                                  }
                                                />
                                              </div>
                                            ) }
                                          </Grid>
                                        ) )
                                        : null }
                                    </Grid>
                                  </Box>
                                </>
                              ) }

                              { selectedSchedule.length > 0 ? (
                                <>
                                  <h5
                                    style={ {
                                      marginTop: "35px",
                                      marginBottom: "25px",
                                    } }
                                  >
                                    SELECCIONADO
                                  </h5>
                                  <Box
                                    sx={ { flexGrow: 1 } }
                                    style={ { marginBottom: "15px" } }
                                  >
                                    <Grid
                                      container
                                      spacing={ { xs: 2, md: 3 } }
                                      columns={ { xs: 4, sm: 8, md: 12 } }
                                    >
                                      { selectedSchedule.map( ( sel, i ) => (
                                        <Grid item xs={ 4 } sm={ 4 } md={ 6 } key={ i }>
                                          <SelectedDayCard
                                            fecha={ sel.fecha }
                                            hora={ sel.hora }
                                            id={ reserva.court.cancha }
                                          />
                                        </Grid>
                                      ) ) }
                                    </Grid>
                                  </Box>

                                  <h5
                                    style={ {
                                      marginTop: "35px",
                                      marginBottom: "25px",
                                    } }
                                  >
                                    Saldo Restante:{ " " }
                                    { saldoUsuario.saldo.saldo -
                                      parseFloat(
                                        selectedSchedule.reduce(
                                          ( prevValue, currentValue ) =>
                                            parseFloat( prevValue ) +
                                            parseFloat( currentValue.precio ),
                                          0
                                        )
                                      ) <
                                      0 ? (
                                      <span
                                        style={ {
                                          color: "red",
                                          fontWeight: "700",
                                        } }
                                      >
                                        ${ " " }
                                        { saldoUsuario.saldo.saldo -
                                          parseFloat(
                                            selectedSchedule.reduce(
                                              ( prevValue, currentValue ) =>
                                                parseFloat( prevValue ) +
                                                parseFloat( currentValue.precio ),
                                              0
                                            )
                                          ) }
                                      </span>
                                    ) : (
                                      <span
                                        style={ {
                                          color: "#d2006e",
                                          fontWeight: "700",
                                        } }
                                      >
                                        ${ " " }
                                        { saldoUsuario.saldo.saldo -
                                          parseFloat(
                                            selectedSchedule.reduce(
                                              ( prevValue, currentValue ) =>
                                                parseFloat( prevValue ) +
                                                parseFloat( currentValue.precio ),
                                              0
                                            )
                                          ) }
                                      </span>
                                    ) }
                                  </h5>
                                </>
                              ) : (
                                <div style={ { margin: "25px 0px" } }>
                                  <p
                                    style={ {
                                      fontSize: "18px",
                                      fontWeight: "600",
                                    } }
                                  >
                                    Todavía no existen horarios seleccionados
                                  </p>
                                </div>
                              ) }

                              <div className="buttonContainer">
                                <button
                                  className="tg-btn"
                                  style={ {
                                    background: "#41404B",
                                    color: "white",
                                    marginTop: "5px",
                                  } }
                                  onClick={ handleRegresar }
                                >
                                  <FaArrowLeft /> Regresar
                                </button>
                                { saldoUsuario.saldo.saldo -
                                  parseFloat(
                                    selectedSchedule.reduce(
                                      ( prevValue, currentValue ) =>
                                        parseFloat( prevValue ) +
                                        parseFloat( currentValue.precio ),
                                      0
                                    )
                                  ) <
                                  0 ? null : (
                                  <button
                                    className="tg-btn"
                                    style={ {
                                      marginTop: "5px",
                                    } }
                                    onClick={ handleFinalizar }
                                  >
                                    Reservar <FaCheckCircle />
                                  </button>
                                ) }
                              </div>
                            </div>
                          ) : (
                            saldoUsuario && (
                              /*---------------------------------------------------------------- user login without saldo */
                              <div className="col-md-7 col-sm-12 col-xs-12">
                                { schedule.disponible ? (
                                  schedule.disponible.length > 0 &&
                                    diffDays >= 0 ? (
                                    <h5>Disponible </h5>
                                  ) : (
                                    <div>
                                      <h5>
                                        No existen horarios disponibles en este
                                        día
                                      </h5>
                                    </div>
                                  )
                                ) : (
                                  ""
                                ) }

                                { loading ? (
                                  <Loader />
                                ) : error ? (
                                  <Message
                                    severity="error"
                                    message="Algo salió mal, por favor intentalo mas tarde"
                                  />
                                ) : (
                                  <>
                                    <Box sx={ { flexGrow: 1 } }>
                                      <Grid
                                        container
                                        spacing={ { xs: 2, md: 3 } }
                                        columns={ { xs: 4, sm: 8, md: 12 } }
                                      >
                                        { schedule.disponible &&
                                          schedule.disponible.map( ( disp, i ) => (
                                            <Grid item xs={ 2 } sm={ 4 } md={ 4 } key={ i }>
                                              { diffDays >= 0 && disp && selectedDay ? (
                                                <div className="horarioCheckBox horarioDisponible">
                                                  <FormControlLabel
                                                    control={
                                                      <Checkbox
                                                        onChange={ ( e ) => {
                                                          e.preventDefault();
                                                          const isChecked = e.target.checked;

                                                          if ( isChecked ) {
                                                            setSelectedSchedule( ( prevSchedule ) => [
                                                              ...prevSchedule,
                                                              {
                                                                fecha: selectedDay.toISOString().slice( 0, 10 ),
                                                                hora: disp.hora,
                                                                precio: disp.precio,
                                                              },
                                                            ] );
                                                          } else {
                                                            setSelectedSchedule( ( prevSchedule ) =>
                                                              prevSchedule.filter(
                                                                ( di ) =>
                                                                  di.fecha !==
                                                                  selectedDay.toISOString().slice( 0, 10 ) ||
                                                                  di.hora !== disp.hora
                                                              )
                                                            );
                                                          }
                                                        } }
                                                        checked={ selectedSchedule.some(
                                                          ( o ) =>
                                                            o.fecha === selectedDay.toISOString().slice( 0, 10 ) &&
                                                            o.hora === disp.hora
                                                        ) }
                                                        color="success"
                                                        sx={ {
                                                          "& .MuiSvgIcon-root": {
                                                            fontSize: 22,
                                                          },
                                                        } }
                                                      />
                                                    }
                                                    label={
                                                      <div style={ { fontSize: "14px" } }>
                                                        <span>Hora: { disp.hora.slice( 0, 5 ) }</span>
                                                        <p style={ { fontWeight: "700" } }>
                                                          Precio: ${ disp.precio }
                                                        </p>
                                                      </div>
                                                    }
                                                  />
                                                </div>
                                              ) : null }
                                            </Grid>
                                          ) ) }
                                      </Grid>
                                    </Box>
                                  </>
                                ) }

                                { selectedSchedule.length > 0 ? (
                                  <>
                                    <h5
                                      style={ {
                                        marginTop: "35px",
                                        marginBottom: "25px",
                                      } }
                                    >
                                      SELECCIONADO
                                    </h5>
                                    <Box
                                      sx={ { flexGrow: 1 } }
                                      style={ { marginBottom: "15px" } }
                                    >
                                      <Grid
                                        container
                                        spacing={ { xs: 2, md: 3 } }
                                        columns={ { xs: 4, sm: 8, md: 12 } }
                                      >
                                        { selectedSchedule.map( ( sel, i ) => (
                                          <Grid
                                            item
                                            xs={ 4 }
                                            sm={ 4 }
                                            md={ 6 }
                                            key={ i }
                                          >
                                            <SelectedDayCard
                                              fecha={ sel.fecha }
                                              hora={ sel.hora }
                                              id={ reserva.court.cancha }
                                            />
                                          </Grid>
                                        ) ) }
                                      </Grid>
                                    </Box>

                                    <h5
                                      style={ {
                                        marginTop: "35px",
                                        marginBottom: "25px",
                                      } }
                                    >
                                      TOTAL A PAGAR:{ " " }
                                      <span
                                        style={ {
                                          color: "#d2006e",
                                          fontWeight: "700",
                                        } }
                                      >
                                        ${ " " }
                                        { parseFloat(
                                          selectedSchedule.reduce(
                                            ( prevValue, currentValue ) =>
                                              parseFloat( prevValue ) +
                                              parseFloat( currentValue.precio ),
                                            0
                                          )
                                        ) }
                                      </span>
                                    </h5>
                                  </>
                                ) : (
                                  <div style={ { margin: "25px 0px" } }>
                                    <p
                                      style={ {
                                        fontSize: "18px",
                                        fontWeight: "600",
                                      } }
                                    >
                                      Todavía no existen horarios seleccionados
                                    </p>
                                  </div>
                                ) }

                                <div className="buttonContainer">
                                  <button
                                    className="tg-btn"
                                    style={ {
                                      background: "#41404B",
                                      color: "white",
                                      marginTop: "5px",
                                    } }
                                    onClick={ handleRegresar }
                                  >
                                    <FaArrowLeft /> Regresar
                                  </button>
                                  <button
                                    className="tg-btn"
                                    style={ {
                                      marginTop: "5px",
                                    } }
                                    onClick={ handleFinalizar }
                                  >
                                    Reservar <FaCheckCircle />
                                  </button>
                                </div>
                              </div>
                            )
                          )
                        ) : (
                          <div className="col-md-7 col-sm-12 col-xs-12">
                            { schedule.disponible ? (
                              schedule.disponible.length > 0 &&
                                diffDays >= 0 ? (
                                <h5>Disponible </h5>
                              ) : (
                                <div>
                                  <h5>
                                    No existen horarios disponibles en este día
                                  </h5>
                                </div>
                              )
                            ) : (
                              ""
                            ) }

                            { loading ? (
                              <Loader />
                            ) : error ? (
                              <Message
                                severity="error"
                                message="Algo salió mal, por favor intentalo mas tarde"
                              />
                            ) : (
                              /* USER GUEST---------------------------------------------------------- */
                              <>
                                <Box sx={ { flexGrow: 1 } }>
                                  <Grid
                                    container
                                    spacing={ { xs: 2, md: 3 } }
                                    columns={ { xs: 4, sm: 8, md: 12 } }
                                  >
                                    { schedule?.disponible?.map( ( disp, i ) => (
                                      <Grid item xs={ 2 } sm={ 4 } md={ 4 } key={ i }>
                                        { diffDays >= 0 && disp ? (
                                          <div className="horarioCheckBox horarioDisponible">
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  onChange={ ( e ) => {
                                                    e.preventDefault();
                                                    const isChecked = e.target.checked;

                                                    if ( isChecked ) {
                                                      setSelectedSchedule( ( prevSchedule ) => [
                                                        ...prevSchedule,
                                                        {
                                                          fecha: selectedDay
                                                            .toISOString()
                                                            .slice( 0, 10 ),
                                                          hora: disp.hora,
                                                          precio: disp.precio,
                                                        },
                                                      ] );
                                                    } else {
                                                      setSelectedSchedule( ( prevSchedule ) =>
                                                        prevSchedule.filter(
                                                          ( di ) =>
                                                            di.fecha !==
                                                            selectedDay.toISOString().slice( 0, 10 ) ||
                                                            di.hora !== disp.hora
                                                        )
                                                      );
                                                    }
                                                  } }
                                                  checked={ selectedSchedule.some(
                                                    ( o ) =>
                                                      o.fecha ===
                                                      selectedDay.toISOString().slice( 0, 10 ) &&
                                                      o.hora === disp.hora
                                                  ) }
                                                  color="success"
                                                  sx={ {
                                                    "& .MuiSvgIcon-root": {
                                                      fontSize: 22,
                                                    },
                                                  } }
                                                />
                                              }
                                              label={
                                                <div style={ { fontSize: "14px" } }>
                                                  <span>Hora: { disp.hora.slice( 0, 5 ) }</span>
                                                  <p style={ { fontWeight: "700" } }>
                                                    Precio: $ { disp.precio }
                                                  </p>
                                                </div>
                                              }
                                            />
                                          </div>
                                        ) : null }
                                      </Grid>
                                    ) ) }
                                  </Grid>
                                </Box>
                              </>
                            ) }

                            { selectedSchedule.length > 0 ? (
                              <>
                                <h5
                                  style={ {
                                    marginTop: "35px",
                                    marginBottom: "25px",
                                  } }
                                >
                                  SELECCIONADO
                                </h5>
                                <Box
                                  sx={ { flexGrow: 1 } }
                                  style={ { marginBottom: "15px" } }
                                >
                                  <Grid
                                    container
                                    spacing={ { xs: 2, md: 3 } }
                                    columns={ { xs: 4, sm: 8, md: 12 } }
                                  >
                                    { selectedSchedule.map( ( sel, i ) => (
                                      <Grid item xs={ 4 } sm={ 4 } md={ 6 } key={ i }>
                                        <SelectedDayCard
                                          fecha={ sel.fecha }
                                          hora={ sel.hora }
                                          id={ reserva.court.cancha }
                                        />
                                      </Grid>
                                    ) ) }
                                  </Grid>
                                </Box>

                                <h5
                                  style={ {
                                    marginTop: "35px",
                                    marginBottom: "25px",
                                  } }
                                >
                                  TOTAL A PAGAR:{ " " }
                                  <span
                                    style={ {
                                      color: "#d2006e",
                                      fontWeight: "700",
                                    } }
                                  >
                                    ${ " " }
                                    { parseFloat(
                                      selectedSchedule.reduce(
                                        ( prevValue, currentValue ) =>
                                          parseFloat( prevValue ) +
                                          parseFloat( currentValue.precio ),
                                        0
                                      )
                                    ) }
                                  </span>
                                </h5>
                              </>
                            ) : (
                              <div style={ { margin: "25px 0px" } }>
                                <p
                                  style={ {
                                    fontSize: "18px",
                                    fontWeight: "600",
                                  } }
                                >
                                  Todavía no existen horarios seleccionados
                                </p>
                              </div>
                            ) }

                            <div className="buttonContainer">
                              <button
                                className="tg-btn"
                                style={ {
                                  background: "#41404B",
                                  color: "white",
                                  marginTop: "5px",
                                } }
                                onClick={ handleRegresar }
                              >
                                <FaArrowLeft /> Regresar
                              </button>
                              <button
                                className="tg-btn"
                                style={ {
                                  marginTop: "5px",
                                } }
                                onClick={ handleFinalizar }
                              >
                                Reservar <FaCheckCircle />
                              </button>
                            </div>
                          </div>
                        ) }
                      </div>
                    ) }
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        <Footer />
      </div>
    </Layout>
  );
};

export default HorariosPage;
