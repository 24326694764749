import api from "../../api/api";
import {
  RESERVA_PLACE_FAIL,
  RESERVA_PLACE_REQUEST,
  RESERVA_PLACE_SUCCESS,
} from "../constants/reservasConstants";
import {
  RESERVA_PLACE_RESET,
  RESERVA_GET_RESULT_FAIL,
  RESERVA_GET_RESULT_REQUEST,
  RESERVA_GET_RESULT_SUCCESS,
} from "../constants/reservaPlace";

import { getSaldo } from "./saldoActions";

export const placeReserva =
  (reservaData, codigo, metodo) => async (dispatch, getState) => {
    try {
      dispatch({ type: RESERVA_PLACE_REQUEST });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      // console.log(bodyData);
      const usuario = {
        email: reservaData.user.email,
        telefono: reservaData.user.telefono,
      };
      const horario = reservaData.schedule;
      const cancha = { id: reservaData.court.cancha };
      const { data } = await api.post(
        "/reserva",
        { codigo, metodo, usuario, horario, cancha },
        config
      );

      dispatch({
        type: RESERVA_PLACE_SUCCESS,
        payload: data,
      });
      dispatch(getSaldo(reservaData.user.email));
      /* localStorage.setItem(
      "reserva",
      
    ); */
      //console.log("reserva sucesssssssssss");
    } catch (error) {
      console.log(error);
      dispatch({
        type: RESERVA_PLACE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const placeReservaReset = () => async (dispatch) => {
  //console.log("dfdffdfddfrfgrgtgthhby");
  dispatch({
    type: RESERVA_PLACE_RESET,
  });
};

export const getReservaResult = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: RESERVA_GET_RESULT_REQUEST });

    const { data } = await api.get(`/transaccion/${id}`);
    //console.log(data);
    dispatch({
      type: RESERVA_GET_RESULT_SUCCESS,
      payload: data,
    });
    /* localStorage.setItem(
    "reserva",
    
  ); */
  } catch (error) {
    //console.log(error);
    dispatch({
      type: RESERVA_GET_RESULT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
