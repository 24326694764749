import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import cancha from "../images/cancha_1 Banco Guayaquil.jpg";
import cancha2 from "../images/cancha_2 American Express.jpg";

const SelectedDayCard = ({ fecha, hora, id }) => {
  const date = new Date(fecha + " 12:00:00");
  // const date = new Date(fecha.replace(" ", "T"));
  return (
    <Card sx={{ display: "flex" }}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flex: "0.5 0 auto" }}>
          <Typography component="div" variant="h6">
            {date.toLocaleString("default", {
              weekday: "short",
              timeZone: "UTC",
            })}
            , {date.getDate()} de{" "}
            {date.toLocaleString("default", { month: "long" })}
          </Typography>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="div"
          >
            {hora}
          </Typography>
        </CardContent>
      </Box>
      <CardMedia
        component="img"
        sx={{ height: 100 }}
        image={id === 1 ? cancha : cancha2}
        alt="Live from space album cover"
      />
    </Card>
  );
};

export default SelectedDayCard;
