import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";

import Layout from "../components/Layout/Layout";
import MobileMenu from "../components/Header/MobileMenu";
import Header from "../components/Header/Header";
import Footer from "../components/Layout/Footer";
import Banner from "../components/Banner";
import { deleteReserva } from "../redux/actions/reservaActions";
import { RESERVA_PLACE_RESET } from "../redux/constants/reservaPlace";
import Seo from "../components/seo";

const ResultadoErrorPago = ({ match, history }) => {
  const transaccionId = match.params.id;
  const dispatch = useDispatch();
  const deleteInfo = useSelector((state) => state.reservaDelete);
  const { error, success } = deleteInfo;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    dispatch(deleteReserva(transaccionId));
    dispatch({ type: RESERVA_PLACE_RESET });

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "/static/js/vendor/jquery-library.js";
    script.async = true;
    script.onload = () => {
      const script13 = document.createElement("script");
      script13.type = "text/javascript";
      script13.src = "/static/js/jquery-ui.js";
      script13.async = true;

      const script2 = document.createElement("script");
      script2.type = "text/javascript";
      script2.src = "/static/js/vendor/modernizr-2.8.3-respond-1.4.2.min.js";
      script2.async = true;
      const script3 = document.createElement("script");
      script3.type = "text/javascript";
      script3.src = "/static/js/vendor/bootstrap.min.js";
      script3.async = true;
      const script4 = document.createElement("script");
      script4.type = "text/javascript";
      script4.src = "/static/js/owl.carousel.js";
      script4.async = true;
      const script5 = document.createElement("script");
      script5.type = "text/javascript";
      script5.src = "/static/js/isotope.pkgd.js";
      script5.async = true;
      const script6 = document.createElement("script");
      script6.type = "text/javascript";
      script6.src = "/static/js/prettyPhoto.js";
      script6.async = true;
      const script7 = document.createElement("script");
      script7.type = "text/javascript";
      script7.src = "/static/js/customScrollbar.min.js";
      script7.async = true;
      const script9 = document.createElement("script");
      script9.type = "text/javascript";
      script9.src = "/static/js/swiper.min.js";
      script9.async = true;
      const script10 = document.createElement("script");
      script10.type = "text/javascript";
      script10.src = "/static/js/countTo.js";
      script10.async = true;
      const script11 = document.createElement("script");
      script11.type = "text/javascript";
      script11.src = "/static/js/appear.js";
      script11.async = true;
      const script8 = document.createElement("script");
      script8.type = "text/javascript";
      script8.src = "/static/js/main.js";
      script8.async = true;
      document.body.appendChild(script13);
      document.body.appendChild(script2);
      document.body.appendChild(script3);
      document.body.appendChild(script4);
      document.body.appendChild(script5);
      document.body.appendChild(script6);
      document.body.appendChild(script7);
      document.body.appendChild(script9);
      document.body.appendChild(script10);
      document.body.appendChild(script11);
      document.body.appendChild(script8);
    };

    document.body.appendChild(script);
  }, [dispatch, transaccionId, history, userInfo]);

  return (
    <Layout>
      <Seo
        titlePage="Resultado Pago"
        descriptionPage="Pádel Cuenca. Somos un club conformado por personas amantes del deporte, enfocados en fomentar la vida activa y saludable, priorizando el ejercicio como un hábito que beneficie nuestro estado físico y mental, Disponemos de canchas de Pádel en Cuenca Ecuador para que pueda realizar una reserva."
      />

      <div id="tg-wrapper" className="tg-wrapper tg-haslayout">
        <MobileMenu />
        <Header />
        <Banner title="Resultado" />
        <section className="tg-main-section tg-paddingbottom-zero tg-haslayout">
          <div className="container">
            <div className="tg-section-name">
              <h2>resultado</h2>
            </div>
            {error && (
              <div>
                <div className="content-wrapper">
                  <h3>No es posible realizar su pago en este momento.</h3>
                  {/*  <TicketResumen /> */}
                </div>

                <>
                  <div class="row" style={{ marginBottom: "30px" }}>
                    <div style={{ textAlign: "center" }}>
                      <p style={{ fontSize: "20px" }}>
                        Por favor, realice la reserva nuevamente.
                      </p>
                      <Link
                        className="tg-btn"
                        to="/reservas/invitado"
                        style={{ margin: "15px 0px" }}
                      >
                        Reservar
                      </Link>
                    </div>
                  </div>
                </>
              </div>
            )}
            {success && (
              <div>
                <div className="content-wrapper">
                  <h3>Su pago no se ha realizado correctamente.</h3>
                  {/*  <TicketResumen /> */}
                </div>

                <>
                  <div class="row" style={{ marginBottom: "30px" }}>
                    <div style={{ textAlign: "center" }}>
                      <p style={{ fontSize: "20px" }}>
                        Por favor, realice la reserva nuevamente.
                      </p>
                      <Link
                        className="tg-btn"
                        to="/reservas/invitado"
                        style={{ margin: "15px 0px" }}
                      >
                        Reservar
                      </Link>
                    </div>
                  </div>
                </>
              </div>
            )}
          </div>
        </section>
        <Footer />
      </div>
    </Layout>
  );
};

export default ResultadoErrorPago;
