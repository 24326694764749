import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import { courtListReducer } from "./reducers/courtReducers";
import {
  reservaReducer,
  reservaListMyReducer,
  reservaDetailsReducer,
  reservaTempCodeReducer,
  reservaDeleteReducer,
} from "./reducers/reservaReducer";
import {
  scheduleListReducer,
  reagendamientoScheduleListReducer,
} from "./reducers/scheduleReducers";
import { reservaPlaceReducer } from "./reducers/reservaPlaceReducers";
import {
  reagendamientoReducer,
  reagendamientoPlaceReducer,
} from "./reducers/reagendamientoReducers";
import { getSaldoReducer } from "./reducers/saldoReducers";
import { userLoginReducer, userRegisterReducer } from "./reducers/userReducers";

import {
  recargaTempCodeReducer,
  recargaResultReducer,
  recargaTransferenciaReducer,
} from "./reducers/recargasReducers";

import { bavigationReducer } from "./reducers/navigationReducers";

const reducer = combineReducers({
  courtList: courtListReducer,
  reserva: reservaReducer,
  reservasList: reservaListMyReducer,
  reservaDetails: reservaDetailsReducer,
  reservaDelete: reservaDeleteReducer,
  placeReserva: reservaPlaceReducer,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  schedule: scheduleListReducer,
  regendamientoSchedule: reagendamientoScheduleListReducer,
  saldo: getSaldoReducer,

  tempCode: reservaTempCodeReducer,
  tempCodeRecarga: recargaTempCodeReducer,
  recargaResult: recargaResultReducer,
  recargaTransferenciaResult: recargaTransferenciaReducer,
  reagendamiento: reagendamientoReducer,
  placeReagendamiento: reagendamientoPlaceReducer,
  navigation: bavigationReducer,
});

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

/* const reservaInfoFromStorage = localStorage.getItem("reservaInfo")
  ? JSON.parse(localStorage.getItem("reservaInfo"))
  : null; */

const initialState = {
  userLogin: {
    userInfo: userInfoFromStorage,
  },
  /* reserva: reservaInfoFromStorage, */
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
