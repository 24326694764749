import api from "../../api/api";
import {
  RESERVA_ADD_COURT,
  RESERVA_DELETE_COURT,
  RESERVA_ADD_SCHEDULE,
  RESERVA_DELETE_SCHEDULE,
  RESERVA_ADD_USER,
  RESERVA_DELETE_USER,
  RESERVA_PLACE_FAIL,
  RESERVA_PLACE_REQUEST,
  RESERVA_PLACE_SUCCESS,
  RESERVA_DETAILS_SUCCESS,
  RESERVA_DETAILS_REQUEST,
  RESERVA_DETAILS_FAIL,
  RESERVA_LIST_MY_FAIL,
  RESERVA_LIST_MY_REQUEST,
  RESERVA_LIST_MY_SUCCESS,
  RESERVA_ADD_CODIGO_FAIL,
  RESERVA_ADD_CODIGO_REQUEST,
  RESERVA_ADD_CODIGO_SUCCESS,
  RESERVA_DELETE_REQUEST,
  RESERVA_DELETE_FAIL,
  RESERVA_DELETE_SUCESS,
} from "../constants/reservasConstants";

export const addCourtToReserva =
  (id, nombre_cancha, direccion, precio, estado_cancha) => async (dispatch) => {
    dispatch({
      type: RESERVA_ADD_COURT,
      payload: {
        cancha: id,
        nombre_cancha: nombre_cancha,
        direccion: direccion,
        precio: precio,
        estado_cancha: estado_cancha,
      },
    });
  };

export const addUserToReserva = (email, telefono) => async (dispatch) => {
  dispatch({
    type: RESERVA_ADD_USER,
    payload: {
      email: email,
      telefono,
    },
  });
};

export const addScheduleToReserva = (shedule) => async (dispatch) => {
  dispatch({
    type: RESERVA_ADD_SCHEDULE,
    payload: shedule,
  });
};

export const resetReserva = () => async (dispatch) => {
  dispatch({
    type: RESERVA_DELETE_USER,
  });
  dispatch({
    type: RESERVA_DELETE_SCHEDULE,
  });
  dispatch({
    type: RESERVA_DELETE_COURT,
  });
};

export const deleteUserToReserva = () => async (dispatch) => {
  dispatch({
    type: RESERVA_DELETE_USER,
  });
};

export const deleteScheduleToReserva = () => async (dispatch) => {
  //console.log("fdfrgrgrrgdddrrrqqqq");
  dispatch({
    type: RESERVA_DELETE_SCHEDULE,
  });
};

export const deleteCourtToReserva = () => async (dispatch) => {
  dispatch({
    type: RESERVA_DELETE_COURT,
  });
};

export const placeReserva =
  (reservaData, schedule) => async (dispatch, getState) => {
    //console.log(schedule);
    dispatch({
      type: RESERVA_ADD_SCHEDULE,
      payload: schedule,
    });
    try {
      dispatch({ type: RESERVA_PLACE_REQUEST });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      // console.log(bodyData);
      const usuario = { email: reservaData.user.email };
      const horario = schedule;
      const cancha = { id: reservaData.court.cancha };
      const { data } = await api.post(
        "/reserva",
        { usuario, horario, cancha },
        config
      );

      dispatch({
        type: RESERVA_PLACE_SUCCESS,
        payload: data,
      });
      /* localStorage.setItem(
      "reserva",
      
    ); */
      // console.log("reserva sucesssssssssss");
    } catch (error) {
      // console.log(error);
      dispatch({
        type: RESERVA_PLACE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getReservaDetails = (codigo) => async (dispatch, getState) => {
  try {
    dispatch({
      type: RESERVA_DETAILS_REQUEST,
    });

    /*    const {
        userLogin: { userInfo },
      } = getState() */

    /* const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      } */

    const { data } = await api.get(`/reserva/codigo/${codigo}` /* config */);

    dispatch({
      type: RESERVA_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    /* if (message === 'Not authorized, token failed') {
        dispatch(logout())
      } */
    dispatch({
      type: RESERVA_DETAILS_FAIL,
      payload: message,
    });
  }
};

export const listMyReservas = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: RESERVA_LIST_MY_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    /*  const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }  */

    const { data } = await api.post(
      `/reserva/usuario`,
      { email: userInfo.email } /* , config */
    );

    dispatch({
      type: RESERVA_LIST_MY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    /* if (message === 'Not authorized, token failed') {
      dispatch(logout())
    } */
    dispatch({
      type: RESERVA_LIST_MY_FAIL,
      payload: message,
    });
  }
};

export const getTempCode = (schedule) => async (dispatch, getState) => {
  dispatch({
    type: RESERVA_ADD_SCHEDULE,
    payload: schedule,
  });
  try {
    dispatch({ type: RESERVA_ADD_CODIGO_REQUEST });

    const { data } = await api.get(`/codigo`);
    //console.log(data);
    dispatch({
      type: RESERVA_ADD_CODIGO_SUCCESS,
      payload: data,
    });
    /* localStorage.setItem(
    "reserva",
    
  ); */
  } catch (error) {
    //console.log(error);
    dispatch({
      type: RESERVA_ADD_CODIGO_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteReserva = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: RESERVA_DELETE_REQUEST,
    });

    await api.delete(`/reserva/${id}`);

    dispatch({ type: RESERVA_DELETE_SUCESS });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      //dispatch(logout())
    }
    dispatch({
      type: RESERVA_DELETE_FAIL,
      payload: message,
    });
  }
};
