import React from "react";
import { useSelector } from "react-redux";
import logo from "../../images/padellogo.svg";

import TopNavBar from "./TopNavBar";
import { Link } from "react-router-dom";
const Header = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  return (
    <header id="tg-header" className="tg-header tg-haslayout">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 col-sm-12 col-xs-12">
          <div className="row">
            <div className="tg-topbar tg-haslayout">
              <nav id="tg-topaddnav" className="tg-topaddnav">
                <TopNavBar />
              </nav>
            </div>
            <nav id="tg-nav" className="tg-nav brand-center">
              <div className="navbar-header">
                <button
                  type="button"
                  className="navbar-toggle collapsed"
                  data-toggle="collapse"
                  data-target="#tg-navigationm-mobile"
                >
                  <i className="fa fa-bars"></i>
                </button>
                <strong className="tg-logo">
                  <Link to="/">
                    <img src={logo} alt="Pádel Cuenca" />
                  </Link>
                </strong>
              </div>
              <div id="tg-navigation" className="tg-navigation">
                <div className="tg-colhalf">
                  <ul>
                    <li className="active">
                      <Link to="/">Inicio</Link>
                    </li>
                    <li>
                      <Link to="/contacto">Contacto</Link>
                    </li>

                    <li>
                      <Link to="/about-us">Sobre Nosotros</Link>
                    </li>
                  </ul>
                </div>
                <div className="tg-colhalf">
                  <ul>
                    <li>
                      <Link to="/reservas/invitado">Reserva una cancha</Link>
                    </li>

                    <li>
                      {userInfo ? (
                        <Link to="/usuario/reservas">Mis Reservas</Link>
                      ) : (
                        <Link to="/consulta">Consultar</Link>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
