import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import imagen from "../../images/padelplayer_11zon.png";
import { FaCheckCircle } from "react-icons/fa";
const BeneficiosVip = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  return (
    <section className=" tg-haslayout tg-bgstyletwo">
      <div className="tg-bgboxone"></div>
      <div className="tg-bgboxtwo"></div>
      <div className="tg-bgpattrant">
        <div className="container">
          <div className="row">
            <div className="tg-pointstablewrapper">
              <div className="col-sm-8 col-xs-12">
                <div className="tg-pointstable">
                  <div className="tg-section-heading">
                    <h2>regístrate y obtén beneficios de socios</h2>
                  </div>
                  <p style={{ color: "white", fontSize: "16px" }}>
                    <FaCheckCircle
                      style={{
                        color: "white",
                        fontSize: "20px",
                        marginRight: "15px",
                        marginTop: "5px",
                      }}
                    />
                    {"  "} Pago preferencial en todos los horarios si es que se
                    realiza una recarga de 120 dólares.
                  </p>
                  <p style={{ color: "white", fontSize: "16px" }}>
                    <FaCheckCircle
                      style={{
                        color: "white",
                        fontSize: "20px",
                        marginRight: "15px",
                        marginTop: "5px",
                      }}
                    />
                    {"  "} Descuentos en implementos.
                  </p>
                  <p
                    style={{
                      color: "white",
                      fontSize: "16px",
                    }}
                  >
                    <FaCheckCircle
                      style={{
                        color: "white",
                        fontSize: "20px",
                        marginRight: "15px",
                        marginTop: "5px",
                      }}
                    />
                    {"  "} Sorteos de juegos gratis.
                  </p>
                  <p
                    style={{
                      color: "white",
                      fontSize: "16px",
                    }}
                  >
                    <FaCheckCircle
                      style={{
                        color: "white",
                        fontSize: "20px",
                        marginRight: "15px",
                        marginTop: "5px",
                      }}
                    />
                    {"  "} Recompensas por número de recargas seguidas.
                  </p>
                  <p
                    style={{
                      color: "white",
                      fontSize: "16px",
                      marginBottom: "45px",
                    }}
                  >
                    <FaCheckCircle
                      style={{
                        color: "white",
                        fontSize: "20px",
                        marginRight: "15px",
                        marginTop: "5px",
                      }}
                    />
                    {"  "} Más sorpresas…
                  </p>
                  {userInfo ? (
                    <div className="tg-btnbox" style={{ marginTop: "20px" }}>
                      <Link className="tg-btn" to="/registro">
                        <span>Realizar una recarga</span>
                      </Link>
                    </div>
                  ) : (
                    <div className="tg-btnbox" style={{ marginTop: "25px" }}>
                      <Link className="tg-btn" to="/registro">
                        <span>Registrarse Ahora</span>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-sm-4 col-xs-12 hidden-xs">
                <figure>
                  <img src={imagen} alt="Jugador Padel" layout="intrinsic" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BeneficiosVip;
