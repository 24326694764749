import {
  GET_SALDO_FAIL,
  GET_SALDO_REQUEST,
  GET_SALDO_SUCCESS,
} from "../constants/saldoConstants";

export const getSaldoReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_SALDO_REQUEST:
      return { loading: true, saldo: {} };
    case GET_SALDO_SUCCESS:
      return { loading: false, saldo: action.payload };
    case GET_SALDO_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
