import React, { useState, useEffect } from "react";
/* import BlogContent from "../../constants/blogHomeContent"; */
import api from "../../api/api";
import Video from "./VideoHomeBlog";
import Loader from "../Loader";
import Alert from "../Alerts";

const BlogHome = () => {
  const [successData, setSuccessData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);

  useEffect(() => {
    const edit = async () => {
      setLoading(true);
      setError(null);
      setSuccessData(null);
      try {
        const { data } = await api.get("/noticia");
        setSuccessData(data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setError("Algo salió mal.");
        setLoading(false);
      }
    };

    edit();
  }, []);

  return (
    <section className="tg-main-section tg-haslayout">
      <div className="container">
        <div className="tg-section-name">
          <h2>Noticias Padel</h2>
        </div>
        <div className="col-sm-11 col-xs-11 pull-right">
          <div className="row">
            <div className="tg-blogpost">
              {loading ? (
                <Loader />
              ) : error ? (
                <Alert message="Algo salió mal." severity="error" />
              ) : (
                successData && (
                  <div className="row">
                    {successData.slice(0, 2).map((content, index) => (
                      <div className="col-sm-6 col-xs-12" key={index}>
                        <article className="tg-post">
                          <figure className="tg-postimg">
                            <Video videoUrl={content.url} />

                            <figcaption>
                              <ul className="tg-postmetadata">
                                <li style={{ color: "white" }}>
                                  {content.etiqueta}
                                </li>
                                {/*   {content.etiquetas.map((cont, i) => (
                              <li style={{ color: "white" }}>{cont}</li>
                            ))} */}
                              </ul>
                            </figcaption>
                          </figure>
                          <div className="tg-posttitle">
                            <h3>{content.titulo}</h3>
                          </div>
                          <div className="tg-description">
                            <p>{content.descripcion}</p>
                          </div>
                        </article>
                      </div>
                    ))}
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogHome;
