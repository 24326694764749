import React from "react";
import sponsor1 from "../../images/logo_bg_white.png";
/* import sponsor2 from "../../images/logo_american.png"; */
import sponsor3 from "../../images/logo_american_blanco.png";

const Sponsors = () => {
  return (
    <section className="tg-main-section tg-haslayout" style={ { backgroundColor: "#071e61" } }>
      <div className="container">
        <div className="tg-section-name">
          <h2 style={ { color: "#fff" } }>sponsors</h2>
        </div>
        <div className="col-sm-11 col-xs-11 pull-right">
          <div className="row">
            <div className="tg-latestresult tg-oursponsers">
              <div className="row">
                <div className="col-md-5 col-sm-12 col-xs-12">
                  <div className="tg-contentbox">
                    <div
                      className="tg-section-heading"
                      style={ { marginTop: "60px" } }
                    >
                      <h2 style={ { color: "#fff" } }>Banco Guayaquil</h2>
                    </div>
                    <div className="tg-description">
                      <p style={ { color: "#fff" } }>
                        Referirse a Banco Guayaquil es mencionar una de las instituciones financieras más destacadas de Ecuador. Nuestra entidad se distingue por potenciar valores de solidez e integridad, tanto en nuestros clientes como en nuestros colaboradores. La excelencia en el servicio al cliente es nuestro mayor activo, lo que nos ha permitido consolidarnos como una entidad fuerte y transparente. En Banco Guayaquil, estamos comprometidos con brindar una experiencia bancaria excepcional y confiable.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-7 col-sm-12 col-xs-12">
                  <div className="row">
                    <div className="col-12" style={{textAlign:"end", marginRight:"1%"}}>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.bancoguayaquil.com/"
                      >
                        <img src={ sponsor1 } alt="BancoGuayaquil" width={ 500 } />
                      </a>
                    </div>
                    
                    <div className="col-12" style={{textAlign:"center", marginTop:"5%"}}>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.americanexpress.com/es-ec/network/"
                      >
                        <img src={ sponsor3 } alt="AmericanExpress" width={ 400 } />
                      </a>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Sponsors;
