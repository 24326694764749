import {
  RESERVA_ADD_COURT,
  RESERVA_DELETE_COURT,
  RESERVA_ADD_SCHEDULE,
  RESERVA_DELETE_USER,
  RESERVA_DELETE_SCHEDULE,
  RESERVA_ADD_USER,
  RESERVA_LIST_MY_FAIL,
  RESERVA_LIST_MY_REQUEST,
  RESERVA_LIST_MY_SUCCESS,
  RESERVA_LIST_MY_RESET,
  RESERVA_DETAILS_FAIL,
  RESERVA_DETAILS_REQUEST,
  RESERVA_DETAILS_SUCCESS,
  RESERVA_DETAILS_RESET,
  RESERVA_ADD_CODIGO_FAIL,
  RESERVA_ADD_CODIGO_REQUEST,
  RESERVA_ADD_CODIGO_RESET,
  RESERVA_ADD_CODIGO_SUCCESS,
  RESERVA_DELETE_FAIL,
  RESERVA_DELETE_REQUEST,
  RESERVA_DELETE_SUCESS,
  RESERVA_DELETE_RESET,
} from "../constants/reservasConstants";

export const reservaReducer = (
  state = { court: {}, user: {}, schedule: [], reservaResult: {} },
  action
) => {
  switch (action.type) {
    case RESERVA_ADD_COURT:
      return {
        loading: true,
        ...state,
        court: action.payload,
      };
    case RESERVA_DELETE_COURT:
      return {
        loading: true,
        ...state,
        court: {},
      };
    case RESERVA_ADD_USER:
      return {
        loading: true,
        ...state,
        user: action.payload,
      };
    case RESERVA_DELETE_USER:
      return {
        loading: true,
        ...state,
        user: {},
      };
    case RESERVA_ADD_SCHEDULE:
      return {
        loading: true,
        ...state,
        schedule: action.payload,
      };
    case RESERVA_DELETE_SCHEDULE:
      return {
        loading: true,
        ...state,
        schedule: [],
      };

    default:
      return state;
  }
};

export const reservaDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case RESERVA_DETAILS_REQUEST:
      return {
        loading: true,
      };
    case RESERVA_DETAILS_SUCCESS:
      return {
        loading: false,
        reservaDetails: action.payload,
      };
    case RESERVA_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case RESERVA_DETAILS_RESET:
      return {};
    default:
      return state;
  }
};

export const reservaListMyReducer = (state = { reservas: [] }, action) => {
  switch (action.type) {
    case RESERVA_LIST_MY_REQUEST:
      return {
        loading: true,
      };
    case RESERVA_LIST_MY_SUCCESS:
      return {
        loading: false,
        reservas: action.payload,
      };
    case RESERVA_LIST_MY_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case RESERVA_LIST_MY_RESET:
      return { reservas: [] };
    default:
      return state;
  }
};
export const reservaTempCodeReducer = (state = {}, action) => {
  switch (action.type) {
    case RESERVA_ADD_CODIGO_REQUEST:
      return {
        loading: true,
      };
    case RESERVA_ADD_CODIGO_SUCCESS:
      return {
        loading: false,
        codigo: action.payload,
      };
    case RESERVA_ADD_CODIGO_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case RESERVA_ADD_CODIGO_RESET:
      return {};
    default:
      return state;
  }
};

export const reservaDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case RESERVA_DELETE_REQUEST:
      return { loading: true };
    case RESERVA_DELETE_SUCESS:
      return { loading: false, success: true };
    case RESERVA_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case RESERVA_DELETE_RESET:
      return {};
    default:
      return state;
  }
};
