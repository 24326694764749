import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaArrowLeft } from "react-icons/fa";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Layout from "../components/Layout/Layout";
import MobileMenu from "../components/Header/MobileMenu";
import Header from "../components/Header/Header";
import Footer from "../components/Layout/Footer";
import Banner from "../components/Banner";
import MaterialSteps from "../components/ReservasPage/MaterialStepper";
import Message from "../components/Alerts";
import { RESERVA_ADD_CODIGO_RESET } from "../redux/constants/reservasConstants";
import useDimentions from "../components/WindowDimentions";
import Seo from "../components/seo";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#43424D",
    color: theme.palette.common.white,
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableCellTotal = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 18,
    fontWeight: 700,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const PagoTransferenciaScreen = ({ history }) => {
  const { width } = useDimentions();
  const dispatch = useDispatch();

  const reservaPlace = useSelector((state) => state.placeReserva);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const { reservaResult, error } = reservaPlace;

  const total = reservaResult
    ? reservaResult.horario.reduce(
        (prevValue, currentValue) => prevValue + currentValue.precio,
        0
      ) * 100
    : null;

  const handleRegresar = (e) => {
    e.preventDefault();

    history.push("/reservas/horario");
  };

  useEffect(() => {
    dispatch({ type: RESERVA_ADD_CODIGO_RESET });
    if (reservaResult === undefined && error === undefined) {
      history.push("/reservas/invitado");
    }
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "/static/js/vendor/jquery-library.js";
    script.async = true;
    script.onload = () => {
      const script13 = document.createElement("script");
      script13.type = "text/javascript";
      script13.src = "/static/js/jquery-ui.js";
      script13.async = true;

      const script2 = document.createElement("script");
      script2.type = "text/javascript";
      script2.src = "/static/js/vendor/modernizr-2.8.3-respond-1.4.2.min.js";
      script2.async = true;
      const script3 = document.createElement("script");
      script3.type = "text/javascript";
      script3.src = "/static/js/vendor/bootstrap.min.js";
      script3.async = true;
      const script4 = document.createElement("script");
      script4.type = "text/javascript";
      script4.src = "/static/js/owl.carousel.js";
      script4.async = true;
      const script5 = document.createElement("script");
      script5.type = "text/javascript";
      script5.src = "/static/js/isotope.pkgd.js";
      script5.async = true;
      const script6 = document.createElement("script");
      script6.type = "text/javascript";
      script6.src = "/static/js/prettyPhoto.js";
      script6.async = true;
      const script7 = document.createElement("script");
      script7.type = "text/javascript";
      script7.src = "/static/js/customScrollbar.min.js";
      script7.async = true;
      const script9 = document.createElement("script");
      script9.type = "text/javascript";
      script9.src = "/static/js/swiper.min.js";
      script9.async = true;
      const script10 = document.createElement("script");
      script10.type = "text/javascript";
      script10.src = "/static/js/countTo.js";
      script10.async = true;
      const script11 = document.createElement("script");
      script11.type = "text/javascript";
      script11.src = "/static/js/appear.js";
      script11.async = true;
      const script8 = document.createElement("script");
      script8.type = "text/javascript";
      script8.src = "/static/js/main.js";
      script8.async = true;
      document.body.appendChild(script13);
      document.body.appendChild(script2);
      document.body.appendChild(script3);
      document.body.appendChild(script4);
      document.body.appendChild(script5);
      document.body.appendChild(script6);
      document.body.appendChild(script7);
      document.body.appendChild(script9);
      document.body.appendChild(script10);
      document.body.appendChild(script11);
      document.body.appendChild(script8);
    };

    document.body.appendChild(script);
  }, [history, reservaResult, total, error, userInfo, dispatch]);

  return (
    <Layout>
      <Seo
        titlePage="Transferencia"
        descriptionPage="Pádel Cuenca. Somos un club conformado por personas amantes del deporte, enfocados en fomentar la vida activa y saludable, priorizando el ejercicio como un hábito que beneficie nuestro estado físico y mental, Disponemos de canchas de Pádel en Cuenca Ecuador para que pueda realizar una reserva."
      />
      <div id="tg-wrapper" className="tg-wrapper tg-haslayout">
        <MobileMenu />
        <Header />
        <Banner title="Confirmación" />
        <section className="tg-main-section tg-paddingbottom-zero tg-haslayout">
          <div className={width >= 600 ? "container" : ""}>
            <div className="tg-section-name">
              <h2>pago</h2>
            </div>

            <div className="col-sm-11 col-xs-11 pull-right">
              {/*  <ReservaSteps step={3} /> */}
              <MaterialSteps step={3} />
            </div>
            <div className="col-sm-12 col-xs-12 ">
              <div className="">
                {/*  <h6>Pago con Trasnferencia bancaria</h6> */}
                {!error && (
                  <div style={{ textAlign: "center", marginBottom: "25px" }}>
                    <h6
                      style={{
                        fontSize: "24px",
                        fontWeight: "700",
                        color: "#8dbe22",
                      }}
                    >
                      Reserva pendiente de Pago
                    </h6>
                    <p
                      style={{
                        fontSize: "22px",
                        fontWeight: "700",
                      }}
                    >
                      Su código de reserva es:{" "}
                    </p>
                    <br />
                    <span
                      style={{
                        fontSize: "30px",
                        fontWeight: "700",
                        color: "#8dbe22",
                      }}
                    >
                      {reservaResult ? reservaResult.reserva.codigo : "--"}
                    </span>
                  </div>
                )}
              </div>
              <div class="row" style={{ marginBottom: "30px" }}>
                <div>
                  {error ? (
                    <div className="col-sm-11 col-xs-11 pull-right">
                      <Message severity="error" message={error} />
                      <div className="buttonContainer">
                        <button
                          className="tg-btn"
                          style={{
                            background: "#41404B",
                            color: "white",
                            marginTop: "15px",
                          }}
                          onClick={handleRegresar}
                        >
                          <FaArrowLeft /> Regresar
                        </button>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="col-md-12 col-sm-12 col-xs-12">
                        {/*  <h6>Pago</h6> */}
                        <div className="tg-description">
                          <>
                            <div
                              style={{ textAlign: "center", margin: "25px" }}
                            >
                              <h4>Pago con Transferencia Bancaria</h4>
                              <p
                                style={{
                                  padding: "15px",
                                  fontWeight: "400",
                                  fontSize: "16px",
                                }}
                              >
                                Para realizar el pago por transferencia bancaria
                                por favor realizar la transferencia a la cuenta
                                detallada a continuación. Para cualquier duda
                                comunicarse con nosotros.{" "}
                              </p>
                              <p>
                                <span
                                  style={{
                                    fontWeight: "700",
                                    fontSize: "20px",
                                  }}
                                >
                                  Recuerde que tiene un límite de{" "}
                                  <span style={{ color: "red" }}>
                                    30 minutos{" "}
                                  </span>
                                  para realizar la transferencia.
                                </span>
                              </p>
                            </div>

                            {/*  */}
                          </>
                        </div>
                      </div>
                      <div
                        className="col-md-6 col-sm-12 col-xs-12"
                        style={{ margin: "40px 0" }}
                      >
                        <h6>Datos de la Cuenta</h6>
                        <Paper
                          sx={{
                            width: "100%",
                            overflow: "hidden",
                            marginBottom: "15px",
                            marginTop: "15px",
                            maxWidth: 600,
                          }}
                          elevation={3}
                        >
                          <TableContainer
                            sx={{ maxHeight: 640, maxWidth: 600 }}
                          >
                            <Table stickyHeader aria-label="sticky table">
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    align="right"
                                    //style={{ minWidth: column.minWidth }}
                                  >
                                    Nombre
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    //style={{ minWidth: column.minWidth }}
                                  >
                                    <span
                                      style={{
                                        color: "#160F41",
                                        fontWeight: "800",
                                      }}
                                    >
                                      Xavier Castro
                                    </span>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    align="right"
                                    //style={{ minWidth: column.minWidth }}
                                  >
                                    Cédula
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    //style={{ minWidth: column.minWidth }}
                                  >
                                    <span
                                      style={{
                                        color: "#160F41",
                                        fontWeight: "800",
                                      }}
                                    >
                                      0104258520
                                    </span>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    align="right"
                                    //style={{ minWidth: column.minWidth }}
                                  >
                                    Banco
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    //style={{ minWidth: column.minWidth }}
                                  >
                                    <span
                                      style={{
                                        color: "#160F41",
                                        fontWeight: "800",
                                      }}
                                    >
                                      Banco Guayaquil
                                    </span>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    align="right"
                                    //style={{ minWidth: column.minWidth }}
                                  >
                                    Tipo de Cuenta
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    //style={{ minWidth: column.minWidth }}
                                  >
                                    <span
                                      style={{
                                        color: "#160F41",
                                        fontWeight: "800",
                                      }}
                                    >
                                      Ahorros
                                    </span>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    align="right"
                                    //style={{ minWidth: column.minWidth }}
                                  >
                                    Número de Cuenta
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    //style={{ minWidth: column.minWidth }}
                                  >
                                    <span
                                      style={{
                                        color: "#160F41",
                                        fontWeight: "800",
                                      }}
                                    >
                                      0029923366
                                    </span>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    align="right"
                                    //style={{ minWidth: column.minWidth }}
                                  >
                                    Correo
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    //style={{ minWidth: column.minWidth }}
                                  >
                                    <span
                                      style={{
                                        color: "#160F41",
                                        fontWeight: "800",
                                      }}
                                    >
                                      padelcuenca2021@gmail.com
                                    </span>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                            </Table>
                          </TableContainer>
                        </Paper>
                        <h6>Datos de Contacto</h6>
                        <Paper
                          sx={{
                            width: "100%",
                            overflow: "hidden",
                            marginBottom: "15px",
                            marginTop: "15px",
                            maxWidth: 600,
                          }}
                          elevation={3}
                        >
                          <TableContainer
                            sx={{ maxHeight: 440, maxWidth: 600 }}
                          >
                            <Table stickyHeader aria-label="sticky table">
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    align="right"
                                    //style={{ minWidth: column.minWidth }}
                                  >
                                    Correo
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    //style={{ minWidth: column.minWidth }}
                                  >
                                    <a
                                      href="mailto:padelcuenca2021@gmail.com"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="detallesLink2"
                                      style={{color:"#160F41"}}
                                    >
                                      padelcuenca2021@gmail.com
                                    </a>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    align="right"
                                    //style={{ minWidth: column.minWidth }}
                                  >
                                    Correo
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    //style={{ minWidth: column.minWidth }}
                                  >
                                    <a
                                      href="mailto:realvarado@hotmail.com"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="detallesLink2"
                                      style={{color:"#160F41"}}
                                    >
                                      realvarado@hotmail.com
                                    </a>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    align="right"
                                    //style={{ minWidth: column.minWidth }}
                                  >
                                    Celular
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    //style={{ minWidth: column.minWidth }}
                                  >
                                    <a
                                      href="tel:+593 98 433 7828"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="detallesLink2"
                                      style={{color:"#160F41"}}
                                    >
                                      +593 98 433 7828
                                    </a>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    align="right"
                                    //style={{ minWidth: column.minWidth }}
                                  >
                                    Celular
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    //style={{ minWidth: column.minWidth }}
                                  >
                                    <a
                                      href="tel:+593 99 828 0164"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="detallesLink2"
                                      style={{color:"#160F41"}}
                                    >
                                      +593 99 828 0164
                                    </a>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                            </Table>
                          </TableContainer>
                        </Paper>
                      </div>
                      <div
                        className="col-md-6 col-sm-12 col-xs-12"
                        style={{ margin: "40px 0" }}
                      >
                        <h6 style-={{ marginBottom: "15px" }}>
                          Resumen de la reserva
                        </h6>
                        <TableContainer
                          component={Paper}
                          sx={{
                            width: "100%",
                            overflow: "hidden",
                            marginBottom: "25px",
                            marginTop: "15px",
                            maxWidth: 600,
                          }}
                        >
                          <Table
                            sx={{ maxWidth: 600 }}
                            aria-label="customized table"
                          >
                            <TableHead>
                              <TableRow>
                                <StyledTableCell>Descripción</StyledTableCell>
                                <StyledTableCell align="right">
                                  Fecha
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  Hora
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  Precio
                                </StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {reservaResult &&
                                reservaResult.horario.map((reser, index) => (
                                  <StyledTableRow key={index}>
                                    <StyledTableCell component="th" scope="row">
                                      Reserva - {reser.cancha}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                      {reser.fecha.slice(0, 10)}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                      {reser.hora}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                      ${reser.precio}
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))}
                              <StyledTableRow>
                                <StyledTableCell rowSpan={1} />
                                <StyledTableCellTotal colSpan={2}>
                                  Total
                                </StyledTableCellTotal>
                                <StyledTableCellTotal align="right">
                                  <span>
                                    ${" "}
                                    {reservaResult &&
                                      parseFloat(
                                        reservaResult.horario.reduce(
                                          (prevValue, currentValue) =>
                                            parseFloat(prevValue) +
                                            parseFloat(currentValue.precio),
                                          0
                                        )
                                      )}
                                  </span>
                                </StyledTableCellTotal>
                              </StyledTableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                        {/*  <div style={{ textAlign: "center", marginTop: "15px" }}>
                          <button
                            className="tg-btn"
                            style={{
                              background: "#F9F9F9",
                              color: "red",
                              marginTop: "5px",
                            }}
                            onClick={handleRegresar} 
                          >
                            Cancelar Reserva
                          </button>
                        </div> */}
                      </div>
                    </>
                  )}
                </div>
                {error ? null : (
                  <>
                    <div
                      className="buttonContainer"
                      style={{ textAlign: "center" }}
                    >
                      {userInfo ? (
                        <Link
                          class="tg-btn"
                          to="/usuario/reservas"
                          style={{ marginTop: "15px" }}
                        >
                          ver mis reservas
                        </Link>
                      ) : (
                        <Link
                          class="tg-btn"
                          to="/consulta"
                          style={{ marginTop: "15px" }}
                        >
                          consultar una reserva
                        </Link>
                      )}
                      <Link
                        class="tg-btn"
                        to="/reservas/invitado"
                        style={{ marginTop: "15px" }}
                      >
                        realizar otra reserva
                      </Link>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </Layout>
  );
};

export default PagoTransferenciaScreen;
