import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FaArrowAltCircleRight } from "react-icons/fa";
import { logout } from "../redux/actions/userActions";
import { getSaldo } from "../redux/actions/saldoActions";

import Layout from "../components/Layout/Layout";
import MobileMenu from "../components/Header/MobileMenu";
import Header from "../components/Header/Header";
import Footer from "../components/Layout/Footer";
import Banner from "../components/Banner";
import Seo from "../components/seo";

import RecargasCard from "../components/Recargaspage/RecargasCard";
import { Link } from "react-router-dom";
import useDimentions from "../components/WindowDimentions";
const UserPage = ({ history }) => {
  const { width } = useDimentions();

  const dispatch = useDispatch();
  const saldoUsuario = useSelector((state) => state.saldo);
  const { saldo, error } = saldoUsuario;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const logoutHandler = () => {
    dispatch(logout());
  };
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "/static/js/vendor/jquery-library.js";
    script.async = true;
    script.onload = () => {
      const script13 = document.createElement("script");
      script13.type = "text/javascript";
      script13.src = "/static/js/jquery-ui.js";
      script13.async = true;

      const script2 = document.createElement("script");
      script2.type = "text/javascript";
      script2.src = "/static/js/vendor/modernizr-2.8.3-respond-1.4.2.min.js";
      script2.async = true;
      const script3 = document.createElement("script");
      script3.type = "text/javascript";
      script3.src = "/static/js/vendor/bootstrap.min.js";
      script3.async = true;
      const script4 = document.createElement("script");
      script4.type = "text/javascript";
      script4.src = "/static/js/owl.carousel.js";
      script4.async = true;
      const script5 = document.createElement("script");
      script5.type = "text/javascript";
      script5.src = "/static/js/isotope.pkgd.js";
      script5.async = true;
      const script6 = document.createElement("script");
      script6.type = "text/javascript";
      script6.src = "/static/js/prettyPhoto.js";
      script6.async = true;
      const script7 = document.createElement("script");
      script7.type = "text/javascript";
      script7.src = "/static/js/customScrollbar.min.js";
      script7.async = true;
      const script9 = document.createElement("script");
      script9.type = "text/javascript";
      script9.src = "/static/js/swiper.min.js";
      script9.async = true;
      const script10 = document.createElement("script");
      script10.type = "text/javascript";
      script10.src = "/static/js/countTo.js";
      script10.async = true;
      const script11 = document.createElement("script");
      script11.type = "text/javascript";
      script11.src = "/static/js/appear.js";
      script11.async = true;
      const script8 = document.createElement("script");
      script8.type = "text/javascript";
      script8.src = "/static/js/main.js";
      script8.async = true;
      document.body.appendChild(script13);
      document.body.appendChild(script2);
      document.body.appendChild(script3);
      document.body.appendChild(script4);
      document.body.appendChild(script5);
      document.body.appendChild(script6);
      document.body.appendChild(script7);
      document.body.appendChild(script9);
      document.body.appendChild(script10);
      document.body.appendChild(script11);
      document.body.appendChild(script8);
    };

    document.body.appendChild(script);

    if (!userInfo) {
      history.push("/login");
    } else {
      dispatch(getSaldo(userInfo.email));
    }
    window.scrollTo(0, 200);
  }, [dispatch, history, userInfo]);

  return (
    <Layout>
      <Seo
        titlePage="Usuario"
        descriptionPage="Pádel Cuenca. Somos un club conformado por personas amantes del deporte, enfocados en fomentar la vida activa y saludable, priorizando el ejercicio como un hábito que beneficie nuestro estado físico y mental, Disponemos de canchas de Pádel en Cuenca Ecuador para que pueda realizar una reserva."
      />

      <div id="tg-wrapper" className="tg-wrapper tg-haslayout">
        <MobileMenu />
        <Header />
        <Banner title="Socio" />
        <section className="tg-main-section tg-paddingbottom-zero tg-haslayout">
          <div className="container">
            <div className="tg-section-name">
              <h2>SOCIO</h2>
            </div>

            <div>
              <div className="content-wrapper">
                <h3>Mi cuenta</h3>
              </div>
              {userInfo && (
                <div className="row" style={{ margin: "25px 0px" }}>
                  <div className="col-md-8 col-sm-12 col-xs-12">
                    {error && <RecargasCard title="Saldo: --" />}
                    {saldo && (
                      <RecargasCard title={`Saldo: $ ${saldo.saldo}`} />
                    )}
                    <div style={{ textAlign: "center" }}>
                      <h6>
                        Usuario:{" "}
                        <span
                          style={{ fontWeight: "600", textTransform: "none" }}
                        >
                          {userInfo.username}
                        </span>
                      </h6>
                      <h6>
                        Correo:{" "}
                        <span
                          style={{ fontWeight: "600", textTransform: "none" }}
                        >
                          {userInfo.email}
                        </span>
                      </h6>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12 col-xs-12">
                    <div
                      style={{
                        textAlign: width >= 960 ? "" : "center",
                      }}
                    >
                      <Link
                        className="tg-btn"
                        style={{
                          background: "#41404B",
                          color: "white",
                          marginTop: "5px",
                          minWidth: "200px",
                          textAlign: "center",
                        }}
                        to="/usuario/reservas"
                      >
                        Mis Reservas
                      </Link>
                      <br />
                      <Link
                        className="tg-btn"
                        style={{
                          background: "#41404B",
                          color: "white",
                          marginTop: "5px",
                          minWidth: "200px",
                          textAlign: "center",
                        }}
                        to="/recargar"
                      >
                        Recargar
                      </Link>
                      <br />
                      <Link
                        className="tg-btn"
                        style={{
                          background: "#41404B",
                          color: "white",
                          marginTop: "5px",
                          minWidth: "200px",
                          textAlign: "center",
                        }}
                        to="/reservas/invitado"
                      >
                        Reservar
                      </Link>
                      <br />
                      <button
                        className="tg-btn"
                        style={{
                          background: "#41404B",
                          color: "white",
                          marginTop: "5px",
                          minWidth: "200px",
                          textAlign: "center",
                        }}
                        onClick={logoutHandler}
                      >
                        Salir{" "}
                        <FaArrowAltCircleRight style={{ marginLeft: "5px" }} />
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </Layout>
  );
};

export default UserPage;
