import React, { useState, useEffect } from "react";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import { FaArrowLeft, FaCheckCircle } from "react-icons/fa";
import DayPicker from "react-day-picker";
import { useDispatch, useSelector } from "react-redux";

import "react-day-picker/lib/style.css";
import Layout from "../components/Layout/Layout";
import MobileMenu from "../components/Header/MobileMenu";
import Header from "../components/Header/Header";
import Footer from "../components/Layout/Footer";
import Banner from "../components/Banner";

import Box from "@mui/material/Box";
import Seo from "../components/seo";
import Grid from "@mui/material/Grid";

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Message from "../components/Alerts";
import SnackBar from "../components/Snackbar";

import { listScheduleReagendamiento } from "../redux/actions/scheduleActions";

import { placeReagendamiento } from "../redux/actions/reagendamientoActions";
import Loader from "../components/Loader";
import MaterialStepperReserva from "../components/Reagendamiento/MaterialReAgendStep";

import "../styles/dayPickerComponent.css";
import SelectedDayCard from "../components/SelectedDayCard";

const ReagendamientoHorariosPage = ({ history }) => {
  const currentDay = new Date();
  const day = currentDay.toISOString().slice(0, 10);

  const dispatch = useDispatch();
  // para realizar el useDispatch despues que se actualize el estado
  const [selectedDay, setSelectedDay] = useStateWithCallbackLazy(currentDay);
  const oneDay = 24 * 60 * 60 * 1000;
  const diffDays = Math.round((selectedDay - currentDay) / oneDay);

  const [finalizarSeleccion, setfinalizarSeleccion] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState([]);
  console.log(selectedSchedule);
  const placeReagendamientoData = useSelector(
    (state) => state.placeReagendamiento
  );
  const { loading: loadingPlace } = placeReagendamientoData;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const scheduleList = useSelector((state) => state.regendamientoSchedule);
  const { loading, error, schedule } = scheduleList;
  const reagenda = useSelector((state) => state.reagendamiento);

  const MONTHS = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Marzo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  const WEEKDAYS_LONG = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miercoles",
    "Jueves",
    "Viernes",
    "Sábado",
  ];
  const WEEKDAYS_SHORT = ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"];
  const modifiersStyles = {
    birthday: {
      color: "white",
      backgroundColor: "#ffc107",
    },
    thursdays: {
      color: "#ffc107",
      backgroundColor: "#fffdee",
    },
    outside: {
      backgroundColor: "white",
    },
  };

  const handleDayClick = (daySelec) => {
    setSelectedDay(daySelec, (current) => {
      dispatch(
        listScheduleReagendamiento(
          reagenda.old_date.oldDate,
          reagenda.old_hour.oldHour,
          current.toISOString().slice(0, 10),
          reagenda.cancha.id
        )
      );
    });
  };

  const handleRegresar = (e) => {
    e.preventDefault();
    history.push("/reagendar/cancha");
  };

  const handleFinalizar = (e) => {
    e.preventDefault();
    if (selectedSchedule.length === 1) {
      dispatch(
        placeReagendamiento(
          reagenda,
          selectedSchedule[0].hora,
          selectedSchedule[0].fecha
        )
      );
    } else {
      setfinalizarSeleccion(!finalizarSeleccion);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (loadingPlace === false) {
      history.push("/reagendar/resumen");
    }

    if (reagenda.cancha.id && reagenda.old_date.oldDate) {
      dispatch(
        listScheduleReagendamiento(
          reagenda.old_date.oldDate,
          reagenda.old_hour.oldHour,
          day,
          reagenda.cancha.id
        )
      );
    } else {
      history.push("/reagendar/cancha");
    }
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "/static/js/vendor/jquery-library.js";
    script.async = true;
    script.onload = () => {
      const script13 = document.createElement("script");
      script13.type = "text/javascript";
      script13.src = "/static/js/jquery-ui.js";
      script13.async = true;

      const script2 = document.createElement("script");
      script2.type = "text/javascript";
      script2.src = "/static/js/vendor/modernizr-2.8.3-respond-1.4.2.min.js";
      script2.async = true;
      const script3 = document.createElement("script");
      script3.type = "text/javascript";
      script3.src = "/static/js/vendor/bootstrap.min.js";
      script3.async = true;
      const script4 = document.createElement("script");
      script4.type = "text/javascript";
      script4.src = "/static/js/owl.carousel.js";
      script4.async = true;
      const script5 = document.createElement("script");
      script5.type = "text/javascript";
      script5.src = "/static/js/isotope.pkgd.js";
      script5.async = true;
      const script6 = document.createElement("script");
      script6.type = "text/javascript";
      script6.src = "/static/js/prettyPhoto.js";
      script6.async = true;
      const script7 = document.createElement("script");
      script7.type = "text/javascript";
      script7.src = "/static/js/customScrollbar.min.js";
      script7.async = true;
      const script9 = document.createElement("script");
      script9.type = "text/javascript";
      script9.src = "/static/js/swiper.min.js";
      script9.async = true;
      const script10 = document.createElement("script");
      script10.type = "text/javascript";
      script10.src = "/static/js/countTo.js";
      script10.async = true;
      const script11 = document.createElement("script");
      script11.type = "text/javascript";
      script11.src = "/static/js/appear.js";
      script11.async = true;
      const script8 = document.createElement("script");
      script8.type = "text/javascript";
      script8.src = "/static/js/main.js";
      script8.async = true;
      document.body.appendChild(script13);
      document.body.appendChild(script2);
      document.body.appendChild(script3);
      document.body.appendChild(script4);
      document.body.appendChild(script5);
      document.body.appendChild(script6);
      document.body.appendChild(script7);
      document.body.appendChild(script9);
      document.body.appendChild(script10);
      document.body.appendChild(script11);
      document.body.appendChild(script8);
    };

    document.body.appendChild(script);
  }, [dispatch, day, history, userInfo, reagenda, loadingPlace]);
  return (
    <Layout>
      <Seo
        titlePage="Reagendar"
        descriptionPage="Pádel Cuenca. Somos un club conformado por personas amantes del deporte, enfocados en fomentar la vida activa y saludable, priorizando el ejercicio como un hábito que beneficie nuestro estado físico y mental, Disponemos de canchas de Pádel en Cuenca Ecuador para que pueda realizar una reserva."
      />
      {finalizarSeleccion && (
        <SnackBar
          severity="error"
          message="Por favor seleccione una fecha para el reagendamiento"
        />
      )}
      <div id="tg-wrapper" className="tg-wrapper tg-haslayout">
        <MobileMenu />
        <Header />
        <Banner title="Reagendamiento" />
        <main id="tg-main" className="tg-main tg-haslayout">
          <section className="tg-main-section tg-paddingbottom-zero tg-haslayout">
            <div className="container">
              <div className="tg-section-name">
                <h2>reagendar</h2>
              </div>
              <div className="col-sm-11 col-xs-11 pull-right">
                <MaterialStepperReserva step={1} />
              </div>
              <div className="col-sm-11 col-xs-11 p">
                <div className="row" style={{ marginTop: "40px" }}>
                  <div className="tg-contactus tg-haslayout">
                    {loadingPlace !== undefined ? (
                      <div style={{ textAlign: "center", marginTop: "30px" }}>
                        <Loader />
                        <h5 style={{ margin: "30px 0px" }}>
                          Por favor espere mientras validamos su solicitud.
                        </h5>
                      </div>
                    ) : (
                      <div className="row">
                        <div className="col-md-5 col-sm-12 col-xs-12">
                          <div className="tg-contactinfobox">
                            <div className="tg-section-heading">
                              <h2>Por favor seleccione una fecha</h2>
                            </div>

                            <DayPicker
                              onDayClick={handleDayClick}
                              selectedDays={selectedDay}
                              months={MONTHS}
                              weekdaysLong={WEEKDAYS_LONG}
                              weekdaysShort={WEEKDAYS_SHORT}
                              modifiersStyles={modifiersStyles}
                              className="daypicker"
                            />
                          </div>
                        </div>
                        <div className="col-md-7 col-sm-12 col-xs-12">
                          {loading ? (
                            <Loader />
                          ) : error ? (
                            <Message
                              severity="error"
                              message="Algo salió mal, por favor intentalo mas tarde"
                            />
                          ) : schedule.disponible ? (
                            schedule.disponible.length > 0 && diffDays >= 0 ? (
                              <>
                                <h5>Disponible </h5>

                                <Box sx={{ flexGrow: 1 }}>
                                  <Grid
                                    container
                                    spacing={{ xs: 2, md: 3 }}
                                    columns={{ xs: 4, sm: 8, md: 12 }}
                                  >
                                    {schedule.disponible
                                      ? schedule.disponible.map((disp, i) => (
                                          <Grid
                                            item
                                            xs={2}
                                            sm={4}
                                            md={4}
                                            key={i}
                                          >
                                            {diffDays >= 0 ? (
                                              <div className="horarioCheckBox horarioDisponible">
                                                <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      onChange={(e) => {
                                                        e.preventDefault();

                                                        if (e.target.checked) {
                                                          setSelectedSchedule([
                                                            {
                                                              fecha: selectedDay
                                                                .toISOString()
                                                                .slice(0, 10),
                                                              hora: disp.hora,
                                                            },
                                                          ]);
                                                        } else {
                                                          // remove from list
                                                          //console.log("remove");
                                                          setSelectedSchedule(
                                                            []
                                                          );
                                                        }
                                                      }}
                                                      checked={
                                                        selectedSchedule.find(
                                                          (o) =>
                                                            o.fecha ===
                                                              selectedDay
                                                                .toISOString()
                                                                .slice(0, 10) &&
                                                            o.hora === disp.hora
                                                        )
                                                          ? true
                                                          : false
                                                      }
                                                      color="success"
                                                      sx={{
                                                        "& .MuiSvgIcon-root": {
                                                          fontSize: 22,
                                                        },
                                                      }}
                                                    />
                                                  }
                                                  label={
                                                    <div
                                                      style={{
                                                        fontSize: "14px",
                                                      }}
                                                    >
                                                      <span>
                                                        Hora:{" "}
                                                        {disp.hora.slice(0, 5)}
                                                      </span>
                                                    </div>
                                                  }
                                                />
                                              </div>
                                            ) : null}
                                          </Grid>
                                        ))
                                      : null}
                                  </Grid>
                                </Box>
                              </>
                            ) : (
                              <div>
                                <h5>
                                  No existen horarios disponibles en este día
                                </h5>
                              </div>
                            )
                          ) : (
                           ""
                          )}

                          {selectedSchedule.length > 0 ? (
                            <>
                              <h5
                                style={{
                                  marginTop: "35px",
                                  marginBottom: "25px",
                                }}
                              >
                                SELECCIONADO
                              </h5>
                              <Box
                                sx={{ flexGrow: 1 }}
                                style={{ marginBottom: "15px" }}
                              >
                                <Grid
                                  container
                                  spacing={{ xs: 2, md: 3 }}
                                  columns={{ xs: 4, sm: 8, md: 12 }}
                                >
                                  {selectedSchedule.map((sel, i) => (
                                    <Grid item xs={4} sm={4} md={6} key={i}>
                                      <SelectedDayCard
                                        fecha={sel.fecha}
                                        hora={sel.hora}
                                      />
                                    </Grid>
                                  ))}
                                </Grid>
                              </Box>
                              {/* <SelectedDayCard /> */}
                            </>
                          ) : (
                            <div style={{ margin: "25px 0px" }}>
                              <p
                                style={{ fontSize: "18px", fontWeight: "600" }}
                              >
                                Todavía no existen horarios seleccionados
                              </p>
                            </div>
                          )}

                          <div className="buttonContainer">
                            <button
                              className="tg-btn"
                              style={{
                                background: "#41404B",
                                color: "white",
                                marginTop: "5px",
                              }}
                              onClick={handleRegresar}
                            >
                              <FaArrowLeft /> Regresar
                            </button>
                            <button
                              className="tg-btn"
                              style={{
                                /*  background: "#F9F9F9",
                              color: "green", */

                                marginTop: "5px",
                              }}
                              onClick={handleFinalizar}
                            >
                              Reagendar <FaCheckCircle />
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        <Footer />
      </div>
    </Layout>
  );
};

export default ReagendamientoHorariosPage;
