import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { FaUserAlt, FaArrowAltCircleRight } from "react-icons/fa";
import { logout } from "../../redux/actions/userActions";
import { getSaldo } from "../../redux/actions/saldoActions";
import socialLinks from "../../constants/social-links";
const MobileMenu = () => {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const saldoUsuario = useSelector((state) => state.saldo);
  const { saldo, error } = saldoUsuario;
  const logoutHandler = () => {
    dispatch(logout());
  };
  useEffect(() => {
    if (userInfo) {
      dispatch(getSaldo(userInfo.email));
    }
  }, [userInfo, dispatch]);
  return (
    <div
      id="tg-navigationm-mobile"
      className="tg-navigationm-mobile tg-navigation collapse navbar-collapse"
    >
      <span id="tg-close" className={`tg-close fa fa-close`}></span>
      <div className="tg-colhalf">
        <ul>
          <li className="active ">
            <Link to="/">Inicio</Link>
          </li>
          <li>
            <Link to="about-us">Sobre Nosotros</Link>
          </li>
          <li>
            <Link to="/reservas/invitado">Reservas</Link>
          </li>
          <li>
            <Link to="/contacto">Contacto</Link>
          </li>
          <li>
            <Link to="/consulta">Consultar</Link>
          </li>
        </ul>
      </div>

      <div className="tg-colhalf">
        <ul></ul>
      </div>
      {userInfo ? (
        <div className="tg-colhalf">
          <ul>
            <li className="active ">
              <Link style={{ color: "#8dbe22" }} to="/usuario">
                <FaUserAlt /> {userInfo.username}
              </Link>
            </li>
            {error && (
              <li style={{ fontWeight: "900", color: "#8dbe22" }}>Saldo --</li>
            )}
            {saldo && saldo.saldo ? (
              <li
                style={{
                  fontWeight: "900",
                  color: "#8dbe22",
                  marginLeft: "10px",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                Saldo $ {saldo.saldo}
              </li>
            ) : (
              <li>Saldo $ 0</li>
            )}

            <li>
              <Link to="/usuario/reservas">Mis reservas</Link>
            </li>
            <li>
              <Link to="/reservas/invitado">Reservar</Link>
            </li>
            <li>
              <Link to="/recargar">Recargar</Link>
            </li>
            <li
              onClick={logoutHandler}
              style={{ marginLeft: "10px", color: "white" }}
            >
              {" "}
              Salir{" "}
              <FaArrowAltCircleRight
                style={{ marginLeft: "5px", marginTop: "10px" }}
              />
            </li>
          </ul>
        </div>
      ) : (
        <div className="tg-colhalf">
          <ul>
            {" "}
            <li>
              <Link to="/login">Iniciar Sesión</Link>
            </li>
            <li>
              <Link to="/registro">Registrarse</Link>
            </li>
          </ul>
        </div>
      )}
      <div className="">
        <ul className="tg-socialicons">
          {socialLinks.map((social, key) => (
            <li key={key}>
              <a href={social.link} target="_blank" rel="noopener noreferrer">
                {social.reactIcon}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default MobileMenu;
