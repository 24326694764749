import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../components/Layout/Layout";
import MobileMenu from "../components/Header/MobileMenu";
import Header from "../components/Header/Header";
import Footer from "../components/Layout/Footer";
import Banner from "../components/Banner";
import MaterialStepperReserva from "../components/ReservasPage/MaterialStepper";
import { Link } from "react-router-dom";
import SnackBar from "../components/Snackbar";
import {
  RESERVA_DELETE_COURT,
  RESERVA_DELETE_SCHEDULE,
} from "../redux/constants/reservasConstants";
import { RESERVA_PLACE_RESET } from "../redux/constants/reservaPlace";
import { addUserToReserva } from "../redux/actions/reservaActions";
import { placeReservaReset } from "../redux/actions/reservaPlaceActions";
import useDimentions from "../components/WindowDimentions";
import Seo from "../components/seo";
const GuestUserScreen = ({ history }) => {
  const { width } = useDimentions();
  const dispatch = useDispatch();
  const [errorCorreo, setErrorCorreo] = useState(false);
  const [correo, setCorreo] = useState("");
  const [telefono, setTelefono] = useState("");
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const handlePlaceReserva = (e) => {
    e.preventDefault();
    if (correo && telefono) {
      dispatch(addUserToReserva(correo, telefono));
      history.push("/reservas/cancha");
    } else {
      setErrorCorreo(!errorCorreo);
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "/static/js/vendor/jquery-library.js";
    script.async = true;
    script.onload = () => {
      const script13 = document.createElement("script");
      script13.type = "text/javascript";
      script13.src = "/static/js/jquery-ui.js";
      script13.async = true;

      const script2 = document.createElement("script");
      script2.type = "text/javascript";
      script2.src = "/static/js/vendor/modernizr-2.8.3-respond-1.4.2.min.js";
      script2.async = true;
      const script3 = document.createElement("script");
      script3.type = "text/javascript";
      script3.src = "/static/js/vendor/bootstrap.min.js";
      script3.async = true;
      const script4 = document.createElement("script");
      script4.type = "text/javascript";
      script4.src = "/static/js/owl.carousel.js";
      script4.async = true;
      const script5 = document.createElement("script");
      script5.type = "text/javascript";
      script5.src = "/static/js/isotope.pkgd.js";
      script5.async = true;
      const script6 = document.createElement("script");
      script6.type = "text/javascript";
      script6.src = "/static/js/prettyPhoto.js";
      script6.async = true;
      const script7 = document.createElement("script");
      script7.type = "text/javascript";
      script7.src = "/static/js/customScrollbar.min.js";
      script7.async = true;
      const script9 = document.createElement("script");
      script9.type = "text/javascript";
      script9.src = "/static/js/swiper.min.js";
      script9.async = true;
      const script10 = document.createElement("script");
      script10.type = "text/javascript";
      script10.src = "/static/js/countTo.js";
      script10.async = true;
      const script11 = document.createElement("script");
      script11.type = "text/javascript";
      script11.src = "/static/js/appear.js";
      script11.async = true;
      const script8 = document.createElement("script");
      script8.type = "text/javascript";
      script8.src = "/static/js/main.js";
      script8.async = true;
      document.body.appendChild(script13);
      document.body.appendChild(script2);
      document.body.appendChild(script3);
      document.body.appendChild(script4);
      document.body.appendChild(script5);
      document.body.appendChild(script6);
      document.body.appendChild(script7);
      document.body.appendChild(script9);
      document.body.appendChild(script10);
      document.body.appendChild(script11);
      document.body.appendChild(script8);
    };

    document.body.appendChild(script);
    window.scrollTo(0, 0);
    dispatch({ type: RESERVA_DELETE_COURT });
    dispatch({ type: RESERVA_DELETE_SCHEDULE });
    dispatch({ type: RESERVA_PLACE_RESET });
    dispatch(placeReservaReset);
    if (userInfo) {
      history.push("/reservas/cancha");
    }

    //dispatch(listCourts());
  }, [dispatch, userInfo, history]);
  return (
    <Layout>
      <Seo
        titlePage="Reserva"
        descriptionPage="Pádel Cuenca. Somos un club conformado por personas amantes del deporte, enfocados en fomentar la vida activa y saludable, priorizando el ejercicio como un hábito que beneficie nuestro estado físico y mental, Disponemos de canchas de Pádel en Cuenca Ecuador para que pueda realizar una reserva."
      />
      {errorCorreo && <SnackBar severity="error" message="Ingrese un correo" />}
      <div id="tg-wrapper" className="tg-wrapper tg-haslayout">
        <MobileMenu />
        <Header />
        <Banner title="Reservas" />
        <main id="tg-main" className="tg-main tg-haslayout">
          <section className="tg-main-section tg-paddingbottom-zero tg-haslayout">
            <div className="container">
              <div className="tg-section-name">
                <h2 style={{color:"#d2006e"}}>reserva</h2>
              </div>

              <div className="col-sm-11 col-xs-11 pull-right">
                <MaterialStepperReserva step={0} />

                <div style={{ textAlign: "center" }}>
                  <p style={{ marginBottom: "15px" }}>
                    Ingresa tu correo, número de teléfono y realiza el pago, o{" "}
                    <Link to="/login">Inicia Sesión</Link> y conoce muchos
                    beneficios.
                  </p>
                  <p></p>
                  <form onSubmit={handlePlaceReserva} className="tg-loginform">
                    <input
                      type="email"
                      name="correo"
                      value={correo}
                      className="form-control"
                      placeholder="Correo"
                      style={{ maxWidth: "400px" }}
                      onChange={(e) => setCorreo(e.target.value)}
                      required
                    />
                    <input
                      type="tel"
                      name="telefono"
                      value={telefono}
                      className="form-control"
                      placeholder="Teléfono"
                      style={{
                        maxWidth: "400px",
                        marginLeft: width >= 960 ? "5px" : "0",
                        marginTop: width >= 960 ? "0px" : "10px",
                      }}
                      onChange={(e) => setTelefono(e.target.value)}
                      required
                    />
                    {/* <hr /> */}
                    <button
                      class="tg-btn"
                      style={{ marginTop: "15px" }}
                      type="submit"
                    >
                      Continuar como invitado
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </main>
        <Footer />
      </div>
    </Layout>
  );
};

export default GuestUserScreen;
