import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import Layout from "../components/Layout/Layout";
import MobileMenu from "../components/Header/MobileMenu";
import Header from "../components/Header/Header";
import Footer from "../components/Layout/Footer";
import Banner from "../components/Banner";
import api from "../api/api";
import { useSelector } from "react-redux";
import Loader from "../components/Loader";
import Message from "../components/Alerts";
import SnackBar from "../components/Snackbar";
import Seo from "../components/seo";
const NuevaContraPage = ({ location, history, match }) => {
  const codigo = match.params.id;
  const [loading, setLoading] = useState(false);
  const [recuperarData, setRecuperarData] = useState(null);
  const [errorRecu, setErrorRecu] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    /* reValidateMode: "onChange", */
    criteriaMode: "firstError",
    shouldFocusError: true,
  });
  const [message, setMessage] = useState(null);
  //console.log(userName);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const redirect = location.search ? location.search.split("=")[1] : "/";

  useEffect(() => {
    window.scrollTo(0, 0);
    if (userInfo) {
      history.push(redirect);
    }
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "/static/js/vendor/jquery-library.js";
    script.async = true;
    script.onload = () => {
      const script13 = document.createElement("script");
      script13.type = "text/javascript";
      script13.src = "/static/js/jquery-ui.js";
      script13.async = true;

      const script2 = document.createElement("script");
      script2.type = "text/javascript";
      script2.src = "/static/js/vendor/modernizr-2.8.3-respond-1.4.2.min.js";
      script2.async = true;
      const script3 = document.createElement("script");
      script3.type = "text/javascript";
      script3.src = "/static/js/vendor/bootstrap.min.js";
      script3.async = true;
      const script4 = document.createElement("script");
      script4.type = "text/javascript";
      script4.src = "/static/js/owl.carousel.js";
      script4.async = true;
      const script5 = document.createElement("script");
      script5.type = "text/javascript";
      script5.src = "/static/js/isotope.pkgd.js";
      script5.async = true;
      const script6 = document.createElement("script");
      script6.type = "text/javascript";
      script6.src = "/static/js/prettyPhoto.js";
      script6.async = true;
      const script7 = document.createElement("script");
      script7.type = "text/javascript";
      script7.src = "/static/js/customScrollbar.min.js";
      script7.async = true;
      const script9 = document.createElement("script");
      script9.type = "text/javascript";
      script9.src = "/static/js/swiper.min.js";
      script9.async = true;
      const script10 = document.createElement("script");
      script10.type = "text/javascript";
      script10.src = "/static/js/countTo.js";
      script10.async = true;
      const script11 = document.createElement("script");
      script11.type = "text/javascript";
      script11.src = "/static/js/appear.js";
      script11.async = true;
      const script8 = document.createElement("script");
      script8.type = "text/javascript";
      script8.src = "/static/js/main.js";
      script8.async = true;
      document.body.appendChild(script13);
      document.body.appendChild(script2);
      document.body.appendChild(script3);
      document.body.appendChild(script4);
      document.body.appendChild(script5);
      document.body.appendChild(script6);
      document.body.appendChild(script7);
      document.body.appendChild(script9);
      document.body.appendChild(script10);
      document.body.appendChild(script11);
      document.body.appendChild(script8);
    };

    document.body.appendChild(script);
  }, [history, userInfo, redirect]);

  const onSubmit = (data) => {
    if (data.password !== data.confirmPassword) {
      setMessage("Las contraseñas no son iguales");
    } else {
      setLoading(true);
      const password = data.password;
      const getIndicadores = async () => {
        try {
          const { data } = await api.post("/usuario/recuperacion", {
            codigo,
            password,
          });
          setRecuperarData(data);
          setLoading(false);
        } catch (error) {
          setErrorRecu(true);
        }
      };

      getIndicadores();
    }
  };
  return (
    <Layout>
      <Seo
        titlePage="Nueva Contraseña"
        descriptionPage="Pádel Cuenca. Somos un club conformado por personas amantes del deporte, enfocados en fomentar la vida activa y saludable, priorizando el ejercicio como un hábito que beneficie nuestro estado físico y mental, Disponemos de canchas de Pádel en Cuenca Ecuador para que pueda realizar una reserva."
      />

      {message && <SnackBar message={message} severity="error" />}
      <div id="tg-wrapper" className="tg-wrapper tg-haslayout">
        <MobileMenu />
        <Header />
        <Banner title="Nueva Contraseña" />
        <main id="tg-main" className="tg-main tg-haslayout">
          <div className="modal-dialog" role="document">
            <div className="tg-modal-content">
              <div className="tg-formarea">
                <div className="tg-border-heading">
                  <h3>Ingresar su Nueva Contraseña</h3>
                </div>
                {errorRecu && <Message message={errorRecu} severity="error" />}
                {loading && <Loader />}
                {recuperarData ? (
                  <div style={{ marginTop: "25px" }}>
                    <h6>
                      Contraseña actualizada con{" "}
                      <span style={{ fontWeight: "800" }}>éxito.</span>
                    </h6>
                    <Link
                      style={{ marginTop: "15px" }}
                      className="tg-btn tg-btn-lg"
                      to="/login"
                    >
                      Ingrese a su Cuenta
                    </Link>
                  </div>
                ) : (
                  <form
                    className="tg-loginform"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <fieldset>
                      <div className="form-group">
                        <input
                          style={{
                            border: errors.password ? "1px solid red" : "",
                          }}
                          {...register("password", {
                            required: true,

                            minLength: 6,
                          })}
                          className="form-control"
                          placeholder="Contraseña"
                          type="password"
                        />
                        {errors.password?.type === "required" && (
                          <span style={{ color: "red" }}>
                            La contraseña es requerida.
                          </span>
                        )}
                        {errors.password?.type === "minLength" && (
                          <span style={{ color: "red" }}>
                            La contraseña debe tener al menos 6 caracteres.
                          </span>
                        )}
                      </div>
                      <div className="form-group">
                        <input
                          style={{
                            border: errors.confirmPassword
                              ? "1px solid red"
                              : "",
                          }}
                          {...register("confirmPassword", {
                            required: true,

                            minLength: 6,
                          })}
                          className="form-control"
                          placeholder="Confirmar Contraseña"
                          type="password"
                        />
                        {errors.confirmPassword?.type === "required" && (
                          <span style={{ color: "red" }}>
                            La contraseña es requerida.
                          </span>
                        )}
                        {errors.confirmPassword?.type === "minLength" && (
                          <span style={{ color: "red" }}>
                            La contraseña debe tener al menos 6 caracteres.
                          </span>
                        )}
                      </div>

                      <div className="form-group">
                        <button className="tg-btn tg-btn-lg" type="submit">
                          Cambiar Contraseña
                        </button>
                      </div>
                      <div className="tg-description">
                        <p>
                          ¿No tienes una cuenta?{" "}
                          <Link to="/registro">Regístrate</Link>
                        </p>
                      </div>
                    </fieldset>
                  </form>
                )}
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </Layout>
  );
};

export default NuevaContraPage;
