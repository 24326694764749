import React, { useState } from "react";

import Collapse from "@mui/material/Collapse";

import IconButton from "@mui/material/IconButton";
import Alert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";

// severity:
// error, warning, info, success
const ErrorAlert = ({ message, severity }) => {
  const [open, setOpen] = useState(true);
  return (
    <div>
      <Collapse in={open}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="24px" />
            </IconButton>
          }
          severity={severity}
          style={{ fontSize: "16px" }}
        >
          {message}
        </Alert>
      </Collapse>
    </div>
  );
};

export default ErrorAlert;
