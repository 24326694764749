import React, { useState } from "react";
import { Link } from "react-router-dom";
import Map from "../Map";
import SnackBar from "../Snackbar";
import api from "../../api/api";

const ContactForm = () => {
  const [error, setError] = useState(false);
  const [dataContacto, setDataContacto] = useState(null);
  const [email, setEmail] = useState("");
  const [texto, setTexto] = useState("");
  const [asunto, setAsunto] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    const EnviarContactenos = async () => {
      try {
        const { data } = await api.post("/mail/contacto", {
          from: email,
          subject: asunto,
          text: texto,
        });
        setDataContacto(data);
      } catch (error) {
        setError(true);
      }
    };

    EnviarContactenos();
  };
  return (
    <section className="tg-main-section tg-paddingbottom-zero tg-haslayout">
      <div className="container">
        <div className="tg-section-name">
          <h2>contacto</h2>
        </div>
        <div className="col-sm-11 col-xs-11 pull-right">
          <div className="row">
            <div className="tg-contactus tg-haslayout">
              <div className="row">
                <div className="col-md-5 col-sm-12 col-xs-12">
                  <div className="tg-contactinfobox">
                    <div className="tg-section-heading">
                      <h2>
                        no dude en enviarnos sus consultas, sugerencias &amp;
                        comentarios
                      </h2>
                    </div>
                    <div className="tg-description">
                      {/*  <p>
                        Consectetur adipisicing elit sed do eiusmod temport
                        incididunt utia labore et dolore magna aliqua enima ad
                        minim veniam quistrud on ullamco laboris nisiut aliquip
                        ex ea commodo consequat.
                      </p> */}
                    </div>
                    <ul className="tg-contactinfo">
                      <li>
                        <i className="fa fa-map-marker"></i>
                        <address>
                          Calle Pueblo de los Quitus (Challuabamba) - Cuenca
                          Ecuador
                        </address>
                      </li>
                      <li>
                        <i className="fa fa-phone"></i>
                        <span>
                          <a
                            href="tel:+593 98 433 7828"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            +593 98 433 7828
                          </a>
                        </span>
                      </li>
                      <li>
                        <i className="fa fa-envelope-o"></i>
                        <a
                          href="mailto:padelcuenca2021@gmail.com"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          padelcuenca2021@gmail.com
                        </a>
                      </li>
                      {/* <li>
                        <i className="fa fa-skype"></i>
                        <a href="info%40domain.html">Padel Cuenca</a>
                      </li> */}
                      <li>
                        <i className="fa fa-facebook-f"></i>
                        <a
                          href="https://www.facebook.com/padel_cuenca-10185478562390"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          facebook.com/padel_cuenca-101854785623902
                        </a>
                      </li>
                      <li>
                        <i className="fa fa-instagram"></i>
                        <a
                          href="https://www.instagram.com/padel_cuenca/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          instagram.com/padel_cuenca
                        </a>
                      </li>
                      {/*  <li>
                        <i className="fa fa-instagram">asa</i>
                        <a
                          href="https://www.tiktok.com/@padelcuenca"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          tiktok.com/padelcuenca
                        </a>
                      </li> */}
                      <li>
                        <i className="fa fa-laptop"></i>
                        <Link to="/">www.padelcuenca.ec</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-7 col-sm-12 col-xs-12">
                  {error && (
                    <SnackBar severity="error" message="Algo salió mal" />
                  )}
                  {dataContacto && (
                    <SnackBar severity="success" message={dataContacto} />
                  )}
                  <form
                    onSubmit={handleSubmit}
                    className="tg-commentform help-form"
                  >
                    <fieldset>
                      <div className="form-group">
                        <input
                          type="text"
                          required=""
                          placeholder="Nombre*"
                          className="form-control"
                          name="contact[name]"
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="email"
                          required
                          placeholder="Email*"
                          className="form-control"
                          value={email}
                          name="contact[email]"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <div className="tg-select">
                          <select
                            name="contact[type]"
                            onChange={(e) => setAsunto(e.target.value)}
                          >
                            <option value="Predeterminado">
                              Tipo de Mensaje *
                            </option>
                            <option value="Help">Discusión</option>
                            <option value="Help">Ayuda</option>
                            <option value="Consutation">Consuta</option>
                          </select>
                        </div>
                      </div>
                      <div className="form-group">
                        <textarea
                          required
                          placeholder="Mensaje*"
                          name="contact[message]"
                          value={texto}
                          onChange={(e) => setTexto(e.target.value)}
                        ></textarea>
                      </div>
                      <div className="form-group">
                        <button type="submit" className="tg-btn ">
                          enviar
                        </button>
                      </div>
                    </fieldset>
                  </form>
                </div>
                <div
                  className="tg-officeaddressslider tg-officeaddressnav"
                  style={{ maxWidth: "300px" }}
                >
                  <div className="item">
                    <span className="tg-theme-tag">PRINCIPAL</span>
                    <div className="tg-section-heading">
                      <h2>Cuenca, EC</h2>
                    </div>
                    <ul className="tg-contactinfo">
                      <li>
                        <i className="fa fa-map-marker"></i>
                        <address>
                          Calle Pueblo de los Quitus (Challuabamba) - Cuenca
                          Ecuador
                        </address>
                      </li>
                      <li>
                        <i className="fa fa-phone"></i>
                        <span>+593 98 433 7828</span>
                      </li>
                      <li>
                        <i className="fa fa-envelope-o"></i>

                        <span>padelcuenca2021@gmail.com</span>

                        {/* @padelcuenca */}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="tg-mapcontent" className="tg-mapcontent "></div>
      <div className="item" style={{ textAlign: "center" }}>
        <Map />
      </div>
    </section>
  );
};

export default ContactForm;
