import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import MobileMenu from "../components/Header/MobileMenu";
import Header from "../components/Header/Header";
import Footer from "../components/Layout/Footer";
import Banner from "../components/Banner";
import MaterialSteps from "../components/Reagendamiento/MaterialReAgendStep";
import Message from "../components/Alerts";
import { REAGENDAMIENTO_PLACE_RESET } from "../redux/constants/reagendamientoConstants";
import Seo from "../components/seo";
const ReagendamientoResumenScreen = ({ history }) => {
  const dispatch = useDispatch();
  const [sdkReady2, setSdkReady2] = useState(false);
  const reagendamientoPlace = useSelector((state) => state.placeReagendamiento);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const { reagendamientoResult, error } = reagendamientoPlace;
  if (!reagendamientoResult && !error) {
    history.push("/usuario/reservas");
  }

  const handleRegresar = (e) => {
    e.preventDefault();
    dispatch({ type: REAGENDAMIENTO_PLACE_RESET });
    history.push("/reagendar/horario");
  };

  useEffect(() => {
    if (reagendamientoResult === undefined && error === undefined) {
      history.push("/usuario/reservas");
    }
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "/static/js/vendor/jquery-library.js";
    script.async = true;
    script.onload = () => {
      const script13 = document.createElement("script");
      script13.type = "text/javascript";
      script13.src = "/static/js/jquery-ui.js";
      script13.async = true;

      const script2 = document.createElement("script");
      script2.type = "text/javascript";
      script2.src = "/static/js/vendor/modernizr-2.8.3-respond-1.4.2.min.js";
      script2.async = true;
      const script3 = document.createElement("script");
      script3.type = "text/javascript";
      script3.src = "/static/js/vendor/bootstrap.min.js";
      script3.async = true;
      const script4 = document.createElement("script");
      script4.type = "text/javascript";
      script4.src = "/static/js/owl.carousel.js";
      script4.async = true;
      const script5 = document.createElement("script");
      script5.type = "text/javascript";
      script5.src = "/static/js/isotope.pkgd.js";
      script5.async = true;
      const script6 = document.createElement("script");
      script6.type = "text/javascript";
      script6.src = "/static/js/prettyPhoto.js";
      script6.async = true;
      const script7 = document.createElement("script");
      script7.type = "text/javascript";
      script7.src = "/static/js/customScrollbar.min.js";
      script7.async = true;
      const script9 = document.createElement("script");
      script9.type = "text/javascript";
      script9.src = "/static/js/swiper.min.js";
      script9.async = true;
      const script10 = document.createElement("script");
      script10.type = "text/javascript";
      script10.src = "/static/js/countTo.js";
      script10.async = true;
      const script11 = document.createElement("script");
      script11.type = "text/javascript";
      script11.src = "/static/js/appear.js";
      script11.async = true;
      const script8 = document.createElement("script");
      script8.type = "text/javascript";
      script8.src = "/static/js/main.js";
      script8.async = true;
      document.body.appendChild(script13);
      document.body.appendChild(script2);
      document.body.appendChild(script3);
      document.body.appendChild(script4);
      document.body.appendChild(script5);
      document.body.appendChild(script6);
      document.body.appendChild(script7);
      document.body.appendChild(script9);
      document.body.appendChild(script10);
      document.body.appendChild(script11);
      document.body.appendChild(script8);
    };

    document.body.appendChild(script);
  }, [history, reagendamientoResult, sdkReady2, setSdkReady2, error]);

  return (
    <Layout>
      <Seo
        titlePage="Reagendar"
        descriptionPage="Pádel Cuenca. Somos un club conformado por personas amantes del deporte, enfocados en fomentar la vida activa y saludable, priorizando el ejercicio como un hábito que beneficie nuestro estado físico y mental, Disponemos de canchas de Pádel en Cuenca Ecuador para que pueda realizar una reserva."
      />
      <div id="tg-wrapper" className="tg-wrapper tg-haslayout">
        <MobileMenu />
        <Header />
        <Banner title="Confirmación" />
        <section className="tg-main-section tg-paddingbottom-zero tg-haslayout">
          <div className="container">
            <div className="tg-section-name">
              <h2>resumen</h2>
            </div>

            <div className="col-sm-11 col-xs-11 pull-right">
              {/*  <ReservaSteps step={3} /> */}
              <MaterialSteps step={2} />
            </div>
            <div className="col-sm-11 col-xs-11 ">
              <div className="content-wrapper">
                {/*  <h6>Resumen de la reserva</h6> */}
                {!error && (
                  <div style={{ textAlign: "center", marginBottom: "35px" }}>
                    <h6
                      style={{
                        fontSize: "24px",
                        fontWeight: "700",
                        color: "#8dbe22",
                      }}
                    >
                      Horario modificado con éxito
                    </h6>
                  </div>
                )}
              </div>
              <div class="row">
                {error ? (
                  <>
                    <Message severity="error" message={error} />
                    <div className="buttonContainer">
                      <button
                        className="tg-btn"
                        style={{
                          background: "#41404B",
                          color: "white",
                          marginTop: "15px",
                        }}
                        onClick={handleRegresar}
                      >
                        <FaArrowLeft /> Regresar
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    {/* <h6>Resumen de la reserva</h6> */}
                    <div
                      className="buttonContainer"
                      style={{ textAlign: "center", marginBottom: "25px" }}
                    >
                      {userInfo ? (
                        <Link class="tg-btn" to="/usuario/reservas">
                          ver mis reservas
                        </Link>
                      ) : (
                        <Link class="tg-btn" to="/consulta">
                          consultar una reserva
                        </Link>
                      )}
                      <Link class="tg-btn" to="/reservas/invitado">
                        realizar reserva
                      </Link>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </Layout>
  );
};

export default ReagendamientoResumenScreen;
