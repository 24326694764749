import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  resetReagendamiento,
  addReservaToReagendamiento,
  addIdHorarioToReagendamiento,
  addOldHourToReagendamiento,
  addOldDateToReagendamiento,
} from "../../redux/actions/reagendamientoActions";
import { withRouter } from "react-router-dom";
const ResumenListItem = ({
  id,
  horarioId,
  fecha,
  hora,
  cancha,
  precio,
  estado,
  es_reagendable,
  history,
}) => {
  //console.log(es_reagendable);
  const dispatch = useDispatch();
  //new Date(fecha.replace(/-/g, "/"))
  //const currentDay = new Date();
  const date = new Date(fecha.replace(" ", "T"));
  //const diffDays = Math.floor((date - currentDay) / (1000 * 60 * 60 * 24), 10);
  //const userLogin = useSelector((state) => state.userLogin);
  //const { userInfo } = userLogin;

  const handleReagendar = (e) => {
    e.preventDefault();
    dispatch(resetReagendamiento());
    dispatch(addReservaToReagendamiento(id));
    dispatch(addIdHorarioToReagendamiento(horarioId));
    dispatch(addOldHourToReagendamiento(hora.slice(0, 5)));
    dispatch(addOldDateToReagendamiento(fecha.slice(0, 10)));
    history.push("/reagendar/cancha");
  };
  //console.log(date);

  useEffect(() => {}, []);

  return (
    <>
      {estado !== "PENDIENTE DE PAGO" && es_reagendable === 1 ? (
        <div class="tg-ticket">
          <time class="tg-matchdate" datetime="2016-05-03">
            {date.getDate()}
            <span>{date.toLocaleString("default", { month: "short" })}</span>
          </time>
          <div class="tg-matchdetail">
            <span class="tg-theme-tag">{cancha} </span>
            <h4>
              Reserva Cancha <span>Padel </span> $ {precio}
            </h4>
            <ul class="tg-matchmetadata">
              <li>
                <time
                  datetime="2016-05-03"
                  style={{ fontSize: "16px", fontWeight: "700" }}
                >
                  {hora.slice(0, 5)}
                </time>
              </li>
              <li>
                <address>Cuenca, Ecuador</address>
              </li>
            </ul>
          </div>
          {estado !== "PENDIENTE DE PAGO" && es_reagendable === 1 && (
            <div class="tg-btnsbox">
              <Link class="tg-btn" to={`/reagendar/cancha/${id}/${horarioId}`}>
                reagendar
              </Link>
              <Link class="tg-btn" to="/contacto">
                Contáctenos
              </Link>
            </div>
          )}
        </div>
      ) : (
        <div class="tg-ticket" disabled>
          <time class="tg-matchdate" datetime="2016-05-03">
            {date.getDate()}
            <span>{date.toLocaleString("default", { month: "short" })}</span>
          </time>
          <div className="tg-matchdetail">
            <span className="tg-theme-tag">{cancha} </span>
            <h4>
              Reserva Cancha <span>Padel </span> $ {precio}
            </h4>
            <ul className="tg-matchmetadata">
              <li>
                <time
                  datetime="2016-05-03"
                  style={{ fontSize: "16px", fontWeight: "700" }}
                >
                  {hora.slice(0, 5)}
                </time>
              </li>
              <li>
                <address>Cuenca, Ecuador</address>
              </li>
            </ul>
          </div>
          {estado !== "PENDIENTE DE PAGO" && es_reagendable === 1 && (
            <div className="tg-btnsbox">
              <button
                className="tg-btn"
                /* to={`/reagendar/cancha/${id}/${horarioId}`} */ onClick={
                  handleReagendar
                }
              >
                reagendar
              </button>
              <Link className="tg-btn" to="/contacto">
                Contáctenos
              </Link>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default withRouter(ResumenListItem);
