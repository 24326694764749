export const REAGENDAMIENTO_ADD_RESERVA = "REAGENDAMIENTO_ADD_RESERVA";
export const REAGENDAMIENTO_DELETE_RESERVA = "REAGENDAMIENTO_DELETE_RESERVA";

export const REAGENDAMIENTO_ADD_ID_HORARIO = "REAGENDAMIENTO_ADD_ID_HORARIO";
export const REAGENDAMIENTO_DELETE_ID_HORARIO =
  "REAGENDAMIENTO_DELETE_ID_HORARIO";

export const REAGENDAMIENTO_ADD_FECHA = "REAGENDAMIENTO_ADD_FECHA";
export const REAGENDAMIENTO_DELETE_FECHA = "REAGENDAMIENTO_DELETE_FECHA";

export const REAGENDAMIENTO_ADD_HORA = "REAGENDAMIENTO_ADD_HORA";
export const REAGENDAMIENTO_DELETE_HORA = "REAGENDAMIENTO_DELETE_HORA";

export const REAGENDAMIENTO_ADD_COURT = "REAGENDAMIENTO_ADD_COURT";
export const REAGENDAMIENTO_DELETE_COURT = "REAGENDAMIENTO_DELETE_COURT";

export const REAGENDAMIENTO_ADD_OLD_DATE = "REAGENDAMIENTO_ADD_OLD_DATE";
export const REAGENDAMIENTO_DELETE_OLD_DATE = "REAGENDAMIENTO_DELETE_OLD_DATE";

export const REAGENDAMIENTO_ADD_OLD_HOUR = "REAGENDAMIENTO_ADD_OLD_HOUR";
export const REAGENDAMIENTO_DELETE_OLD_HOUR = "REAGENDAMIENTO_DELETE_OLD_HOUR";

export const REAGENDAMIENTO_PLACE_REQUEST = "REAGENDAMIENTO_PLACE_REQUEST";
export const REAGENDAMIENTO_PLACE_SUCCESS = "REAGENDAMIENTO_PLACE_SUCCESS";
export const REAGENDAMIENTO_PLACE_FAIL = "REAGENDAMIENTO_PLACE_FAIL";
export const REAGENDAMIENTO_PLACE_RESET = "REAGENDAMIENTO_PLACE_RESET";
