import React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import MobileStepper from "@mui/material/MobileStepper";
import useDimentions from "../WindowDimentions";
import "../../styles/materialStepper.css";
const stepsDatos = [
  {
    label: "Iniciar Sesión",
    description: `Inicie sesión con la cuenta`,
  },
  {
    label: "Recarga",
    description: "Recargue el saldo.",
  },
  {
    label: "Pago",
    description: `pago de la recarga`,
  },
];

const MaterialStepperRecargas = ({ step }) => {
  const { width } = useDimentions();
  console.log(width);
  return (
    <div>
      {width > 630 ? (
        <Box sx={{ width: "100%" }} style={{ marginBottom: "70px" }}>
          <Stepper activeStep={step}>
            {stepsDatos.map((step, index) => (
              <Step key={index}>
                <StepLabel>{step.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      ) : (
        <div style={{ textAlign: "center" }}>
          {" "}
          <MobileStepper
            variant="text"
            steps={3}
            position="static"
            activeStep={step}
          />
          <Box sx={{ maxWidth: 400, width: "100%", p: 2 }}>
            <p style={{ fontWeight: "600" }}>{stepsDatos[step].label}</p>{" "}
            <p>{stepsDatos[step].description}</p>
          </Box>
        </div>
      )}
    </div>
  );
};

export default MaterialStepperRecargas;
