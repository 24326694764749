import React from "react";
import { Link } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa";
import ScrollArea from "react-scrollbar";
import useDimentions from "../WindowDimentions";

import content from "../../constants/comoSeJuegaPadelContent";

const ComoJugarPadel = () => {
  const { width } = useDimentions();
  return (
    <section className="tg-main-section tg-haslayout">
      <div className="container">
        <div className="tg-section-name">
          <h2>cómo se juega</h2>
        </div>
        <div className="col-sm-11 col-xs-11 pull-right">
          <div className="row">
            <div className="tg-topratedplayer tg-haslayout">
              <div className="row">
                <div className="col-md-7 col-sm-12 col-xs-12">
                  {width > 960 ? (
                    <ScrollArea
                      speed={0.4}
                      className="area"
                      contentClassName="content"
                      horizontal={false}
                      stopScrollPropagation={true}
                    >
                      {content.map((info, index) => (
                        <div className="tg-player" key={index}>
                          <div className="col-sm-6 col-xs-6 pull-right">
                            <div className="tg-playcontent">
                              <p
                                className="tg-theme-tag"
                                style={{ color: "#8dbe22" }}
                              >
                                {info.etiqueta}
                              </p>
                              <h3>
                                <Link to="/">{info.titulo}</Link>
                              </h3>
                              {/* <span className="tg-stars">
                            <span></span>
                          </span> */}
                              <div className="tg-description">
                                <p>{info.descripcion}</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 col-xs-6">
                            <figure>
                              <Link to="/">{info.img}</Link>
                            </figure>
                          </div>
                        </div>
                      ))}
                    </ScrollArea>
                  ) : (
                    content.map((info, index) => (
                      <div className="tg-player" key={index}>
                        <div className="col-sm-6 col-xs-6 pull-right">
                          <div className="tg-playcontent">
                            <p
                              className="tg-theme-tag"
                              style={{ color: "#8dbe22" }}
                            >
                              {info.etiqueta}
                            </p>
                            <h3>
                              <Link to="/">{info.titulo}</Link>
                            </h3>
                            {/* <span className="tg-stars">
                        <span></span>
                      </span> */}
                            <div className="tg-description">
                              <p>{info.descripcion}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-xs-6">
                          <figure>
                            <Link to="/">{info.img}</Link>
                          </figure>
                        </div>
                      </div>
                    ))
                  )}
                </div>
                <div className="col-md-5 col-sm-12 col-xs-12">
                  <div className="tg-contentbox">
                    <div className="tg-section-heading">
                      <h2>¿qué se necesita para jugar al pádel?</h2>
                    </div>
                    <div className="tg-description">
                      <p style={{ fontSize: "16px" }}>
                        Para jugar a pádel necesitamos tres elementos
                        indispensables que sin ellos no sería posible jugar una
                        partida.
                      </p>
                      <p style={{ marginTop: "25px" }}>
                        {" "}
                        <FaCheckCircle
                          style={{
                            fontSize: "20px",
                            marginRight: "15px",
                            marginTop: "5px",
                          }}
                        />
                        La pista de pádel
                      </p>
                      <p>
                        {" "}
                        <FaCheckCircle
                          style={{
                            fontSize: "20px",
                            marginRight: "15px",
                            marginTop: "5px",
                          }}
                        />
                        La pala de pádel
                      </p>
                      <p>
                        {" "}
                        <FaCheckCircle
                          style={{
                            fontSize: "20px",
                            marginRight: "15px",
                            marginTop: "5px",
                          }}
                        />
                        La pelota
                      </p>
                    </div>
                    <div className="tg-btnbox" style={{ marginTop: "20px" }}>
                      <Link className="tg-btn" to="/reservas/invitado">
                        <span>reservar</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ComoJugarPadel;
