import React from "react";
import { Link } from "react-router-dom";
import fondo from "../../images/bg-pattran.png";
import sliderContent from "../../constants/slider-content";

const HomeSlider = () => {
  return (
    <div className="tg-sliderbox">
      <div className="tg-imglayer">
        <img src={fondo} alt="Padel Cuenca" layout="responsive" />
      </div>
      <div id="tg-home-slider" className="tg-home-slider tg-haslayout">
        <div className="swiper-wrapper">
          {sliderContent.map((slider, index) => (
            <div className="swiper-slide" key={index}>
              <div className="container">
                <figure className="floating">{slider.image}</figure>
                <div className="tg-slider-content">
                  <h1>
                    {slider.titleparte1} <span>{slider.titleparte2}</span>
                  </h1>
                  <div className="tg-btnbox">
                    <h2>{slider.subtitle}</h2>

                    <Link className="tg-btn" to={slider.button1Link}>
                      <span>{slider.button1}</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="tg-btn-next">
          <span>sig</span>
          <span className="fa fa-angle-down"></span>
        </div>
        <div className="tg-btn-prev">
          <span>ant</span>
          <span className="fa fa-angle-down"></span>
        </div>
      </div>
    </div>
  );
};

export default HomeSlider;
